import { FunctionComponent, useCallback, useState } from "react";

import EditProfile from "@src/modules/App/Profile/EditProfile";
import { ProfileModalWrapper } from "@src/modules/App/Profile/Profile.components";
import ViewProfile from "@src/modules/App/Profile/ViewProfile";
import { ModalTransitionProps } from "@src/modules/shared/components/Modal/ModalContainer.components";

const Profile: FunctionComponent<ModalTransitionProps> = ({ status }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const setViewMode = useCallback(() => setIsEditMode(false), [setIsEditMode]);
  const setEditMode = useCallback(() => setIsEditMode(true), [setIsEditMode]);

  return (
    <ProfileModalWrapper status={status}>
      {isEditMode ? (
        <EditProfile goToView={setViewMode} />
      ) : (
        <ViewProfile goToEdit={setEditMode} />
      )}
    </ProfileModalWrapper>
  );
};

export default Profile;
