import { createContext } from "react";

import { UserToken } from "@src/common/helpers/userToken";
import { NotificationsData } from "@src/modules/App/Notifications/hooks/useNotificationsCore";

export interface AppContextType {
  userToken: UserToken | null;
  notifications: NotificationsData;
}

const AppContext: React.Context<AppContextType> = createContext<AppContextType>(
  {} as AppContextType,
);

export default AppContext;
