import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";

import useAxios from "@src/config/axios/useAxios";
import { ACCOUNT_ENDPOINT } from "@src/config/baseUrls";
import { ResponseWithMessage } from "@src/modules/shared/types";

const useDeleteAccount = () => {
  const axios = useAxios();

  return useMutation<AxiosResponse<string>, AxiosError<ResponseWithMessage>>(
    async () => await axios.delete(ACCOUNT_ENDPOINT),
  );
};

export default useDeleteAccount;
