import { HubConnectionState } from "@microsoft/signalr";
import { useCallback, useEffect, useReducer } from "react";

import { useHubConnection } from "@src/modules/App/hooks/useHubConnection";
import {
  BASE_NOTIFICATION_HEIGHT,
  NOTIFICATIONS_CROP,
} from "@src/modules/App/Notifications/shared/consts";
import { PageableList } from "@src/modules/App/Notifications/shared/pageableList";
import { INotification } from "@src/modules/App/Notifications/shared/types";
import { ActionTypes } from "@src/modules/App/Notifications/signalR/reducer/actions";
import { reducer } from "@src/modules/App/Notifications/signalR/reducer/reducer";
import { useEmitters } from "@src/modules/App/Notifications/signalR/useEmitters";
import { useListeners } from "@src/modules/App/Notifications/signalR/useListeners";

export interface NotificationsData {
  notifications?: INotification[];
  currentPage?: PageableList<INotification>;
  unreadFlag: boolean;
  markAsRead: (notificationId?: string) => void;
  getNextPage: (pageSize: number) => void;
  setUnreadFlag: (unreadFlag: boolean) => void;
}

export const useNotificationsCore = (): NotificationsData => {
  const { hub: notificationsHub } = useHubConnection();
  const [{ notifications, currentPage, unreadFlag }, dispatch] = useReducer(
    reducer,
    {
      notifications: undefined,
      currentPage: undefined,
      unreadFlag: false,
    },
  );
  const { registerListeners, deregisterListeners } = useListeners();
  const { getNotificationsEmitter, markReadEmitter } =
    useEmitters(notificationsHub);

  useEffect(() => {
    if (!notificationsHub) return;
    const pageSize =
      Math.ceil(window.innerHeight / BASE_NOTIFICATION_HEIGHT) -
      NOTIFICATIONS_CROP;
    const startHub = async () => {
      if (notificationsHub.state === HubConnectionState.Disconnected) {
        try {
          await notificationsHub.start();
          await getNotificationsEmitter({ pageNumber: 1, pageSize });
        } catch (e) {}
      }
    };

    registerListeners(notificationsHub, dispatch);
    setTimeout(async () => startHub(), 2000);

    return () => deregisterListeners(notificationsHub);
  }, [
    notificationsHub,
    registerListeners,
    deregisterListeners,
    getNotificationsEmitter,
  ]);

  const markAsRead = useCallback(
    async (notificationId?: string) => await markReadEmitter(notificationId),
    [markReadEmitter],
  );

  const setUnreadFlag = useCallback(
    (unreadFlag: boolean) =>
      dispatch({ type: ActionTypes.SetUnreadFlag, unreadFlag }),
    [],
  );

  const getNextPage = useCallback(
    async (pageSize: number) => {
      if (!currentPage) return;
      await getNotificationsEmitter({
        pageNumber: currentPage?.currentPage + 1,
        pageSize,
      });
    },
    [getNotificationsEmitter, currentPage],
  );

  return {
    notifications,
    currentPage,
    unreadFlag,
    markAsRead,
    getNextPage,
    setUnreadFlag,
  };
};
