import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import {
  ConnectorDescription,
  ConnectorFlow,
  ConnectorHeader,
  DescriptionWrapper,
  EmptyStateWrapper,
} from "@src/modules/App/Connector/EmptyState/EmptyState.components";
import routesPaths from "@src/modules/Routing/routesPaths";
import Button from "@src/modules/shared/components/Button";
import { DescriptionLink } from "@src/modules/shared/components/components";
import ConnectorLabel from "@src/modules/shared/components/ConnectorLabel";
import useModal from "@src/modules/shared/components/Modal/useModal";
import { ReactComponent as DownloadArrow } from "@src/modules/shared/icons/download_arrow.svg";

interface Props {
  downloadConnector: () => Promise<void>;
}

const EmptyState: FunctionComponent<Props> = ({ downloadConnector }) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  return (
    <EmptyStateWrapper>
      <ConnectorHeader>{t("Try Diagnomatic Connector")}</ConnectorHeader>
      <ConnectorFlow />
      <DescriptionWrapper>
        <ConnectorDescription>
          {t(
            "Diagnomatic Connector allows to automatically run test studies. Connector sends DICOM files directly from tested device to Diagnomatic, where those are categorized and analyzed. Learn more in",
          )}
          &nbsp;
          <DescriptionLink
            onClick={() => closeModal()}
            to={routesPaths.KNOWLEDGE_BASE.BASE}
          >
            {t("Knowledge Base link")}
          </DescriptionLink>
        </ConnectorDescription>
        <Button buttonType="secondary" onClick={downloadConnector}>
          <DownloadArrow />
          <ConnectorLabel>{t("Download Connector")}</ConnectorLabel>
        </Button>
      </DescriptionWrapper>
    </EmptyStateWrapper>
  );
};

export default EmptyState;
