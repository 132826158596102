import { useInfiniteQuery } from "react-query";

import useAxios from "@src/config/axios/useAxios";
import { CONNECTOR_LOGS_ENDPOINT } from "@src/config/baseUrls";
import { queryKeys } from "@src/config/queryKeys";

export interface ConnectorLogData {
  date: string;
  locationName: string;
  message: string;
  ipAddress: string;
  isConnected: boolean;
  status: "Error" | "Success" | "Unset";
}

interface ConnectorLogsResponse {
  itemsCount: number;
  pageSize: number;
  currentPage: number;
  items: ConnectorLogData[];
  pagesCount: number;
}

const useConnectorLogs = (pageSize: number) => {
  const axios = useAxios();
  const fetchLogs = async ({ pageParam = 1 }) => {
    const { data } = await axios.get(
      `${CONNECTOR_LOGS_ENDPOINT}/${pageSize}/${pageParam}`,
    );
    return data;
  };

  return useInfiniteQuery<ConnectorLogsResponse>(
    queryKeys.CONNECTOR_LOGS,
    fetchLogs,
    {
      getNextPageParam: (lastPage) =>
        lastPage.currentPage >= lastPage.pagesCount
          ? undefined
          : lastPage.currentPage + 1,
      keepPreviousData: true,
    },
  );
};

export default useConnectorLogs;
