import axios from "axios";
import { useMemo } from "react";

import { CMS_URL } from "@src/config/baseUrls";
import { getUserToken } from "@src/common/helpers/userToken";

export const useAxiosCMS = () => {
  const userToken = getUserToken();

  return useMemo(() => {
    const axiosInstance = axios.create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userToken?.token}`,
      },
      withCredentials: false,
      baseURL: CMS_URL,
    });

    axiosInstance.interceptors.response.use(undefined, (error) => {
      if (error) {
        // eslint-disable-next-line no-console
        console.log("CMS", error);
      }
    });

    return axiosInstance;
  }, [userToken]);
};
