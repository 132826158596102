import { css, CSSObject, SimpleInterpolation } from "styled-components";

import {
  BREAKPOINTS,
  ELEMENT_BORDER_RADIUS_SIZE,
  ORIENTATIONS,
} from "@src/common/styles/const";
import colors from "@src/common/styles/colors";

export const defaultElementBorderRadius = css`
  border-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
`;

export const defaultLetterSpacing = css`
  letter-spacing: 0.05em;
`;

export const DEFAULT_TRANSITION_TIME = 500;

export const BOUNCE_TRANSITION_TIME = 200;

export const defaultTransition = css`
  transition: all ${DEFAULT_TRANSITION_TIME}ms;
`;

export const defaultBounceTransition = css`
  transition: all ${BOUNCE_TRANSITION_TIME}ms cubic-bezier(0.17, 0.6, 0.1, 1);
`;

export const defaultLinkStyle = css`
  font-weight: 600;
  color: ${colors.darkBlue};
  ${defaultTransition};

  &:hover {
    color: ${colors.darkBlue2};
  }
`;

export const wrapLongText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const hideNumberInputArrows = css`
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const media = {
  up:
    (breakpoint: keyof typeof BREAKPOINTS) =>
    (
      first: TemplateStringsArray | CSSObject,
      ...interpolations: SimpleInterpolation[]
    ) => {
      if (BREAKPOINTS[breakpoint].min) {
        return css`
          @media (min-width: ${BREAKPOINTS[breakpoint].min}px) {
            ${css(first, ...interpolations)}
          }
        `;
      }

      return css`
        ${css(first, ...interpolations)}
      `;
    },
  down:
    (breakpoint: keyof typeof BREAKPOINTS) =>
    (
      first: TemplateStringsArray | CSSObject,
      ...interpolations: SimpleInterpolation[]
    ) => {
      if (BREAKPOINTS[breakpoint].max) {
        return css`
          @media (max-width: ${BREAKPOINTS[breakpoint].max}px) {
            ${css(first, ...interpolations)}
          }
        `;
      }

      return css`
        ${css(first, ...interpolations)}
      `;
    },
  only:
    (breakpoint: keyof typeof BREAKPOINTS) =>
    (
      first: TemplateStringsArray | CSSObject,
      ...interpolations: SimpleInterpolation[]
    ) => {
      const minWidthQuery = BREAKPOINTS[breakpoint].min
        ? `(min-width: ${BREAKPOINTS[breakpoint].min}px)`
        : undefined;

      const maxWidthQuery = BREAKPOINTS[breakpoint].max
        ? `(max-width: ${BREAKPOINTS[breakpoint].max}px)`
        : undefined;

      const mediaQuery = [minWidthQuery, maxWidthQuery]
        .filter(Boolean)
        .join(" and ");

      if (mediaQuery) {
        return css`
          @media ${mediaQuery} {
            ${css(first, ...interpolations)}
          }
        `;
      }

      return css`
        ${css(first, ...interpolations)}
      `;
    },
  between:
    (
      fromBreakpoint: keyof typeof BREAKPOINTS,
      toBreakpoint: keyof typeof BREAKPOINTS,
    ) =>
    (
      first: TemplateStringsArray | CSSObject,
      ...interpolations: SimpleInterpolation[]
    ) => {
      const minWidthQuery = BREAKPOINTS[fromBreakpoint].min
        ? `(min-width: ${BREAKPOINTS[fromBreakpoint].min}px)`
        : undefined;

      const maxWidthQuery = BREAKPOINTS[toBreakpoint].max
        ? `(max-width: ${BREAKPOINTS[toBreakpoint].max}px)`
        : undefined;

      const mediaQuery = [minWidthQuery, maxWidthQuery]
        .filter(Boolean)
        .join(" and ");

      if (mediaQuery) {
        return css`
          @media ${mediaQuery} {
            ${css(first, ...interpolations)}
          }
        `;
      }

      return css`
        ${css(first, ...interpolations)}
      `;
    },
  upWithOrientation:
    (
      breakpoint: keyof typeof BREAKPOINTS,
      orientation: keyof typeof ORIENTATIONS,
    ) =>
    (
      first: TemplateStringsArray | CSSObject,
      ...interpolations: SimpleInterpolation[]
    ) => {
      const minWidthQuery = BREAKPOINTS[breakpoint].min
        ? `(min-width: ${BREAKPOINTS[breakpoint].min}px)`
        : undefined;

      const orientationQuery = `(orientation: ${ORIENTATIONS[orientation]})`;

      const mediaQuery = [minWidthQuery, orientationQuery]
        .filter(Boolean)
        .join(" and ");

      if (mediaQuery) {
        return css`
          @media ${mediaQuery} {
            ${css(first, ...interpolations)}
          }
        `;
      }

      return css`
        ${css(first, ...interpolations)}
      `;
    },
};
