import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_L,
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_XXL,
} from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import BaseSideModalWrapper from "@src/modules/shared/components/Modal/BaseSideModalWrapper";

const UPDATES_MODAL_WIDTH = 437;

export const UpdatesModalWrapper = styled(BaseSideModalWrapper).attrs({
  direction: "right",
})`
  width: ${UPDATES_MODAL_WIDTH}px;
  background-color: ${colors.white};
  padding: ${DEFAULT_MARGIN_XXL}px;

  ${media.down("MOBILE")`
    width: 100%;
    padding: ${DEFAULT_MARGIN_M}px;
  `};
  overflow: auto;
`;

export const UpdatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${DEFAULT_MARGIN_L}px;
  margin-top: ${DEFAULT_MARGIN_L}px;
`;
