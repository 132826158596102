import styled from "styled-components";

import colors from "@src/common/styles/colors";
import { DEFAULT_MARGIN_XL } from "@src/common/styles/const";
import { defaultLetterSpacing } from "@src/common/styles/mixins";
import connectorFlow from "@src/modules/App/Connector/EmptyState/icons/connector-flow.png";

export const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: ${DEFAULT_MARGIN_XL}px;
  border-radius: 12px;
  background-color: ${colors.background};
  margin-top: 48px;
`;

export const ConnectorHeader = styled.h2`
  font-size: 24px;
  ${defaultLetterSpacing};
  color: ${colors.gray3};
  margin: 0;
`;

export const ConnectorFlow = styled.img.attrs({
  src: connectorFlow,
  alt: "connector flow",
})`
  max-width: 100vw;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 31px;
`;

export const ConnectorDescription = styled.p`
  text-align: center;
  color: ${colors.gray3};
  ${defaultLetterSpacing};
`;
