import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { differenceInMinutes } from "date-fns";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import { getErrorMessageFromResponse } from "@src/common/helpers/getErrorMessageFromResponse";
import {
  INSUFFICIENT_SUBSCRIPTION,
  LOGIN,
  OTHER_ERROR,
} from "@src/common/helpers/toastIds";
import {
  deleteUserToken,
  getTimeToExpire,
  getUserToken,
} from "@src/common/helpers/userToken";
import useLogout from "@src/common/hooks/useLogout";
import useRefreshToken from "@src/common/hooks/useRefreshToken";
import { AxiosContext } from "@src/config/axios/AxiosContext";
import { API_URL } from "@src/config/baseUrls";
import { activationCodeId, profileButtonId } from "@src/config/consts";
import { LoginState } from "@src/modules/Public/shared/LoginForm/LoginForm";
import routesPaths from "@src/modules/Routing/routesPaths";
import {
  ResponseWithErrors,
  ResponseWithMessage,
} from "@src/modules/shared/types";

export const baseAxiosHeaders = {
  "Content-Type": "application/json",
};

export const baseAxiosConfig: AxiosRequestConfig = {
  withCredentials: true,
  baseURL: API_URL,
};

const AxiosProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { i18n, t } = useTranslation();
  const refreshToken = useRefreshToken();
  const history = useHistory<LoginState>();
  const { logout } = useLogout();

  const axiosValue = useMemo(() => {
    const axiosInstance = axios.create({
      headers: {
        ...baseAxiosHeaders,
      },
      ...baseAxiosConfig,
    });

    axiosInstance.interceptors.request.use(async (config) => {
      const userToken = getUserToken();
      if (userToken !== null) {
        const validDuration = differenceInMinutes(
          userToken.expiresAt,
          userToken.issuedAt,
        );
        const diff = getTimeToExpire(userToken);
        if (diff < validDuration / 2) {
          try {
            await refreshToken();
          } catch (error) {
            console.error("Unable to refresh token", error);
            logout();
          }
        }
      }

      if (!config.headers) return config;

      if (userToken) {
        config.headers.Authorization = `Bearer ${userToken.token}`;
      }

      config.headers["Accept-Language"] = i18n.language;

      return config;
    });

    axiosInstance.interceptors.response.use(
      undefined,
      async (error: AxiosError<ResponseWithMessage & ResponseWithErrors>) => {
        let errorMsg;
        switch (error.response?.status) {
          case 401:
            try {
              await refreshToken();
              const token = getUserToken()?.token;
              if (!token) break;

              if (error.config.headers)
                error.config.headers["Authorization"] = `Bearer ${token}`;
              return axios.request(error.config);
            } catch (error) {
              console.error(error);
              deleteUserToken();
              history.replace(routesPaths.LOGIN);
              toast.error(t("Please log in"), { toastId: LOGIN });
            }
            break;
          case 402:
          case 403:
            errorMsg =
              error.response.data.message ??
              t(
                "Your subscription doesn't allow that operation. Please activate appropriate plan",
              );
            toast.error(errorMsg, {
              toastId: INSUFFICIENT_SUBSCRIPTION,
              autoClose: 3500,
            });

            document.getElementById(profileButtonId)?.click();
            // NOTE: Wait for profile data to load
            setTimeout(
              () => document.getElementById(activationCodeId)?.focus(),
              2000,
            );
            break;
          case 409:
            errorMsg = error.response.data.message;
            toast.success(errorMsg);
            break;
          default:
            toast.error(
              getErrorMessageFromResponse(error) ??
                t("Something went wrong, please try again"),
              { toastId: OTHER_ERROR, autoClose: 3500 },
            );
            break;
        }

        return Promise.reject(error);
      },
    );

    return axiosInstance;
  }, [i18n.language, refreshToken, logout, t, history]);

  return (
    <AxiosContext.Provider value={axiosValue}>{children}</AxiosContext.Provider>
  );
};

export default AxiosProvider;
