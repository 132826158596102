import { useMutation } from "react-query";

import useAxios from "@src/config/axios/useAxios";
import { EDIT_USER_SETTINGS_ENDPOINT } from "@src/config/baseUrls";
import { UserSettings } from "@src/modules/App/hooks/useProfile";
import { AxiosError, AxiosResponse } from "axios";

const useDisableConnectorTooltip = () => {
  const axios = useAxios();

  return useMutation<AxiosResponse, AxiosError>(async () => {
    const updateModel: Partial<UserSettings> = {
      hasConnectorTooltipDisabled: true,
    };

    return await axios.patch(EDIT_USER_SETTINGS_ENDPOINT, updateModel);
  });
};

export default useDisableConnectorTooltip;
