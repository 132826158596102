import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import {
  defaultElementBorderRadius,
  defaultLetterSpacing,
  hideNumberInputArrows,
} from "@src/common/styles/mixins";
import ComponentWrapper from "@src/modules/shared/components/Form/ComponentWrapper";

export const INPUT_WIDTH = 320;

export const INPUT_VERTICAL_PADDING = 9;
export const INPUT_HORIZONTAL_PADDING = 16;

interface StyledInputProps {
  isError?: boolean;
  isIcon?: boolean;
}

interface IconProps {
  clickable?: boolean;
  disabled?: boolean;
}

export const Icon = styled.img<IconProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  right: ${INPUT_HORIZONTAL_PADDING}px;

  ${(p) =>
    p.clickable &&
    css`
      pointer-events: initial;
      cursor: pointer;
    `}

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.25;
    `}
`;

export const StyledInput = styled.input<StyledInputProps>`
  ${defaultElementBorderRadius};
  ${defaultLetterSpacing};
  background-color: ${colors.white};
  color: ${colors.almostBlack};
  appearance: none;
  transition: all 0.1s ease-out;

  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  padding: ${INPUT_VERTICAL_PADDING}px ${INPUT_HORIZONTAL_PADDING}px;
  width: 100%;

  border: 1px solid ${(p) => (p.isError ? colors.red : colors.gray1)};
  ${hideNumberInputArrows}

  ${(p) =>
    p.isIcon &&
    css`
      padding-right: ${2 * INPUT_HORIZONTAL_PADDING}px;
    `};

  &::placeholder {
    opacity: 1;
    color: ${colors.gray2};
  }

  &:focus:not([disabled]),
  &:active:not([disabled]) {
    outline: none;
    border-color: ${colors.darkBlue};
  }

  &:hover:not([disabled]) {
    border-color: ${colors.gray2};
  }

  &:disabled {
    background-color: ${colors.background};
    color: ${colors.gray2};
  }
`;

interface InputComponentWrapperProps {
  fullSize?: boolean;
}

export const InputComponentWrapper = styled(
  ComponentWrapper,
)<InputComponentWrapperProps>`
  width: ${(p) => (p.fullSize ? "100%" : `${INPUT_WIDTH}px`)};
`;

export const InputWrapper = styled.div`
  position: relative;
`;
