import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import {
  ConnectorLabelWrapper,
  SmallLabel,
} from "@src/modules/shared/components/ConnectorLabel/ConnectorLabel.components";

const ConnectorLabel: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();
  return (
    <ConnectorLabelWrapper>
      {children}
      <SmallLabel>{t("For Windows")}</SmallLabel>
    </ConnectorLabelWrapper>
  );
};

export default ConnectorLabel;
