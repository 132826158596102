import styled from "styled-components";

import { media } from "@src/common/styles/mixins";
import BaseSideModalWrapper from "@src/modules/shared/components/Modal/BaseSideModalWrapper";

const PROFILE_MODAL_WIDTH = 437;

export const ProfileModalWrapper = styled(BaseSideModalWrapper).attrs({
  direction: "right",
})`
  width: ${PROFILE_MODAL_WIDTH}px;

  ${media.down("MOBILE")`
    width: 100%;
  `}
  overflow: auto;
`;
