import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";

import useAxios from "@src/config/axios/useAxios";
import { ACTIVATE_SUBSCRIPTION_ENDPOINT } from "@src/config/baseUrls";
import { ResponseWithMessage } from "@src/modules/shared/types";

export interface ActivationModel {
  subscriptionCode: string;
}

const useRegister = () => {
  const axios = useAxios();

  return useMutation<
    AxiosResponse<string>,
    AxiosError<ResponseWithMessage>,
    ActivationModel
  >(
    async (values: ActivationModel) =>
      await axios.post(ACTIVATE_SUBSCRIPTION_ENDPOINT, values),
  );
};

export default useRegister;
