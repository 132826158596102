import { PageableList } from "@src/modules/App/Notifications/shared/pageableList";
import { INotification } from "@src/modules/App/Notifications/shared/types";

export enum ActionTypes {
  AddNotification,
  MarkedAsRead,
  ReceiveNotifications,
  SetUnreadFlag,
}

export type Actions =
  | {
      type: ActionTypes.AddNotification;
      data: INotification;
    }
  | {
      type: ActionTypes.MarkedAsRead;
      notificationId?: string;
    }
  | {
      type: ActionTypes.ReceiveNotifications;
      page: PageableList<INotification>;
    }
  | {
      type: ActionTypes.SetUnreadFlag;
      unreadFlag: boolean;
    };
