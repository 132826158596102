import useMedia from "use-media";

import { BREAKPOINTS } from "@src/common/styles/const";

export const useIsMobile = () =>
  useMedia({ maxWidth: BREAKPOINTS.MOBILE.max! });
export const useIsMobileSmall = () =>
  useMedia({ maxWidth: BREAKPOINTS.MOBILE_SMALL.max! });
export const useIsMobileHorizontal = () =>
  useMedia({ maxHeight: BREAKPOINTS.MOBILE.min! });
export const useIsTablet = () =>
  useMedia({
    minWidth: BREAKPOINTS.TABLET.min!,
    maxWidth: BREAKPOINTS.TABLET.max!,
  });
export const useIsTabletDown = () =>
  useMedia({
    maxWidth: BREAKPOINTS.TABLET.max!,
  });
export const useIsPortrait = () => useMedia({ orientation: "portrait" });
