import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_L,
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_XS,
} from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import { ReactComponent as Error } from "@src/modules/App/Profile/ViewProfile/icons/error.svg";

interface DetailsProps {
  isActive: boolean;
}

export const SubscriptionDetailsWrapper = styled.div<DetailsProps>`
  width: 100%;
  margin-bottom: ${DEFAULT_MARGIN_L}px;
  padding-bottom: ${DEFAULT_MARGIN_XS}px;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${colors.white};
  color: ${(p) => (p.isActive ? colors.almostBlack : colors.gray2)};

  ${media.down("MOBILE")`
    margin-bottom: ${DEFAULT_MARGIN_M}px;
  `}
`;

export const ExpirationDate = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorLogo = styled(Error)`
  margin-left: 5px;
`;
