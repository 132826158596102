import { FunctionComponent } from "react";

import useDownloadEventHandler from "@src/common/eventHandlers/useDownloadEventHandler";
import { useIsTabletDown } from "@src/common/styles/media";
import {
  Content,
  Wrapper,
} from "@src/modules/App/AuthedLayout/AuthedLayout.components";
import Sidebar from "@src/modules/App/Sidebar";
import Topbar from "@src/modules/App/Topbar";

const AuthedLayout: FunctionComponent = ({ children }) => {
  const isTabletDown = useIsTabletDown();
  useDownloadEventHandler();

  return (
    <>
      <Topbar isAuthed={true} />
      <Wrapper>
        {!isTabletDown && <Sidebar />}
        <Content as="main">{children}</Content>
      </Wrapper>
    </>
  );
};

export default AuthedLayout;
