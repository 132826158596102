import styled, { keyframes } from "styled-components";

import { DEFAULT_MARGIN_M } from "@src/common/styles/const";
import { ReactComponent as Loader } from "@src/modules/shared/components/FileLoader/icons/loader.svg";

const animation = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

export const FileLoader = styled(Loader)<{ size: "large" | "small" }>`
  animation: ${animation} 1s infinite;
  margin-left: ${DEFAULT_MARGIN_M}px;
  height: ${({ size }) => (size === "large" ? "35px" : "24px")};
  width: ${({ size }) => (size === "large" ? "35px" : "24px")};
`;
