import {
  ComponentPropsWithoutRef,
  forwardRef,
  FunctionComponent,
  Ref,
} from "react";

import ErrorMessage from "@src/modules/shared/components/Form/ErrorMessage";
import {
  Icon,
  InputComponentWrapper,
  InputWrapper,
  StyledInput,
} from "@src/modules/shared/components/Form/Input/Input.components";

export interface InputProps extends ComponentPropsWithoutRef<"input"> {
  type?: string;
  error?: string;
  showError?: boolean;
  fullSize?: boolean;
  icon?: string;
  onIconClick?: () => void;
}

export const Input: FunctionComponent<
  InputProps & {
    forwardedRef?: Ref<HTMLInputElement>;
  }
> = ({
  type = "text",
  error,
  showError = false,
  fullSize = false,
  disabled,
  icon,
  onIconClick,
  className,
  forwardedRef,
  ...inputProps
}) => (
  <InputComponentWrapper
    className={className}
    isError={!!error}
    showError={showError}
    fullSize={fullSize}
  >
    <InputWrapper>
      <StyledInput
        {...inputProps}
        ref={forwardedRef}
        type={type}
        disabled={disabled}
        isError={!!error}
        isIcon={!!icon}
      />
      {icon && (
        <Icon
          src={icon}
          disabled={disabled}
          clickable={!!onIconClick && !disabled}
          onClick={onIconClick}
        />
      )}
    </InputWrapper>
    {error && showError && <ErrorMessage>{error}</ErrorMessage>}
  </InputComponentWrapper>
);

const forwardedInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => <Input {...props} forwardedRef={ref} />,
);

forwardedInput.displayName = "Input";

export default forwardedInput;
