import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import { DEFAULT_MARGIN_S, DEFAULT_MARGIN_XS } from "@src/common/styles/const";
import {
  defaultElementBorderRadius,
  defaultLetterSpacing,
} from "@src/common/styles/mixins";

type ButtonType = "primary" | "secondary" | "tertiary" | "delete";
type ButtonSize = "large" | "small" | "fullSize";

export interface StyledButtonProps {
  buttonType?: ButtonType;
  size?: ButtonSize;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${defaultElementBorderRadius};
  ${defaultLetterSpacing};
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: ${DEFAULT_MARGIN_S}px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: inherit;
  text-align: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;

  ${(p) => {
    if (p.size === "large") {
      return css`
        height: 40px;
        width: 320px;
        font-size: 16px;
      `;
    } else {
      return css`
        padding: 6px 14px;
        font-size: 14px;
      `;
    }
  }}

  ${(p) =>
    p.fullWidth &&
    css`
      width: 100%;
    `}

  ${(p) => {
    switch (p.buttonType) {
      case "primary":
        return css`
          background-color: ${p.disabled ? colors.gray2 : colors.darkBlue};
          color: ${colors.white};
          border: none;

          ${!p.disabled &&
          `&:hover {
            background-color: ${colors.darkBlue2};
          }`}
        `;
      case "secondary":
        return css`
          background-color: transparent;
          color: ${p.disabled ? colors.gray2 : colors.darkBlue};
          border: 1px solid ${p.disabled ? colors.gray2 : colors.darkBlue};

          ${!p.disabled &&
          `&:hover {
            color: ${colors.darkBlue2};
            border-color: ${colors.darkBlue2};
          }`}
        `;
      case "tertiary":
        return css`
          border: none;
          color: ${p.disabled ? colors.gray2 : colors.darkBlue};
          font-size: 12px;
          gap: ${DEFAULT_MARGIN_XS}px;
          padding: ${DEFAULT_MARGIN_XS}px;

          ${!p.disabled &&
          ` &:hover {
            color: ${colors.darkBlue2};
          }`}
        `;
      case "delete":
        return css`
          background-color: ${p.disabled ? colors.gray2 : colors.red};
          color: ${colors.white};
          border: none;

          ${!p.disabled &&
          `&:hover {
            background-color: ${colors.red};
          }`}
        `;
    }
  }};
`;
