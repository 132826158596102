import format from "date-fns/format";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { CONNECTOR_LOG_FORMAT } from "@src/common/helpers/dateFormats";
import {
  ConnectorLogWrapper,
  IpAddress,
  LocationName,
  LogDate,
  LogDescription,
  LogStatus,
} from "@src/modules/App/Connector/ConnectorLog/ConnectorLog.components";
import { ReactComponent as Signal } from "@src/modules/App/Connector/ConnectorLog/icons/signal.svg";
import { ConnectorLogData } from "@src/modules/App/Connector/useConnectorLogs";

const ConnectorLog: FunctionComponent<ConnectorLogData> = ({
  date,
  locationName,
  message,
  ipAddress,
  isConnected,
  status,
}) => {
  const { t } = useTranslation();

  return (
    <ConnectorLogWrapper status={status}>
      <LogDate>{format(new Date(date), CONNECTOR_LOG_FORMAT)}</LogDate>
      <LocationName>{locationName}</LocationName>
      <IpAddress>{ipAddress}</IpAddress>
      {isConnected && (
        <LogStatus>
          <Signal />
          {t("connected")}
        </LogStatus>
      )}
      <LogDescription>{message}</LogDescription>
    </ConnectorLogWrapper>
  );
};

export default ConnectorLog;
