export const DAY_MONTH_FORMAT = "dd/MM";
export const DATE_FORMAT = [DAY_MONTH_FORMAT, "yyyy"].join("/");
export const FILENAME_DATE_FORMAT = DATE_FORMAT.replaceAll("/", "_");
export const CHART_DATE_FORMAT = DATE_FORMAT.replaceAll("/", ".");
export const HOUR_FORMAT = "hh:mm a";
export const HOUR_24_FORMAT = "HH:mm";
export const FULL_HOUR_FORMAT = "hh:mm:ss a";
export const DATE_HOUR_FORMAT = [DATE_FORMAT, HOUR_FORMAT].join(" ");
export const FULL_DATE_HOUR_FORMAT = [DATE_FORMAT, FULL_HOUR_FORMAT].join(" ");
export const DATE_HOUR_24_FORMAT = [DATE_FORMAT, HOUR_24_FORMAT].join(" ");
export const CONNECTOR_LOG_FORMAT = [CHART_DATE_FORMAT, HOUR_24_FORMAT].join(
  ", ",
);
export const ISO_DATE_ONLY = "yyyy-MM-dd";

export const DAY_OF_WEEK_FORMAT = "cccc";
export const DAY_OF_WEEK_SINGLE_LETTER = "ccccc";
export const DAY_OF_WEEK_SHORTCUT_FORMAT = "cccccc";

export const MONTH_YEAR_FORMAT = "MMMM yyyy";
export const FULL_MONTH_DATE_FORMAT = "MMMM dd, yyyy";
