import { useEffect, useState } from "react";

import AppEventTarget, {
  appEventTypes,
} from "@src/common/events/AppEventTarget";
import TokenChangeEvent from "@src/common/events/TokenChangeEvent";
import { getUserToken, UserToken } from "@src/common/helpers/userToken";

const useTokenChangeHandler = () => {
  const [token, setToken] = useState<UserToken | null>(getUserToken());

  useEffect(() => {
    const handler = ({ detail: { userToken } }: TokenChangeEvent) => {
      setToken(userToken);
    };

    AppEventTarget.addEventListener(appEventTypes.TOKEN_CHANGE, {
      handleEvent: handler,
    });
    return () => {
      AppEventTarget.removeEventListener(appEventTypes.TOKEN_CHANGE, {
        handleEvent: handler,
      });
    };
  }, []);

  return [token];
};

export default useTokenChangeHandler;
