import styled from "styled-components";

import { media } from "@src/common/styles/mixins";
import { HEADER_HEIGHT } from "@src/modules/App/Topbar/Topbar.components";

export const PublicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;

  ${media.down("MOBILE")`
    position: fixed;
    top: ${HEADER_HEIGHT}px;
    bottom: 0;
    left:0;
    right:0;
  `}
`;
