import styled from "styled-components";

import { DEFAULT_MARGIN_XL, DEFAULT_MARGIN_M } from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import Button from "@src/modules/shared/components/Button";

export const DropdownsWrapper = styled.div`
  display: flex;
  margin-bottom: ${DEFAULT_MARGIN_XL}px;

  ${media.down("MOBILE")`
    margin-bottom: ${DEFAULT_MARGIN_M}px;
  `}
`;

export const LogoutButton = styled(Button)`
  margin: auto 40px ${DEFAULT_MARGIN_XL}px auto;
`;
