import { Formik, FormikHelpers } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { CHANGES_APPLIED } from "@src/common/helpers/toastIds";
import useRefreshToken from "@src/common/hooks/useRefreshToken";
import { queryKeys } from "@src/config/queryKeys";
import useProfile from "@src/modules/App/hooks/useProfile";
import EditProfileForm from "@src/modules/App/Profile/EditProfile/EditProfileForm";
import useEditProfile, {
  EditProfileModel,
} from "@src/modules/App/Profile/EditProfile/useEditProfile";
import {
  BottomProfileWrapper,
  ProfileWrapper,
} from "@src/modules/App/Profile/shared/components";
import ProfileButton from "@src/modules/App/Topbar/ProfileButton";

interface Props {
  goToView: () => void;
}

const EditProfile: FunctionComponent<Props> = ({ goToView }) => {
  const mutation = useEditProfile();
  const refreshToken = useRefreshToken();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: profileData } = useProfile();

  const initialValues: EditProfileModel = {
    userName: "",
    oldPassword: "",
    password: "",
  };

  const validationSchema = Yup.object().shape(
    {
      userName: Yup.string().when(["oldPassword", "password"], {
        is: (old: string, pass: string) => !old && !pass,
        then: Yup.string().required("All fields cannot be empty"),
      }),
      oldPassword: Yup.string()
        .min(6, t("Too short!"))
        .max(100, t("Too long!"))
        .when("password", {
          is: (password: string) => !!password,
          then: Yup.string().required("Enter your current password"),
        }),
      password: Yup.string()
        .min(6, t("Too short!"))
        .max(100, t("Too long!"))
        .when("oldPassword", {
          is: (old: string) => !!old,
          then: Yup.string().required("Enter new password"),
        }),
    },
    [["oldPassword", "password"]],
  );

  const handleSubmit = (
    values: EditProfileModel,
    { setSubmitting }: FormikHelpers<EditProfileModel>,
  ) => {
    const setValues: EditProfileModel = {
      userName: values.userName === "" ? null : values.userName,
      oldPassword: values.oldPassword === "" ? null : values.oldPassword,
      password: values.password === "" ? null : values.password,
    };

    mutation.mutate(setValues, {
      onSuccess: async () => {
        toast.success(t("Changes successfully applied"), {
          toastId: CHANGES_APPLIED,
        });
        goToView();
        await refreshToken();
        await queryClient.invalidateQueries(queryKeys.PROFILE);
      },
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <ProfileWrapper>
      <BottomProfileWrapper>
        <ProfileButton size="large" />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formProps) => (
            <EditProfileForm
              {...formProps}
              userName={profileData?.name ?? ""}
              goToProfileView={goToView}
            />
          )}
        </Formik>
      </BottomProfileWrapper>
    </ProfileWrapper>
  );
};

export default EditProfile;
