import { useEffect } from "react";
import { useQueryClient } from "react-query";

import AppEventTarget, {
  appEventTypes,
} from "@src/common/events/AppEventTarget";

const useConnectorStatusHandler = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries();

  useEffect(() => {
    AppEventTarget.addEventListener(
      appEventTypes.CONNECTOR_STATUS_CHANGE,
      invalidateQuery,
    );
    return () =>
      AppEventTarget.removeEventListener(
        appEventTypes.CONNECTOR_STATUS_CHANGE,
        invalidateQuery,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useConnectorStatusHandler;
