import { useEffect, useState } from "react";

import AppEventTarget, {
  appEventTypes,
} from "@src/common/events/AppEventTarget";
import UpdateConnectorStatusEvent, {
  ConnectorActionStatus,
} from "@src/common/events/UpdateConnectorStatusEvent";

const useUpdateConnectorStatus = () => {
  const [status, setStatus] = useState<ConnectorActionStatus>(
    ConnectorActionStatus.None,
  );

  useEffect(() => {
    const handler = ({ detail: { status } }: UpdateConnectorStatusEvent) =>
      setStatus(status);

    AppEventTarget.addEventListener(appEventTypes.UPDATE_CONNECTOR_STATUS, {
      handleEvent: handler,
    });
    return () => {
      AppEventTarget.removeEventListener(
        appEventTypes.UPDATE_CONNECTOR_STATUS,
        {
          handleEvent: handler,
        },
      );
    };
  }, []);

  return [status];
};

export default useUpdateConnectorStatus;
