import styled from "styled-components";

import { media } from "@src/common/styles/mixins";
import { SIDEBAR_WIDTH } from "@src/modules/App/Sidebar/Sidebar.components";
import { HEADER_HEIGHT } from "@src/modules/App/Topbar/Topbar.components";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const Content = styled.main`
  flex: 1;
  margin-left: ${SIDEBAR_WIDTH}px;
  z-index: 1;

  ${media.down("TABLET")`
    margin-left: 0;
  `}

  height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow: auto;
`;
