export interface ResponseWithMessage {
  message?: string;
}

export interface ResponseWithErrors {
  errors: {
    [key: string]: string[];
  };
}

export enum TestStatus {
  Ready = "Ready",
  InProgress = "InProgress",
  Fail = "Fail",
  ActionNeeded = "ActionNeeded",
}

export enum DeviceType {
  Tomography = "Tomography",
  DentalCBCT = "DentalCBCT",
  IntraOral = "IntraOral",
  OPG = "OPG",
  Fluoroscopy = "Fluoroscopy",
  Mammography = "Mammography",
  Resonance = "Resonance",
  Nuclear = "Nuclear",
  Radiography = "Radiography",
  Ultrasound = "Ultrasound",
}

export enum ReportTypes {
  Full = "Full",
  Simple = "Simple",
}

export interface DeviceModel {
  name: string;
  modality: DeviceType | undefined;
  locationId: string;
  manufacturer: string;
  room: string;
  ipAddress: string;
  notes: string;
  serialNumber: string;
  reportType: string;
  isDigital: boolean;
  focalParameters?: FocalParameters;
}

export interface FocalParameters {
  fdd?: number | null;
  ftd?: number | null;
  fod?: number | null;
}

export interface StudyTestResult {
  id: string;
  testId: number;
  name: string;
  daysInterval: number;
  resultStatus: TestStatus;
  isBaseTest: boolean;
  isTestDeleted: boolean;
  isCustomTest: boolean;
}

export interface StudyDetail {
  id: string;
  hasDicoms: boolean;
  shouldBeReanalyzed: boolean;
  customLabel: string;
  studyDate: string;
  groupName: string;
  userName: string;
  tests: StudyTestResult[];
  connectorSeriesId: string;
  canBeEditedOrDeleted: boolean;
  hasPdfReport: boolean;
  studyGroupId: string;
}
