import { FunctionComponent } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";

import isDevelopment from "@src/common/helpers/isDevelopment";
import AxiosProvider from "@src/config/axios/AxiosProvider";
import App from "@src/modules/App/App";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const Root: FunctionComponent = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AxiosProvider>
        <App />
        {isDevelopment() && <ReactQueryDevtools />}
      </AxiosProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
export default Root;
