import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEn from "@src/config/i18n/en/translation.json";
import translationPl from "@src/config/i18n/pl/translation.json";
import translationRu from "@src/config/i18n/ru/translation.json";
import issuesEn from "@src/config/i18n/en/issues.json";
import issuesPl from "@src/config/i18n/pl/issues.json";

const resources = {
  en: {
    translation: translationEn,
    issues: issuesEn,
  },
  pl: {
    translation: translationPl,
    issues: issuesPl,
  },
  ru: {
    translation: translationRu,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18n;
