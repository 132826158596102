import { appEventTypes } from "@src/common/events/AppEventTarget";
import { UserToken } from "@src/common/helpers/userToken";

interface TokenChangeEventDetail {
  userToken: UserToken | null;
}

class TokenChangeEvent extends CustomEvent<TokenChangeEventDetail> {
  constructor(userToken: UserToken | null) {
    super(appEventTypes.TOKEN_CHANGE, {
      detail: {
        userToken,
      },
    });
  }
}

export default TokenChangeEvent;
