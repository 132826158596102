import styled from "styled-components";

import colors from "@src/common/styles/colors";
import { DEFAULT_MARGIN_S } from "@src/common/styles/const";

export const NavButtonWrapper = styled.button`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${DEFAULT_MARGIN_S}px;
  background-color: ${colors.gray1};
  color: ${colors.almostBlack};
  height: 32px;
  padding: ${DEFAULT_MARGIN_S}px;
  border-radius: 8px;
`;
