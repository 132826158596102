import { useTranslation } from "react-i18next";

import useProfile, { AccountType } from "@src/modules/App/hooks/useProfile";

const useSubscriptionLabel = () => {
  const { data: profileData } = useProfile();
  const { t } = useTranslation();

  return profileData?.accountType === AccountType.GroupMember
    ? t("Group Member")
    : profileData?.accountType === AccountType.GroupAdmin
    ? t("Group Admin")
    : profileData?.subscription.pricingPlanId;
};

export default useSubscriptionLabel;
