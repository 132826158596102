import { appEventTypes } from "@src/common/events/AppEventTarget";

type DownloadEventType = "start" | "end";

class FileDownloadEvent extends CustomEvent<DownloadEventType> {
  constructor(type: DownloadEventType) {
    super(appEventTypes.FILE_DOWNLOAD, {
      detail: type,
    });
  }
}

export default FileDownloadEvent;
