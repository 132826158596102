import { LottieOptions, useLottie } from "lottie-react";
import { FunctionComponent } from "react";
import styled, { css } from "styled-components";

import animationData from "@src/modules/shared/components/ScreenLoading/loader.json";

const Background = styled.div<{ adjustSize?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${(p) =>
    p.adjustSize
      ? css`
          flex: 1;
        `
      : css`
          height: 60vh;
        `}
`;

interface Props {
  adjustSize?: boolean;
}

const ScreenLoading: FunctionComponent<Props> = ({ adjustSize }) => {
  const options: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData,
    style: {
      width: "15%",
      height: "25%",
    },
  };

  const { View } = useLottie(options);

  return <Background adjustSize={adjustSize}>{View}</Background>;
};

export default ScreenLoading;
