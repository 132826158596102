import { FieldInputProps } from "formik";
import { ChangeEvent, FunctionComponent, useCallback } from "react";

import {
  CheckboxComponentWrapper,
  CheckboxLabel,
  CheckboxTextWrapper,
  CheckboxWrapper,
  InputCheckmark,
  StyledCheckbox,
  StyledInput,
} from "@src/modules/shared/components/Form/Checkbox/Checkbox.components";
import ErrorMessage from "@src/modules/shared/components/Form/ErrorMessage";

export interface CheckboxProps {
  name: string;
  error?: string;
  checked?: boolean;
  showError?: boolean;
  hasBigLabel?: boolean;
  hasCenteredLabel?: boolean;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  field?: FieldInputProps<boolean>;
  stopPropagation?: boolean;
}

const Checkbox: FunctionComponent<CheckboxProps> = ({
  name,
  field,
  error,
  checked,
  onChange,
  showError = true,
  hasBigLabel = false,
  hasCenteredLabel = false,
  children,
  stopPropagation = false,
}) => {
  const handleChange = useCallback(
    (e) => {
      field?.onChange(e);
      if (onChange) {
        onChange(e);
      }
    },
    [field, onChange],
  );
  return (
    <CheckboxComponentWrapper stopPropagation={stopPropagation}>
      <CheckboxWrapper isBig={hasBigLabel}>
        <StyledInput
          id={name}
          {...(field || checked !== undefined
            ? { checked: !!field?.checked || !!checked }
            : { defaultChecked: false })}
          onChange={handleChange}
        />
        <StyledCheckbox htmlFor={name} hasError={!!error}>
          <InputCheckmark />
        </StyledCheckbox>
        <CheckboxTextWrapper hasCenteredLabel={hasCenteredLabel}>
          {children && (
            <CheckboxLabel isBig={hasBigLabel} htmlFor={name}>
              {children}
            </CheckboxLabel>
          )}
          {showError && error && <ErrorMessage>{error}</ErrorMessage>}
        </CheckboxTextWrapper>
      </CheckboxWrapper>
    </CheckboxComponentWrapper>
  );
};
export default Checkbox;
