import {
  ERROR_HEIGHT,
  ERROR_MARGIN,
} from "@src/modules/shared/components/Form/ErrorMessage";
import styled, { css } from "styled-components";

interface WrapperProps {
  isError?: boolean;
  showError?: boolean;
}

const ComponentWrapper = styled.div<WrapperProps>`
  position: relative;
  ${(p) =>
    p.showError &&
    css`
      margin-bottom: ${p.isError ? 0 : ERROR_HEIGHT + ERROR_MARGIN}px;
    `};
`;

export default ComponentWrapper;
