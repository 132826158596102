import { FunctionComponent, useEffect, useRef } from "react";

import { LoaderWrapper } from "@src/modules/shared/components/InfiniteScrollLoader/InfiniteScrollLoader.components";
import SmallLoader from "@src/modules/shared/components/ScreenLoading/SmallLoader";

interface Props {
  handleObserver: (entries: IntersectionObserverEntry[]) => void;
}

const InfiniteScrollLoader: FunctionComponent<Props> = ({ handleObserver }) => {
  const loaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ref = loaderRef.current;
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      threshold: 0,
    });
    if (ref) observer.observe(ref);

    return () => {
      if (ref) observer.unobserve(ref);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoaderWrapper ref={loaderRef}>
      <SmallLoader />
    </LoaderWrapper>
  );
};

export default InfiniteScrollLoader;
