import { createGlobalStyle } from "styled-components";

import colors from "@src/common/styles/colors";

const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
		background-color: ${colors.background};

		input {
				font-family: inherit;
		}

		*::-webkit-scrollbar {
			width: 6px;
			height: 6px;
			cursor: pointer;
		}

		*::-webkit-scrollbar-thumb {
			background: #cdcdcd;
			border-radius: 3px;
			cursor: pointer;
		}

		*::-webkit-scrollbar-thumb:hover {
			background: darken($color: #cdcdcd, $amount: 50);
		}

		* { 
			scrollbar-width: thin;
			scrollbar-color: lightgray transparent;
		}

	}

	button{
		font-family: inherit;
		background-color: transparent;
		border: none;
		padding: 0;
		cursor: pointer;
	}

	p{
		margin:0;
	}

	a{
		text-decoration: none;
		color: inherit;
	}
`;

export default GlobalStyle;
