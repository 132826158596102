import { AxiosError } from "axios";
import qs from "qs";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useQuery } from "react-query";

import { UPDATES_ENDPOINT } from "@src/config/baseUrls";
import { queryKeys } from "@src/config/queryKeys";
import { useAxiosCMS } from "@src/modules/KnowledgeBase/shared/hooks/useAxiosCMS";

export interface UpdateData {
  id: string;
  title: string;
  date: string;
  newFeatures: string;
  improvements: string;
}

const PAGE_SIZE = 5;

const useUpdates = () => {
  const { i18n } = useTranslation();
  const axios = useAxiosCMS();

  const { data: countData } = useQuery<number>(
    queryKeys.UPDATES_COUNT,
    async () => {
      const querystring = qs.stringify({
        _locale: i18n.language.split("-")[0],
      });
      const { data } = await axios.get(
        `${UPDATES_ENDPOINT}/count?${querystring}`,
      );
      return data;
    },
  );

  const pageCount = countData ? Math.ceil(countData / PAGE_SIZE) : 0;

  const fetchUpdates = async ({ pageParam = 1 }) => {
    const querystring = qs.stringify({
      _locale: i18n.language.split("-")[0],
      _start: (pageParam - 1) * PAGE_SIZE,
      _limit: PAGE_SIZE,
      _sort: "date:DESC",
    });

    const { data } = await axios.get(`${UPDATES_ENDPOINT}?${querystring}`);
    return data;
  };

  return useInfiniteQuery<UpdateData[], AxiosError>(
    [queryKeys.UPDATES, i18n.language],
    fetchUpdates,
    {
      getNextPageParam: (_, allPages) => {
        const currentPage = Math.ceil(
          allPages.reduce((prev, cur) => prev + cur.length, 0) / PAGE_SIZE,
        );

        return pageCount > currentPage ? currentPage + 1 : undefined;
      },
      keepPreviousData: true,
    },
  );
};

export default useUpdates;
