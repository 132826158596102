import styled from "styled-components";

import { media } from "@src/common/styles/mixins";
import {
  HEADER_HEIGHT,
  HEADER_TABLET_PADDING,
} from "@src/modules/App/Topbar/Topbar.components";
import BaseSideModalWrapper from "@src/modules/shared/components/Modal/BaseSideModalWrapper";

const TABLET_SIDEBAR_WIDTH = 332;
const MOBILE_SIDEBAR_WIDTH = 280;

export const MobileSidebarModalWrapper = styled(BaseSideModalWrapper).attrs({
  direction: "left",
})`
  width: ${TABLET_SIDEBAR_WIDTH}px;

  ${media.down("MOBILE")`
    width: ${MOBILE_SIDEBAR_WIDTH}px;
  `}
`;

export const SidebarTopbar = styled.div`
  padding: 0 ${HEADER_TABLET_PADDING}px;
  height: ${HEADER_HEIGHT}px;
  display: flex;
`;
