import { FunctionComponent, useCallback } from "react";

import {
  MobileSidebarModalWrapper,
  SidebarTopbar,
} from "@src/modules/App/MobileSidebar/MobileSidebarModal.components";
import Sidebar from "@src/modules/App/Sidebar";
import DiagnomaticLogo from "@src/modules/App/Topbar/DiagnomaticLogo";
import SidebarHamburger from "@src/modules/App/Topbar/Hamburger";
import { TopbarContent } from "@src/modules/App/Topbar/Topbar.components";
import { ModalTransitionProps } from "@src/modules/shared/components/Modal/ModalContainer.components";
import useModal from "@src/modules/shared/components/Modal/useModal";

const MobileSidebar: FunctionComponent<ModalTransitionProps> = ({ status }) => {
  const { closeModal } = useModal();
  const onHamburgerClick = useCallback(() => closeModal(), [closeModal]);

  return (
    <MobileSidebarModalWrapper status={status}>
      <SidebarTopbar>
        <TopbarContent>
          <SidebarHamburger onClick={onHamburgerClick} />
          <DiagnomaticLogo />
        </TopbarContent>
      </SidebarTopbar>
      <Sidebar />
    </MobileSidebarModalWrapper>
  );
};

export default MobileSidebar;
