import styled from "styled-components";

import colors from "@src/common/styles/colors";
import { DEFAULT_MARGIN_M } from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const BottomProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  padding: 40px;
  height: 100%;

  ${media.down("MOBILE")`
    padding: ${DEFAULT_MARGIN_M}px;
    width: 100vw;
  `}
`;
