import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_XL,
  DEFAULT_MARGIN_XS,
} from "@src/common/styles/const";
import {
  defaultBounceTransition,
  defaultLetterSpacing,
  media,
} from "@src/common/styles/mixins";
import Button from "@src/modules/shared/components/Button";

interface WrapperProps {
  offsetRight: number;
  isVisible: boolean;
}

export const TooltipWrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 70px;
  right: ${DEFAULT_MARGIN_M}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${DEFAULT_MARGIN_M}px;
  padding: ${DEFAULT_MARGIN_M}px;
  background-color: ${colors.white};
  color: ${colors.almostBlack};
  max-width: min(400px, calc(100vw - 32px));
  border-radius: 12px;
  filter: drop-shadow(0px 4px 4px rgba(3, 74, 100, 0.25));

  transform-origin: ${({ offsetRight }) => `calc(100% - ${offsetRight}px)`} 0;
  transform: ${({ isVisible }) => (isVisible ? "scale(1)" : "scale(0)")};
  ${defaultBounceTransition};

  cursor: default;

  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    width: 32px;
    height: 16px;
    background-color: ${colors.white};
    clip-path: polygon(0 100%, 50% 0, 100% 100%);
    transform: translateX(50%);
    right: ${({ offsetRight }) => offsetRight}px;
  }
`;

export const TooltipHeader = styled.h2`
  font-size: 18px;
  margin: 0;
  text-align: left;

  ${media.down("MOBILE")`
    font-size: 14px;
  `}
`;

export const TooltipDescription = styled.p`
  ${defaultLetterSpacing};
  text-align: left;
`;

export const TooltipFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TooltipButton = styled(Button).attrs({
  buttonType: "primary",
  size: "small",
})`
  padding: ${DEFAULT_MARGIN_XS}px ${DEFAULT_MARGIN_XL}px;
`;
