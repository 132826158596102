import jwtDecode, { JwtPayload } from "jwt-decode";
import { differenceInMinutes } from "date-fns";

import AppEventTarget from "@src/common/events/AppEventTarget";
import TokenChangeEvent from "@src/common/events/TokenChangeEvent";

const USER_TOKEN_KEY = "userToken";

export enum PricingPlanEnum {
  ECO = "ECO",
  BASIC = "BASIC",
  PRO = "PRO",
  DEMO = "DEMO",
  SIEMENS = "SIEMENS",
  ENTERPRISE = "ENTERPRISE",
}

export interface SubscriptionResponse {
  activePlan: PricingPlanEnum;
  expirationDate: string;
  performedTests: number;
  testsLimit: number;
  diagnosticDevices: number;
  devicesLimit: number;
  usedPhantoms: number;
  phantomsLimit: number;
}

export interface TokenResponse {
  token: string;
  email: string;
  userName: string;
  subscription: SubscriptionResponse;
}

type Subscription = Omit<SubscriptionResponse, "expirationDate"> & {
  expirationDate: Date;
};

export interface UserToken {
  token: string;
  email: string;
  userName: string;
  issuedAt: Date;
  expiresAt: Date;
  subscription: Subscription;
}

export const userTokenFromResponse = (
  response: TokenResponse,
): UserToken | null => {
  const decodedToken = jwtDecode<JwtPayload>(response.token);
  try {
    if (decodedToken.iat === undefined || decodedToken.exp === undefined) {
      throw new Error("Invalid decoded token dates");
    }
  } catch (error) {
    return null;
  }

  return {
    issuedAt: new Date(decodedToken.iat * 1000),
    expiresAt: new Date(decodedToken.exp * 1000),
    token: response.token,
    email: response.email,
    userName: response.userName,
    subscription: {
      ...response.subscription,
      expirationDate: new Date(response.subscription.expirationDate),
    },
  };
};

export const setUserToken = (userToken: UserToken | null) => {
  localStorage.setItem(USER_TOKEN_KEY, JSON.stringify(userToken));
  AppEventTarget.dispatchEvent(new TokenChangeEvent(userToken));
};

export const getUserToken = (): UserToken | null => {
  try {
    const userToken = JSON.parse(
      localStorage.getItem(USER_TOKEN_KEY) ?? "null",
    );

    if (userToken === null) {
      return null;
    }

    ["token", "issuedAt", "expiresAt", "subscription"].forEach((key) => {
      if (!(key in userToken)) {
        throw new Error(`Missing key: ${key}`);
      }
    });

    userToken.issuedAt = new Date(userToken.issuedAt);
    if (isNaN(userToken.issuedAt)) {
      throw new Error("Invalid issue date");
    }

    userToken.expiresAt = new Date(userToken.expiresAt);
    if (isNaN(userToken.expiresAt)) {
      throw new Error("Invalid expiration date");
    }

    return userToken;
  } catch (err) {
    console.error(`Error parsing user token ${(err as Error)?.message}`);
    deleteUserToken();
    return null;
  }
};

export const deleteUserToken = () => {
  setUserToken(null);
};

export const getTimeToExpire = (token: UserToken) =>
  differenceInMinutes(token.expiresAt, new Date());
