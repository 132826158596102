import { appEventTypes } from "@src/common/events/AppEventTarget";

export enum ConnectorActionStatus {
  None,
  Receiving,
  Sending,
}

export interface ConnectorStatusEventDetail {
  status: ConnectorActionStatus;
}

class UpdateConnectorStatusEvent extends CustomEvent<ConnectorStatusEventDetail> {
  constructor(status: ConnectorActionStatus) {
    super(appEventTypes.UPDATE_CONNECTOR_STATUS, {
      detail: {
        status,
      },
    });
  }
}

export default UpdateConnectorStatusEvent;
