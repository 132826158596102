import { AxiosError } from "axios";

import {
  ResponseWithErrors,
  ResponseWithMessage,
} from "@src/modules/shared/types";

export const getErrorMessageFromResponse = (
  error: AxiosError<ResponseWithMessage & ResponseWithErrors>,
) => {
  let errorMessage;
  const fieldsErrors = error.response?.data?.errors;

  if (fieldsErrors) {
    const firstFieldErrors = Object.values(fieldsErrors).find(() => true);
    if (firstFieldErrors) {
      errorMessage = firstFieldErrors.find(() => true);
    }
  }

  return error.response?.data?.message ?? errorMessage;
};
