import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { REQUEST_SEND, SELECT_COUNTRY } from "@src/common/helpers/toastIds";
import useProfile from "@src/modules/App/hooks/useProfile";
import {
  SubscriptionHeader,
  SubscriptionRow,
  SubscriptionValue,
} from "@src/modules/App/Profile/ViewProfile/Subscription/shared/components";
import {
  ContinueSubscriptionInfo,
  InfoLogo,
  SubscriptionWrapper,
} from "@src/modules/App/Profile/ViewProfile/Subscription/SubscriptionContact/SubscriptionContact.components";
import useContactSales from "@src/modules/App/Profile/ViewProfile/Subscription/SubscriptionContact/useContactSales";
import Button from "@src/modules/shared/components/Button";

interface Props {
  phone: string | undefined;
  email: string | undefined;
}

const SubscriptionContact: FunctionComponent<Props> = ({
  phone,
  email,
  children,
}) => {
  const { t } = useTranslation();
  const { data: profileData } = useProfile();
  const mutation = useContactSales();

  const handleContact = () => {
    if (profileData?.country)
      mutation.mutate(undefined, {
        onSuccess: () => {
          toast.success(
            t(
              "Your request was sent to our Sales Team. Our Representative will reach to you soon",
            ),
            { toastId: REQUEST_SEND },
          );
        },
      });
    else
      toast.error(
        t(
          "Select your country to contact with the assigned sales representative",
        ),
        { toastId: SELECT_COUNTRY },
      );
  };

  return (
    <SubscriptionWrapper>
      <SubscriptionHeader isActive={true}>
        {t("Subscription code")}
      </SubscriptionHeader>
      {children}
      <ContinueSubscriptionInfo>
        <InfoLogo />
        {t(
          "Want to continue with your subscription or get an upgrade? Contact with your Sales Representative",
        )}
      </ContinueSubscriptionInfo>
      <SubscriptionRow>
        <Button onClick={handleContact} disabled={mutation.isLoading} fullWidth>
          {t("Get in touch with Sales")}
        </Button>
      </SubscriptionRow>

      <SubscriptionRow>
        {t("By phone")}
        {phone && (
          <SubscriptionValue as="a" href={`tel: ${phone}`}>
            {phone}
          </SubscriptionValue>
        )}
      </SubscriptionRow>
      <SubscriptionRow>
        {t("By e-mail")}
        {email && (
          <SubscriptionValue as="a" href={`mailto: ${email}`}>
            {email}
          </SubscriptionValue>
        )}
      </SubscriptionRow>
    </SubscriptionWrapper>
  );
};

export default SubscriptionContact;
