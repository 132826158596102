import styled, { css, keyframes } from "styled-components";

import colors from "@src/common/styles/colors";
import { ReactComponent as Bell } from "@src/modules/App/Topbar/icons/bell.svg";

export const NotificationsButtonWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: ${colors.gray1};

  width: 32px;
  height: 32px;

  border-radius: 50%;
  align-items: center;
`;

const ringAnimation = keyframes`
    0% {
      transform: rotate(0);
    }
    10% {
      transform: rotate(-30deg);
    }
    20% {
      transform: rotate(28deg);
    }
    30% {
      transform: rotate(-26deg);
    }
    40% {
      transform: rotate(24deg);
    }
    50% {
      transform: rotate(-22deg);
    }
    60% {
      transform: rotate(20deg);
    }
    70% {
      transform: rotate(-18deg);
    }
    80% {
      transform: rotate(16deg);
    }
    90% {
      transform: rotate(-14deg);
    }
    100% {
      transform: rotate(0);
    }`;

export const BellIcon = styled(Bell)<{ $animate: boolean }>`
  margin: 0 auto;
  display: block;
  cursor: pointer;
  transform-origin: 50% 4px;
  ${({ $animate }) =>
    $animate
      ? css`
          animation: ${ringAnimation} 1s ease-in-out 1;
        `
      : null}
`;

export const BadgeAnimation = keyframes`
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(0.7);
    }
    40% {
      transform: scale(1.3);
    }
    60% {
      transform: scale(0.7);
    }
    80% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
`;

export const NotificationsBadge = styled.div<{ animate: boolean }>`
  position: absolute;
  top: 16%;
  right: 20%;
  height: 11px;
  width: 11px;

  background-color: ${colors.red};
  border-radius: 50%;
  cursor: pointer;

  ${({ animate }) =>
    animate
      ? css`
          animation: ${BadgeAnimation} 1s ease-in-out 1;
        `
      : null}
  transform-origin: 50% 4px;
`;
