import React, { ReactElement, useCallback, useMemo, useState } from "react";

import sleep from "@src/common/helpers/sleep";
import ModalContainer from "@src/modules/shared/components/Modal/ModalContainer";
import {
  ContainerProps,
  MODAL_TRANSITION_TIME_MS,
} from "@src/modules/shared/components/Modal/ModalContainer.components";
import ModalContext, {
  ModalContextType,
} from "@src/modules/shared/components/Modal/ModalContext";

export type ModalContentType = ReactElement<ContainerProps>;

export interface ModalState {
  content?: ModalContentType;
  isOpen: boolean;
  position: "absolute" | "fixed";
  mode: "normal" | "side";
  showHeader?: boolean;
  /**
   * onBeforeClose handler
   *
   * return true to capture event and keep modal open
   */
  onBeforeClose?: () => boolean | void;
}

const ModalProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [state, setState] = useState<ModalState>({
    isOpen: false,
    position: "absolute",
    mode: "normal",
  });

  const closeModal = useCallback(
    () =>
      setState((prevState) => ({
        ...prevState,
        isOpen: false,
      })),
    [],
  );

  const contextValue = useMemo<ModalContextType>(
    () => ({
      openModal(content, showHeader, onBeforeClose) {
        setState({
          content,
          showHeader,
          onBeforeClose,
          isOpen: true,
          position: "absolute",
          mode: "normal",
        });
      },
      openFixedModal(content, showHeader, onBeforeClose) {
        setState({
          content,
          showHeader,
          onBeforeClose,
          isOpen: true,
          position: "fixed",
          mode: "normal",
        });
      },
      updateModal(content) {
        if (state.isOpen) {
          setState({
            ...state,
            content,
          });
        }
      },
      async openSideModal(content, showHeader, onBeforeClose) {
        if (state.content && state.isOpen) {
          closeModal();
          await sleep(MODAL_TRANSITION_TIME_MS);
        }
        setState({
          content,
          showHeader,
          onBeforeClose,
          isOpen: true,
          position: "fixed",
          mode: "side",
        });
      },
      closeModal: closeModal,
    }),
    [closeModal, state],
  );

  const handleClose = useCallback(
    async (event: React.MouseEvent) => {
      //TODO: check if it is really unnecessary
      // event.preventDefault();
      if (event.target !== event.currentTarget) {
        return;
      }

      const shouldCancel = await state.onBeforeClose?.();
      if (!shouldCancel) {
        contextValue.closeModal();
      }
    },
    [contextValue, state],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      <ModalContainer
        isOpen={state.isOpen}
        onBackgroundClick={handleClose}
        position={state.position}
        mode={state.mode}
        showHeader={state.showHeader}
      >
        {state.content}
      </ModalContainer>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
