class AppEventTarget extends EventTarget {}

export default new AppEventTarget();

export const appEventTypes = {
  TOKEN_CHANGE: "TOKEN_CHANGE",
  CONNECTOR_STATUS_CHANGE: "CONNECTOR_STATUS_CHANGE",
  UPDATE_CONNECTOR_STATUS: "UPDATE_CONNECTOR_STATUS",
  STUDY_DATE_CHANGE: "STUDY_DATE_CHANGE",
  SHOW_CONNECTOR_TOOLTIP: "SHOW_CONNECTOR_TOOLTIP",
  FILE_DOWNLOAD: "FILE_DOWNLOAD",
};
