import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useUpdates from "@src/modules/App/Updates/hooks/useUpdates";
import Update from "@src/modules/App/Updates/Update/Update";
import {
  UpdatesContainer,
  UpdatesModalWrapper,
} from "@src/modules/App/Updates/UpdatesModal/UpdatesModal.components";
import { PanelHeadline } from "@src/modules/shared/components/components";
import InfiniteScrollLoader from "@src/modules/shared/components/InfiniteScrollLoader";
import { ModalTransitionProps } from "@src/modules/shared/components/Modal/ModalContainer.components";

const UpdatesModal: FunctionComponent<ModalTransitionProps> = ({ status }) => {
  const { t } = useTranslation();
  const { data, hasNextPage, fetchNextPage } = useUpdates();

  const handleObserver = (entries: IntersectionObserverEntry[]) => {
    if (hasNextPage && entries[0].isIntersecting) fetchNextPage();
  };

  useEffect(() => {
    const storageDate = localStorage.getItem("lastUpdateDate");
    const newestUpdate = data?.pages.flatMap((page) => page).find(() => true);

    if (newestUpdate && newestUpdate.date !== storageDate) {
      localStorage.setItem("lastUpdateDate", newestUpdate.date);
    }
  }, [data]);

  return (
    <UpdatesModalWrapper status={status}>
      <PanelHeadline>{t("What's new")}</PanelHeadline>
      <UpdatesContainer>
        {data?.pages.flatMap((page) =>
          page.map((update) => <Update key={update.id} {...update} />),
        )}
        {hasNextPage && (
          <InfiniteScrollLoader handleObserver={handleObserver} />
        )}
      </UpdatesContainer>
    </UpdatesModalWrapper>
  );
};

export default UpdatesModal;
