import { useQuery } from "react-query";

import { PricingPlanEnum } from "@src/common/helpers/userToken";
import useAxios from "@src/config/axios/useAxios";
import { PROFILE_ENDPOINT } from "@src/config/baseUrls";
import { queryKeys } from "@src/config/queryKeys";
import useAppContext from "@src/modules/App/hooks/useAppContext";

export type StepStatus = "Todo" | "Active" | "Done";

export enum AccountType {
  ProProjectAdmin = "ProProjectAdmin",
  SuperAdmin = "SuperAdmin",
  GroupAdmin = "GroupAdmin",
  RegularUser = "RegularUser",
  GroupMember = "GroupMember",
}

export interface QuickSetupData {
  setupStepStatuses: {
    subscription: StepStatus;
    testEquipment: StepStatus;
    device: StepStatus;
    connector: StepStatus;
  };
  defaultLocationId: string;
}

export interface ProfileData {
  email: string;
  name: string;
  country: string;
  setupInfo: QuickSetupData;
  accountType: AccountType;
  groupIds?: string[];
  hasSetupFinished: boolean;
  subscription: SubscriptionData;
  hasValidSubscription: boolean;
  hasActiveWarranty: boolean;
  hasActiveConnector: boolean;
  settings: UserSettings;
}

export interface SubscriptionData {
  pricingPlanId: PricingPlanEnum;
  firstActivation: string;
  expirationDate: string;
  studiesCount: number;
  studiesLimit: number;
  devicesCount: number;
  devicesLimit: number;
  usedPhantoms: number;
  phantomsLimit: number;
  expiresSoon: boolean;
  groupMembersCount: number;
  groupMembersLimit: number;
  redeemCode: string;
}

export interface UserSettings {
  hasSetupFinished: boolean;
  hasConnectorTooltipDisabled: boolean;
  schedulerTasksCount: number;
}

const useProfile = () => {
  const axios = useAxios();
  const { userToken } = useAppContext();

  return useQuery<ProfileData>(
    queryKeys.PROFILE,
    async () => {
      const { data } = await axios.get(PROFILE_ENDPOINT);
      return data;
    },
    { enabled: !!userToken },
  );
};

export default useProfile;
