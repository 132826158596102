import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_S,
  DEFAULT_MARGIN_XS,
} from "@src/common/styles/const";
import { defaultLetterSpacing } from "@src/common/styles/mixins";

export const UpdateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${DEFAULT_MARGIN_M}px;
  padding: ${DEFAULT_MARGIN_M}px;
  border-radius: 12px;
  background-color: ${colors.background};
`;

export const UpdateHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UpdateTitle = styled.h3`
  margin: 0;
  font-size: 14px;
  ${defaultLetterSpacing};
`;

export const UpdateContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${DEFAULT_MARGIN_S}px;
`;

export const UpdateText = styled.p`
  font-size: 12px;
  color: ${colors.gray3};
  white-space: pre-wrap;
`;

export const UpdateBadge = styled.div<{
  type: "improvements" | "newFeatures";
}>`
  padding: ${DEFAULT_MARGIN_XS}px;
  color: ${colors.white};
  background-color: ${({ type }) =>
    type === "improvements" ? `${colors.darkBlue}CC` : `${colors.green}CC`};
  width: fit-content;
  border-radius: 6px;

  font-size: 10px;
  ${defaultLetterSpacing}
`;
