import { DeviceType } from "@src/modules/shared/types";

export enum NotificationActionType {
  TestPassed,
  TestFailed,
  ConnectorStudyAdded,
  ConnectorFilesError,
  ActionNeeded,
  ConnectorMissingIPAddressError,
  ConnectorNoDeviceByIPError,
  ConnectorSubscriptionExceededError,
  ConnectorNoPhantomForDeviceError,
}

export enum NotificationType {
  Warning,
  Information,
  Attention,
}

export interface INotification {
  id: string;
  actionType: NotificationActionType;
  deviceType: DeviceType;
  openUrl?: string;
  read: boolean;
  type: NotificationType;
  upperInfo: string;
  lowerInfo: string;
  date: string;
}

export type PageRequest = {
  pageNumber: number;
  pageSize?: number;
};
