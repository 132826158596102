import styled from "styled-components";

import colors from "@src/common/styles/colors";
import { DEFAULT_MARGIN_S, DEFAULT_MARGIN_XS } from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import { ConnectorLogData } from "@src/modules/App/Connector/useConnectorLogs";

type Props = Pick<ConnectorLogData, "status">;

export const ConnectorLogWrapper = styled.div<Props>`
  display: grid;
  grid-row-gap: ${DEFAULT_MARGIN_XS}px;
  grid-template-columns: 2fr repeat(3, 1fr);
  grid-template-areas:
    "date locationName ipAddress status"
    "description description description description";
  padding: ${DEFAULT_MARGIN_S}px;
  color: ${colors.almostBlack};
  background-color: ${(p) =>
    p.status === "Error" ? colors.errorBackground : colors.background};
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;

  ${media.down("MOBILE")`
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "date date status"
        "locationName ipAddress ipAddress"
        "description description description";
  `}
`;

export const LogDate = styled.span`
  font-size: 14px;
  font-weight: 600;
  grid-area: date;
`;

export const LocationName = styled.span`
  grid-area: locationName;
`;

export const IpAddress = styled.span`
  grid-area: ipAddress;
`;

export const LogStatus = styled.span`
  grid-area: status;
  justify-self: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${DEFAULT_MARGIN_XS}px;
  padding: ${DEFAULT_MARGIN_XS}px ${DEFAULT_MARGIN_S}px;
  border-radius: 6px;
  background-color: ${colors.background};
`;

export const LogDescription = styled.p`
  grid-area: description;
`;
