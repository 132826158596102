import { FunctionComponent } from "react";
import { useLocation } from "react-router";

import { Wrapper } from "@src/modules/App/AppSkeleton/AppSkeleton.components";
import AuthedLayout from "@src/modules/App/AuthedLayout";
import useAppContext from "@src/modules/App/hooks/useAppContext";
import PublicLayout from "@src/modules/App/PublicLayout";
import routesPaths from "@src/modules/Routing/routesPaths";

export const AppSkeleton: FunctionComponent = ({ children }) => {
  const hasToken = !!useAppContext().userToken;
  const location = useLocation();

  if (location.pathname === routesPaths.STORYBOOK) {
    return <>{children}</>;
  }

  return (
    <Wrapper>
      {hasToken ? (
        <AuthedLayout>{children}</AuthedLayout>
      ) : (
        <PublicLayout>{children}</PublicLayout>
      )}
    </Wrapper>
  );
};

export default AppSkeleton;
