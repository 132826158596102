import styled from "styled-components";

export const LanguageDropdownWrapper = styled.div`
  min-width: 90px;
`;

export const LanguageName = styled.p`
  justify-self: start;
  margin: 0;
  font-size: 12px;
  line-height: 1.4em;
`;

export const OptionContent = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 25px 31px 1fr;
`;
