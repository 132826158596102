import styled from "styled-components";

interface DetailProps {
  margin: number;
}

export const CountryDropdownWrapper = styled.div<DetailProps>`
  margin-left: ${(p) => p.margin}px;
  width: 100%;
`;
