import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_S,
  DEFAULT_MARGIN_XS,
  ELEMENT_BORDER_RADIUS_SIZE,
  ZIndex,
} from "@src/common/styles/const";
import {
  defaultLetterSpacing,
  media,
  wrapLongText,
} from "@src/common/styles/mixins";
import { StyledButton } from "@src/modules/shared/components/Button/Button.components";
import ComponentWrapper from "@src/modules/shared/components/Form/ComponentWrapper";
import { ReactComponent as Chevron } from "@src/modules/shared/icons/chevron-down.svg";

interface DropdownProps {
  $isOpen?: boolean;
  isLoading?: boolean;
  isCurrent?: boolean;
  isError?: boolean;
  disabled?: boolean;
}

interface Style {
  dropdownStyle?: DropdownStyle;
  isLabel?: boolean;
}

export type DropdownStyle = "lightBig" | "light" | "dark" | "bold";

const HEIGHT = 32;
const TRANSITION_TIME = 0.25;

export const DropdownComponentWrapper = styled(ComponentWrapper)`
  width: 100%;
`;

export const DropdownWrapper = styled.div<Style>`
  position: relative;
  height: ${HEIGHT}px;
  width: 100%;

  ${(p) =>
    p.dropdownStyle === "lightBig" &&
    css`
      height: 40px;
    `}
`;

export const DropdownToggle = styled(Chevron)<DropdownProps>`
  transform-origin: center;
  transition: transform ${TRANSITION_TIME}s;
  justify-self: center;

  ${(p) =>
    p.$isOpen &&
    css`
      transform: rotateZ(180deg);
    `}

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.25;
    `}
`;

export const OptionsWrapper = styled.div<DropdownProps & Style>`
  position: relative;
  z-index: ${ZIndex.Dropdown};
  transform-origin: top;
  opacity: 0;
  /* TODO: restore animation */
  display: none;
  transform: scaleY(0.5);
  width: 100%;
  max-height: 200px;
  transition: all ${TRANSITION_TIME}s;
  border-bottom-left-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
  border-bottom-right-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
  overflow: hidden;
  background-color: ${colors.white};

  ${(p) => p.dropdownStyle !== "dark" && `border: 1px solid ${colors.gray1}`};

  ${(p) =>
    p.$isOpen &&
    css`
      display: block;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    `};
`;

export const ScrollWrapper = styled.div`
  max-height: 200px;
  overflow: hidden auto;
`;

export const DropdownOption = styled.div<
  DropdownProps & Style & { isDisabled?: boolean; hasBadge?: boolean }
>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${HEIGHT}px;
  width: 100%;
  padding: ${DEFAULT_MARGIN_S}px;
  user-select: none;
  border: 1px solid transparent;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  transition: all ${TRANSITION_TIME}s;
  letter-spacing: 0;
  ${({ hasBadge }) =>
    hasBadge &&
    css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `}

  ${({ isCurrent, $isOpen, isDisabled, dropdownStyle, isError }) => {
    if (isCurrent && $isOpen) {
      return css`
        color: ${isDisabled ? colors.gray2 : colors.almostBlack};
        border: 1px solid
          ${dropdownStyle !== "dark" ? colors.lightBlue : "transparent"};
        border-top-left-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
        border-top-right-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
      `;
    } else if (isCurrent) {
      return css`
        color: ${colors.almostBlack};
        border: 1px solid
          ${isError
            ? colors.red
            : dropdownStyle !== "dark"
            ? colors.gray1
            : "transparent"};
        border-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
      `;
    }
  }}

  ${({ isLoading }) =>
    isLoading &&
    css`
      color: ${colors.gray1};
      cursor: no-drop;
    `}

  background-color: ${({ dropdownStyle, isCurrent }) =>
    dropdownStyle !== "dark"
      ? colors.white
      : isCurrent
      ? colors.gray1
      : colors.background};

  &:hover:not([disabled]) {
    background-color: ${({ dropdownStyle, isCurrent }) =>
      dropdownStyle === "bold" && isCurrent ? colors.white : colors.gray1};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.background};
      color: ${colors.gray2};
      cursor: not-allowed;
    `}
`;

export const AddOption = styled(DropdownOption)`
  ${StyledButton} {
    justify-content: flex-start;
  }
`;

export const OptionName = styled.span`
  max-width: 80%;
  ${wrapLongText}
  text-align: left;
  flex-shrink: 0;
`;

export const DropdownBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray2};
  color: ${colors.white};
  opacity: 0.7;
  border-radius: 6px;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
`;

export const StyledDropdown = styled.div<DropdownProps & Style>`
  position: absolute;
  width: 100%;
  border-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
  transition: box-shadow ${TRANSITION_TIME}s;

  box-shadow: ${(p) => (p.$isOpen ? "0 4px 20px 0 rgba(0,0,0,0.15) " : "none")};

  ${(p) =>
    !p.isLabel && p.dropdownStyle === "lightBig" && defaultLetterSpacing};

  ${(p) => {
    if (p.dropdownStyle === "lightBig") {
      return css`
        ${DropdownOption} {
          height: 40px;
          padding: 0 ${p.isLabel ? 8 : 16}px;
          font-size: 12px;
        }
      `;
    } else if (p.dropdownStyle === "bold") {
      return css`
        ${DropdownOption} {
          font-size: 18px;
          font-weight: 600;
          border: none;
        }
      `;
    }
  }}
`;

export const DropdownLabel = styled.div`
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  text-align: left;
  ${defaultLetterSpacing};
  margin: ${DEFAULT_MARGIN_XS}px 0;
  color: ${colors.gray3};
`;

export const OptionNameWrapper = styled.div<{
  isBoldStyle?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.isBoldStyle ? "flex-start" : "space-between")};
  width: 100%;

  ${DropdownToggle} {
    margin-left: auto;
  }

  ${(p) =>
    p.isBoldStyle &&
    css`
      ${DropdownToggle} {
        margin-left: 14px;

        ${media.down("TABLET")`
        margin-left: 7px;
      `};
      }
    `}
`;

export const TextWrapper = styled.div<{ isPlaceholder?: boolean }>`
  max-width: calc(100% - 16px); // toggle icon width
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(p) =>
    p.isPlaceholder &&
    css`
      color: ${colors.gray2};
    `}
`;
