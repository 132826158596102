import styled from "styled-components";

import colors from "@src/common/styles/colors";
import { defaultLetterSpacing } from "@src/common/styles/mixins";

export const LABEL_LINE_HEIGHT = 16;

const Label = styled.label`
  ${defaultLetterSpacing};
  font-size: 12px;
  font-weight: 600;
  color: ${colors.gray3};
  line-height: ${LABEL_LINE_HEIGHT}px;
  height: fit-content;
  word-break: normal;
`;

export default Label;
