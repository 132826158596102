import { NestedRouteConfig, RouteConfig } from "@src/modules/Routing/routes";

export const flatNestedRoutes = (
  nestedArray: NestedRouteConfig[],
): RouteConfig[] => {
  const arr = nestedArray.reduce<RouteConfig[]>((prev, it) => {
    const { children, ...routeConfig } = it;
    if (children) {
      prev.push(...flatNestedRoutes(children));
    }
    prev.push(routeConfig as RouteConfig);
    return prev;
  }, []);
  return arr;
};
