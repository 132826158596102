import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import { DEFAULT_MARGIN_S } from "@src/common/styles/const";
import { defaultLetterSpacing } from "@src/common/styles/mixins";
import { ReactComponent as Checkmark } from "@src/modules/shared/components/Form/Checkbox/icons/checkmark.svg";
import ErrorMessage from "@src/modules/shared/components/Form/ErrorMessage";

const CHECKBOX_SIZE = 18;
const CHECKBOX_MARGIN = 3;

interface CheckboxProps {
  isBig: boolean;
}

interface ErrorProps {
  hasError?: boolean;
}

export const CheckboxComponentWrapper = styled.div<{
  stopPropagation: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.stopPropagation ? "pointer-events: none" : "")}
`;

export const StyledInput = styled.input.attrs({
  type: "checkbox",
})`
  position: absolute;
  opacity: 0;
  margin: ${CHECKBOX_MARGIN}px;
  height: ${CHECKBOX_SIZE}px;
  width: ${CHECKBOX_SIZE}px;
`;

export const InputCheckmark = styled(Checkmark)`
  opacity: 0;
`;

export const StyledCheckbox = styled.label<ErrorProps>`
  position: relative;
  display: inline-flex;
  place-items: center;
  background-color: ${colors.white};
  margin: ${CHECKBOX_MARGIN}px;
  width: ${CHECKBOX_SIZE}px;
  height: ${CHECKBOX_SIZE}px;
  z-index: 1;
  border: 1px solid ${(p) => (p.hasError ? colors.red : colors.gray2)};
  border-radius: 1px;

  ${StyledInput}:checked ~ & {
    border-color: ${colors.darkBlue};
  }

  ${StyledInput}:checked ~ & ${InputCheckmark} {
    opacity: 1;
  }

  ${StyledInput}:focus ~ & {
    outline: 3px solid ${colors.darkBlue}4d;
  }

  cursor: pointer;
`;

export const CheckboxTextWrapper = styled.div<{ hasCenteredLabel?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: ${DEFAULT_MARGIN_S}px;
  color: ${colors.gray3};
  ${(p) =>
    p.hasCenteredLabel &&
    css`
      padding-top: 3px;
    `}

  ${ErrorMessage} {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(18px);
  }
`;

export const CheckboxLabel = styled.label<CheckboxProps>`
  font-size: ${(p) => (p.isBig ? "14px" : "12px")};
  line-height: ${(p) => (p.isBig ? "19px" : "17px")};
  font-weight: ${(p) => (p.isBig ? "600" : "400")};
  padding-top: ${(p) => (p.isBig ? "2px" : "0")};
  white-space: normal;

  cursor: pointer;
`;

export const LabelTextWrapper = styled.div`
  overflow-wrap: break-word;
`;

export const CheckboxWrapper = styled.div<CheckboxProps>`
  position: relative;
  display: flex;

  ${CheckboxTextWrapper} {
    color: ${(p) => (p.isBig ? colors.gray2 : colors.gray3)};
  }

  ${(p) =>
    p.isBig &&
    css`
      ${defaultLetterSpacing}
      white-space: nowrap;
      flex-shrink: 0;

      ${StyledInput}:checked ~ ${CheckboxTextWrapper} {
        color: ${colors.darkBlue};
      }
    `}
`;
