import { useTranslation } from "react-i18next";

import { PricingPlanEnum } from "@src/common/helpers/userToken";
import useProfile, { AccountType } from "@src/modules/App/hooks/useProfile";

const useSubscriptionLimits = () => {
  const { t } = useTranslation();
  const { data: profileData } = useProfile();

  const activationDate = profileData?.subscription.firstActivation
    ? new Date(profileData?.subscription.firstActivation)
    : new Date();
  const unlimitedPremiumProDate = new Date(Date.UTC(2022, 3, 21));

  const wasPremium =
    (profileData?.subscription.pricingPlanId === PricingPlanEnum.PRO ||
      profileData?.accountType === AccountType.GroupAdmin ||
      profileData?.accountType === AccountType.GroupMember) &&
    activationDate < unlimitedPremiumProDate;

  const isPremium =
    profileData?.subscription.pricingPlanId === PricingPlanEnum.ENTERPRISE ||
    profileData?.subscription.pricingPlanId === PricingPlanEnum.SIEMENS ||
    wasPremium;

  const hasUnlimitedStudies =
    isPremium ||
    profileData?.subscription.pricingPlanId === PricingPlanEnum.PRO ||
    profileData?.accountType === AccountType.GroupAdmin ||
    profileData?.accountType === AccountType.GroupMember;

  return {
    studiesLimit: hasUnlimitedStudies
      ? t("unlimited")
      : profileData?.subscription.studiesLimit ?? "-",
    devicesLimit: isPremium
      ? t("unlimited")
      : profileData?.subscription.devicesLimit ?? "-",
    phantomsLimit: isPremium
      ? t("unlimited")
      : profileData?.subscription.phantomsLimit ?? "-",
  };
};

export default useSubscriptionLimits;
