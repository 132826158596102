export enum NotificationEvents {
  AddNotification = "AddNotification",
  ReceiveNotifications = "ReceiveNotifications",
  MarkedAsRead = "MarkedAsRead",
  SetConnectorStatus = "SetConnectorStatus",
  UpdateConnectorStatus = "UpdateConnectorStatus",
  ShowConnectorTooltip = "ShowConnectorTooltip",
}

export enum NotificationEndpoints {
  MarkAsRead = "MarkAsRead",
  MarkAllAsRead = "MarkAllAsRead",
  GetNotifications = "GetNotifications",
}
