import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_S,
  DEFAULT_MARGIN_XL,
  DEFAULT_MARGIN_XS,
} from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import { ReactComponent as Attention } from "@src/modules/App/Notifications/icons/attention.svg";
import { ReactComponent as Ok } from "@src/modules/App/Notifications/icons/ok.svg";
import { ReactComponent as Warning } from "@src/modules/App/Notifications/icons/warning.svg";
import { NotificationType } from "@src/modules/App/Notifications/shared/types";

export const NotificationWrapper = styled.div<{ isRead: boolean }>`
  position: relative;
  display: flex;
  width: 389px;
  min-height: 78px;

  background-color: ${colors.background};
  color: ${({ isRead: read }) => (read ? colors.gray2 : colors.almostBlack)};

  border-radius: 12px;
  padding: ${DEFAULT_MARGIN_S}px;
  margin-top: ${DEFAULT_MARGIN_S}px;
  ${({ isRead: read }) => !read && "cursor: pointer;"}

  :last-child {
    margin-bottom: ${DEFAULT_MARGIN_S}px;
  }

  ${media.down("MOBILE")`
    width: 100%;
  `}
  cursor: pointer;
`;

export const NotificationDate = styled.h5<{ isRead: boolean }>`
  position: absolute;
  bottom: ${DEFAULT_MARGIN_S}px;
  right: ${DEFAULT_MARGIN_S}px;
  margin: 0;

  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  color: ${(p) => (p.isRead ? colors.gray2 : colors.almostBlack)};
`;

export const NotificationImage = styled.img`
  height: 62px;
  width: 62px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 12px;
  align-self: center;
`;

export const NotificationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NotificationHeaderWrapper = styled.div<{
  type: NotificationType;
  isRead: boolean;
}>`
  display: flex;
  flex-direction: row;
  color: ${({ type }) =>
    type === NotificationType.Attention
      ? colors.almostBlack
      : type === NotificationType.Warning
      ? colors.red
      : colors.lightBlue};
  align-items: center;

  ${({ isRead: read }) =>
    read
      ? css`
          color: ${colors.gray2} !important;
        `
      : null}
`;

export const NotificationTitle = styled.h4`
  margin: 0;
  margin-left: ${DEFAULT_MARGIN_XS}px;
  margin-right: ${DEFAULT_MARGIN_XL};
`;

export const NotificationFooterUpper = styled.h5`
  margin: 0;
  max-width: 235px;
  font-size: 12px;
  font-weight: 600;
`;

export const NotificationFooterLower = styled.h5`
  margin: 0;
  max-width: 235px;
  font-size: 12px;
  font-weight: 300;
`;

export const OkIcon = styled(Ok)`
  margin-top: 2px;
  min-height: 16px;
  min-width: 16px;
  align-self: start;
`;

export const WarningIcon = styled(Warning)`
  margin-top: 2px;
  min-height: 16px;
  min-width: 16px;
  align-self: start;
`;

export const AttentionIcon = styled(Attention)`
  margin-top: 2px;
  min-height: 16px;
  min-width: 16px;
  align-self: start;
`;
