import { AxiosResponse, AxiosError } from "axios";
import { useMutation } from "react-query";

import useAxios from "@src/config/axios/useAxios";
import { SALES_CONTACT_ENDPOINT } from "@src/config/baseUrls";

export interface UpdateCountryModel {
  country: string;
}

const useUpdateCountry = () => {
  const axios = useAxios();

  return useMutation<AxiosResponse<string>, AxiosError, UpdateCountryModel>(
    async (value: UpdateCountryModel) =>
      await axios.post(SALES_CONTACT_ENDPOINT, {
        country: value.country,
      }),
  );
};

export default useUpdateCountry;
