import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_L,
  DEFAULT_MARGIN_M,
  ZIndex,
} from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";

export const HEADER_HEIGHT = 54;
export const HEADER_TABLET_PADDING = 16;

export const TopbarWrapper = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${DEFAULT_MARGIN_L}px;
  border-bottom: 1px solid ${colors.gray2_5};
  background-color: ${colors.white};
  z-index: ${ZIndex.Header};

  ${media.down("TABLET")`
    padding: 0 ${HEADER_TABLET_PADDING}px;
  `}
`;

export const TopbarContent = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${DEFAULT_MARGIN_M}px;
  }
`;
