import { FunctionComponent, Suspense } from "react";
import { Redirect, Route, RouteProps } from "react-router";

import useAppContext from "@src/modules/App/hooks/useAppContext";
import routesPaths from "@src/modules/Routing/routesPaths";
import ScreenLoading from "@src/modules/shared/components/ScreenLoading";

type Props = RouteProps & Required<Pick<RouteProps, "component">>;

export const PublicRoute: FunctionComponent<Props> = ({
  component: Component,
  ...routeProps
}) => {
  // token means that user is logged in
  const hasToken = !!useAppContext().userToken;

  if (hasToken) {
    return <Redirect to={routesPaths.HOME} />;
  }

  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) => (
        <Suspense fallback={<ScreenLoading />}>
          <Component {...routeComponentProps} />
        </Suspense>
      )}
    />
  );
};

export default PublicRoute;
