import CT from "@src/modules/shared/icons/devices/CT.png";
import DentalCBCT from "@src/modules/shared/icons/devices/Dental-CBCT.png";
import Dentalintraoral from "@src/modules/shared/icons/devices/Dental-intraoral.png";
import Dentalpanorama from "@src/modules/shared/icons/devices/Dental-panorama.png";
import Fluoroscopy from "@src/modules/shared/icons/devices/Fluoroscopy.png";
import Mammography from "@src/modules/shared/icons/devices/Mammography.png";
import MRI from "@src/modules/shared/icons/devices/MRI.png";
import NuclearMedicine from "@src/modules/shared/icons/devices/Nuclear-Medicine.png";
import Radiography from "@src/modules/shared/icons/devices/Radiography.png";
import Ultrasound from "@src/modules/shared/icons/devices/Ultrasound.png";
import Default from "@src/modules/shared/icons/devices/defaultDevice.png";
import { DeviceType } from "@src/modules/shared/types";

interface IconConfig {
  value: DeviceType;
  url: string;
}

export const iconsConfig: IconConfig[] = [
  { value: DeviceType.Tomography, url: CT },
  { value: DeviceType.DentalCBCT, url: DentalCBCT },
  { value: DeviceType.IntraOral, url: Dentalintraoral },
  { value: DeviceType.OPG, url: Dentalpanorama },
  { value: DeviceType.Fluoroscopy, url: Fluoroscopy },
  { value: DeviceType.Mammography, url: Mammography },
  { value: DeviceType.Resonance, url: MRI },
  { value: DeviceType.Nuclear, url: NuclearMedicine },
  { value: DeviceType.Radiography, url: Radiography },
  { value: DeviceType.Ultrasound, url: Ultrasound },
];

export const getDeviceIconUrl = (value: DeviceType) =>
  iconsConfig.find((icon) => icon.value === value)?.url ?? Default;
