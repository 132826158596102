import styled from "styled-components";
import { DEFAULT_MARGIN_XS } from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import { InputComponentWrapper } from "@src/modules/shared/components/Form/Input/Input.components";
import Label from "@src/modules/shared/components/Form/Label";

const FormGroup = styled.div<{ isButton?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ isButton }) =>
    isButton ? "justify-content: center; align-items: center;" : null}

  ${Label} {
    margin-bottom: ${DEFAULT_MARGIN_XS}px;
  }

  ${media.down("TABLET")`
    ${InputComponentWrapper}{
      min-width: 200px;
      width: 100%;
    }
  `}
`;

export default FormGroup;
