import { createContext } from "react";

import { ModalContentType } from "@src/modules/shared/components/Modal/ModalProvider";

export interface ModalContextType {
  openModal(
    node: ModalContentType,
    showHeader?: boolean,
    onBeforeClose?: () => void,
  ): void;
  openFixedModal(
    node: ModalContentType,
    showHeader?: boolean,
    onBeforeClose?: () => void,
  ): void;
  openSideModal(
    node: ModalContentType,
    showHeader?: boolean,
    onBeforeClose?: () => void,
  ): void;
  updateModal(node: ModalContentType): void;
  closeModal(): void;
}

export const ModalContext = createContext<ModalContextType>({
  openModal: () => {
    throw new Error("You probably forgot to put <ModalContext.Provider>!");
  },
  openFixedModal: () => {
    throw new Error("You probably forgot to put <ModalContext.Provider>!");
  },
  openSideModal: () => {
    throw new Error("You probably forgot to put <ModalContext.Provider>!");
  },
  updateModal: () => {
    throw new Error("You probably forgot to put <ModalContext.Provider>!");
  },
  closeModal: () => {
    throw new Error("You probably forgot to put <ModalContext.Provider>!");
  },
});

export default ModalContext;
