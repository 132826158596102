import { FunctionComponent, useRef, useState } from "react";

import useDelayUnmount from "@src/common/hooks/useDelayUnmount";
import {
  TooltipPosition,
  TooltipWrapper,
} from "@src/modules/shared/components/Tooltip/Tooltip.components";
import TooltipPortal from "@src/modules/shared/components/Tooltip/TooltipPortal";

interface Props {
  description: string | string[];
  isActive?: boolean;
  position?: TooltipPosition;
  fullWidth?: boolean;
}

const Tooltip: FunctionComponent<Props> = ({
  description,
  isActive = true,
  position = "top",
  fullWidth = false,
  children,
}) => {
  const [active, setActive] = useState(false);
  const shouldRenderTooltip = useDelayUnmount(active, 500);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const show = () => setActive(true);
  const hide = () => setActive(false);
  const descriptions = !Array.isArray(description)
    ? [description]
    : description;

  return (
    <TooltipWrapper
      onMouseEnter={show}
      onMouseLeave={hide}
      fullWidth={fullWidth}
      ref={tooltipRef}
    >
      {children}
      {isActive && (active || shouldRenderTooltip) && (
        <TooltipPortal
          descriptions={descriptions}
          position={position}
          isVisible={active && shouldRenderTooltip}
          rect={tooltipRef.current?.getBoundingClientRect()}
        />
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;
