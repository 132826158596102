import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  Bounce,
  ToastContainer as BaseToastContainer,
  ToastContainerProps,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_XS,
  DEFAULT_MARGIN_XXL,
} from "@src/common/styles/const";
import { ReactComponent as Close } from "@src/modules/shared/components/ToastContainer/close.svg";

const TOASTIFY_PREFIX = "Toastify__toast";
const TOAST_SUCCESS = `${TOASTIFY_PREFIX}--success`;
const TOAST_ERROR = `${TOASTIFY_PREFIX}--error`;
const CLOSE_BUTTON = `${TOASTIFY_PREFIX}__close-button`;

const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${DEFAULT_MARGIN_XS}px;
`;

const CloseText = styled.span`
  white-space: nowrap;
  color: ${colors.gray2_5};
`;

interface CloseButtonProps {
  showText?: boolean;
}

const ToastContainer: FunctionComponent<ToastContainerProps> = ({
  ...props
}) => {
  return <BaseToastContainer {...props} />;
};

export const CloseButton: FunctionComponent<CloseButtonProps> = ({
  showText = false,
}) => {
  const { t } = useTranslation();

  return (
    <CloseButtonWrapper>
      {showText && <CloseText>{t("Close")}</CloseText>}
      <Close />
    </CloseButtonWrapper>
  );
};

export default styled(ToastContainer).attrs({
  position: "bottom-center",
  hideProgressBar: true,
  autoClose: 2000,
  closeButton: <CloseButton />,
  transition: Bounce,
  closeOnClick: true,
  bodyClassName: "body",
  icon: false,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0;
  bottom: ${DEFAULT_MARGIN_XXL}px;

  .body {
    margin: 0;
    padding: 0;
  }

  .${TOASTIFY_PREFIX} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: min(500px, 90vw);
    min-height: 51px;
    margin: 0;
    padding: ${DEFAULT_MARGIN_M}px;
    font-family: "Open Sans";
    font-weight: 600;
    line-height: 19px;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(3, 74, 100, 0.25);
  }

  .${TOAST_SUCCESS} {
    color: ${colors.darkBlue2};
    background-color: ${colors.toastSuccessBackground};
  }

  .${TOAST_ERROR} {
    color: ${colors.red};
    background-color: ${colors.errorBackground};
  }

  .${CLOSE_BUTTON} {
    align-self: center;
    margin: 1.5px;
  }
`;
