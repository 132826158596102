import { useTranslation } from "react-i18next";

interface ErrorMessage {
  message?: string;
}

interface ResponseError {
  response?: ErrorMessage;
}

interface ResponseWithDataError {
  response?: {
    data?: ErrorMessage;
  };
}

export const useErrorMessage = () => {
  const { t } = useTranslation();

  return (err: unknown) => {
    const errorMsg =
      (err as ResponseError).response?.message ??
      (err as ResponseWithDataError).response?.data?.message ??
      t("Something went wrong, please try again");

    return errorMsg;
  };
};
