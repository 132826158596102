export const queryKeys = {
  DEVICE_INFO: "deviceInfo",
  CALENDAR_STUDIES: "calendarStudies",
  CHART_RESULTS: "chartResults",
  DAY_TEST_RESULTS: "dayTestResults",
  HAS_REPORT: "hasReport",
  SINGLE_TEST_RESULTS: "singleTestResults",
  PROFILE: "profile",
  SALES_CONTACT: "salesContact",
  MODALITIES: "modalities",
  STUDY_IMAGES: "studyImages",
  DEVICE_DEFAULT_TESTS: "deviceDefaultTests",
  TOLERANCES: "tolerances",
  DEVICE_GENERAL_INFO: "deviceGeneralInfo",
  DEVICES: "devices",
  DEVICES_TOOLBAR: "devicesToolbar",
  DEVICE_PHANTOMS: "devicePhantoms",
  LOCATIONS: "locations",
  PHANTOMS: "phantoms",
  TEST_EQUIPMENT: "testEquipment",
  EQUIPMENT_DETAILS: "equipmentDetails",
  EQUIPMENT_DEFAULT_TESTS: "equipmentDefaultTests",
  GROUP_DETAILS: "groupDetails",
  GROUP_SUMMARY: "groupSummary",
  DEVICE_STATUS: " deviceStatus",
  SCHEDULER_TASKS: "schedulerTasks",
  FMM: "FMM",
  SINGLE_FMM_ISSUE: "singleFmmIssue",
  KNOWLEDGE_BASE_TAGS: "knowledgeBaseTags",
  KNOWLEDGE_BASE_VIDEOS: "knowledgeBaseVideos",
  KNOWLEDGE_BASE_TUTORIALS: "knowledgeBaseTutorials",
  LOCATION_DETAILS: "locationDetails",
  GROUPS: "groups",
  CONNECTOR_LOGS: "connectorLogs",
  USERS: "users",
  LICENSE_CODES: "licenseCodes",
  RESEND_ACTIVATION_LINK: "resendActivationLink",
  PRICING_PLANS: "pricingPlans",
  ADD_LICENSE_CODES: "addLicenseCodes",
  DELETE_CODE: "deleteCode",
  EDIT_CODE_LIMITS: "editCodeLimits",
  LOGOUT: "logout",
  IMAGES_RANGE: "imagesRange",
  REANALYZE_STUDY: "reanalyzeStudy",
  REANALYZE_Test: "reanalyzeSingleTest",
  DELETE_STUDY: "deleteStudy",
  DELETE_STUDIES: "deleteStudies",
  UPDATES: "updates",
  UPDATES_COUNT: "updatesCount",
  SELECT_PHANTOM: "selectPhantom",
  DICOM_HEADERS: "dicomHeaders",
  ADD_STUDY_GROUP: "addStudyGroup",
  STUDY_GROUPS: "studyGroups",
  ARCHIVE_STUDY_GROUP: "archiveStudyGroup",
  GROUPING_CONDITIONS: "groupingConditions",
  ASSIGN_TO_STUDY_GROUP: "assignToStudyGroup",
  SINGLE_STUDY_DETAIL: "singleStudyDetail",
  MINED_STUDIES: "minedStudies",
} as const;
