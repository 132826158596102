import { Form } from "formik";
import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_S,
  DEFAULT_MARGIN_XS,
  ELEMENT_BORDER_RADIUS_SIZE,
} from "@src/common/styles/const";

export const FormWrapper = styled(Form)`
  display: flex;
  height: 38px;
  width: 100%;
  padding: ${DEFAULT_MARGIN_XS}px ${DEFAULT_MARGIN_M}px;
`;

export const CodeInput = styled.input.attrs({ type: "text" })<{
  error: boolean;
}>`
  flex: 1;
  padding: ${DEFAULT_MARGIN_XS}px ${DEFAULT_MARGIN_S}px;
  font-size: 12px;
  border-top-left-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
  border-bottom-left-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
  border: 1px solid ${(p) => (p.error ? colors.red : colors.gray1)};
`;

export const SubmitButton = styled.button.attrs({ type: "submit" })`
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  padding: ${DEFAULT_MARGIN_XS}px ${DEFAULT_MARGIN_S}px;
  font-weight: 600;
  font-size: 14px;
  border-top-right-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
  border-bottom-right-radius: ${ELEMENT_BORDER_RADIUS_SIZE}px;
`;
