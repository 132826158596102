import { FunctionComponent, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { DEFAULT_MARGIN_M } from "@src/common/styles/const";
import useProfile from "@src/modules/App/hooks/useProfile";
import {
  TooltipButton,
  TooltipDescription,
  TooltipFormWrapper,
  TooltipHeader,
  TooltipWrapper,
} from "@src/modules/App/Topbar/ConnectorButton/ConnectorTooltip/ConnectorTooltip.components";
import useDisableConnectorTooltip from "@src/modules/App/Topbar/ConnectorButton/hooks/useDisableConnectorTooltip";
import routesPaths from "@src/modules/Routing/routesPaths";
import { DescriptionLink } from "@src/modules/shared/components/components";
import Checkbox from "@src/modules/shared/components/Form/Checkbox";

interface Props {
  closeTooltip: () => void;
  isVisible: boolean;
  container: HTMLDivElement;
}

const ConnectorTooltip: FunctionComponent<Props> = ({
  closeTooltip,
  isVisible,
  container,
}) => {
  const { t } = useTranslation();
  const { data: profileData } = useProfile();
  const [isChecked, setIsChecked] = useState(false);
  const toggle = () => setIsChecked((prev) => !prev);
  const mutation = useDisableConnectorTooltip();

  const containerRect = container.getBoundingClientRect();
  const positionX = containerRect.x + containerRect.width / 2;
  const offsetRight = window.innerWidth - positionX - DEFAULT_MARGIN_M;

  const handleClose = () => {
    if (isChecked && !profileData?.settings?.hasConnectorTooltipDisabled) {
      mutation.mutate();
    }
    closeTooltip();
  };

  const stopPropagation = (e: MouseEvent<HTMLDivElement>) =>
    e.stopPropagation();

  return (
    <TooltipWrapper
      onClick={stopPropagation}
      isVisible={isVisible}
      offsetRight={offsetRight}
    >
      <TooltipHeader>{t("Next time try Diagnomatic Connector!")}</TooltipHeader>
      <TooltipDescription>
        {t(
          "Connector sends DICOMs directly from the device to Diagnomatic app",
        )}
        &nbsp;
        <DescriptionLink to={routesPaths.KNOWLEDGE_BASE.BASE}>
          {t("Knowledge Base link")}
        </DescriptionLink>
      </TooltipDescription>
      <TooltipFormWrapper>
        <Checkbox
          name="don't show again"
          hasBigLabel
          checked={isChecked}
          onChange={toggle}
        >
          {t("Don't show this again")}
        </Checkbox>
        <TooltipButton onClick={handleClose}>OK</TooltipButton>
      </TooltipFormWrapper>
    </TooltipWrapper>
  );
};

export default ConnectorTooltip;
