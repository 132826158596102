import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_S,
  DEFAULT_MARGIN_XS,
} from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import { ReactComponent as Info } from "@src/modules/App/Profile/ViewProfile/icons/info.svg";

export const SubscriptionWrapper = styled.div`
  background-color: ${colors.white};
  padding-bottom: ${DEFAULT_MARGIN_XS}px;
  border-radius: 12px;
  overflow: hidden;
`;

export const ContinueSubscriptionInfo = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 17px;
  padding: ${DEFAULT_MARGIN_S}px ${DEFAULT_MARGIN_M}px;

  ${media.down("MOBILE")`
    font-size: 10px;
  `}
`;

export const InfoLogo = styled(Info)`
  flex-shrink: 0;
  margin-right: 10px;
`;
