import React, { FunctionComponent } from "react";
import { Transition } from "react-transition-group";

import {
  Background,
  Container,
  MODAL_TRANSITION_TIME_MS,
} from "@src/modules/shared/components/Modal/ModalContainer.components";
import {
  ModalContentType,
  ModalState,
} from "@src/modules/shared/components/Modal/ModalProvider";

interface ModalContainerProps {
  isOpen: boolean;
  onBackgroundClick: (event: React.MouseEvent) => void;
  children?: ModalContentType;
  position: ModalState["position"];
  mode: ModalState["mode"];
  showHeader?: ModalState["showHeader"];
}

const forceReflow = (node: HTMLElement) => node.offsetHeight;

const ModalContainer: FunctionComponent<ModalContainerProps> = ({
  children,
  isOpen,
  position,
  mode,
  showHeader,
  onBackgroundClick,
}) => (
  <Transition
    timeout={MODAL_TRANSITION_TIME_MS}
    mountOnEnter={true}
    unmountOnExit={true}
    appear={true}
    in={isOpen}
    onEnter={forceReflow}
  >
    {(status) => (
      <>
        <Background
          status={status}
          showHeader={showHeader}
          onMouseDown={onBackgroundClick}
        />
        <Container
          status={status}
          onMouseDown={onBackgroundClick}
          position={position}
          showHeader={showHeader}
          mode={mode}
        >
          {/* Pass extra status prop to children element */}
          {children && <children.type {...children.props} status={status} />}
        </Container>
      </>
    )}
  </Transition>
);

export default ModalContainer;
