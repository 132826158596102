import { Formik, FormikHelpers } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as Yup from "yup";

import useQueryParams from "@src/common/helpers/useQueryParams";
import {
  setUserToken,
  userTokenFromResponse,
} from "@src/common/helpers/userToken";
import LoginFormContent from "@src/modules/Public/shared/LoginForm/LoginFormContent";
import useLogin, {
  LoginModel,
} from "@src/modules/Public/shared/LoginForm/useLogin";
import routesPaths from "@src/modules/Routing/routesPaths";

export type LoginMode = "regular" | "activated" | "passwordSet";
export interface LoginState {
  mode?: LoginMode;
  showToast?: boolean;
}

const initialValues: LoginModel = {
  email: "",
  password: "",
};

export const redirectToQueryParam = "r";

type Props = LoginState;

const LoginForm: FunctionComponent<Props> = ({ mode }) => {
  const mutation = useLogin();
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQueryParams();
  const redirectTo = query.get(redirectToQueryParam) ?? routesPaths.HOME;

  const validationSchema: Yup.SchemaOf<LoginModel> = Yup.object().shape({
    email: Yup.string()
      .email(t("Invalid e-mail"))
      .required(t("E-mail required")),
    password: Yup.string()
      .min(6, t("Too short!"))
      .max(100, t("Too long!"))
      .required(t("Password required")),
  });

  const handleSubmit = async (
    values: LoginModel,
    { setSubmitting }: FormikHelpers<LoginModel>,
  ) => {
    mutation.mutate(values, {
      onSuccess: (response) => {
        setUserToken(userTokenFromResponse(response.data));
        history.push(redirectTo);
      },
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <LoginFormContent
          {...formProps}
          isLoading={mutation.isLoading}
          mode={mode}
        />
      )}
    </Formik>
  );
};

export default LoginForm;
