import { LottieOptions, useLottie } from "lottie-react";
import { ComponentPropsWithoutRef, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import useConnectorTooltipHandler from "@src/common/eventHandlers/useConnectorTooltipHandler";
import useUpdateConnectorStatus from "@src/common/eventHandlers/useUpdateConnectorStatus";
import { ConnectorActionStatus } from "@src/common/events/UpdateConnectorStatusEvent";
import useDelayUnmount from "@src/common/hooks/useDelayUnmount";
import { useIsMobile } from "@src/common/styles/media";
import { BOUNCE_TRANSITION_TIME } from "@src/common/styles/mixins";
import useProfile from "@src/modules/App/hooks/useProfile";
import downloadAnimation from "@src/modules/App/Topbar/ConnectorButton/animations/download.json";
import uploadAnimation from "@src/modules/App/Topbar/ConnectorButton/animations/upload.json";
import { ButtonLogo } from "@src/modules/App/Topbar/ConnectorButton/ConnectorButton.components";
import TooltipIndicator from "@src/modules/App/Topbar/ConnectorButton/TooltipIndicator";
import { NavButtonWrapper } from "@src/modules/App/Topbar/shared/components";
import { ReactComponent as Logo } from "@src/modules/shared/icons/green_logo.svg";
import { ReactComponent as InactiveLogo } from "@src/modules/shared/icons/grey_logo.svg";

const options: Omit<LottieOptions, "animationData"> = {
  loop: true,
  autoplay: true,
  style: { height: "17px", width: "16px" },
};

//NOTE: Animations have to be declared as separate components, useLottie hook doesn't update on state change
const UploadAnimation: FunctionComponent = () =>
  useLottie({ ...options, animationData: uploadAnimation }).View;

const DownloadAnimation: FunctionComponent = () =>
  useLottie({ ...options, animationData: downloadAnimation }).View;

const ConnectorButton: FunctionComponent<
  ComponentPropsWithoutRef<"button">
> = ({ onClick }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { data: profileData } = useProfile();
  const [status] = useUpdateConnectorStatus();
  const [showIndicator, setShowIndicator] = useState(false);

  useConnectorTooltipHandler(() => {
    setTimeout(() => setShowIndicator(true), 4000);
  });

  const isIndicatorMounted = useDelayUnmount(
    showIndicator,
    BOUNCE_TRANSITION_TIME,
  );

  const buttonLogo =
    status === ConnectorActionStatus.Sending ? (
      <UploadAnimation />
    ) : status === ConnectorActionStatus.Receiving ? (
      <DownloadAnimation />
    ) : profileData?.hasActiveConnector ? (
      <Logo />
    ) : (
      <InactiveLogo />
    );

  return (
    <NavButtonWrapper onClick={onClick}>
      <ButtonLogo>
        {buttonLogo}
        {(showIndicator || isIndicatorMounted) && (
          <TooltipIndicator
            shouldDisplayTooltip={
              !profileData?.settings?.hasConnectorTooltipDisabled
            }
            hideIndicator={() => setShowIndicator(false)}
          />
        )}
      </ButtonLogo>
      {!isMobile && t("Connector")}
    </NavButtonWrapper>
  );
};

export default ConnectorButton;
