import { Form } from "formik";
import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_L,
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_S,
} from "@src/common/styles/const";
import { defaultLetterSpacing, media } from "@src/common/styles/mixins";
import { ProfileButtonWrapper } from "@src/modules/App/Topbar/ProfileButton/ProfileButton.components";
import { InputComponentWrapper } from "@src/modules/shared/components/Form/Input/Input.components";

export const EditFormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  & ${ProfileButtonWrapper} {
    margin-left: 9.5px;

    ${media.down("MOBILE")`
      margin-left: 15px;
    `}
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  margin-top: 40px;

  & ${InputComponentWrapper} {
    width: 100%;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export const FormLabel = styled.p`
  ${defaultLetterSpacing};
  color: ${colors.gray2};
  margin-bottom: ${DEFAULT_MARGIN_L}px;
  font-size: 12px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  gap: ${DEFAULT_MARGIN_S}px;
`;

export const LinksWrapper = styled.div`
  display: flex;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${DEFAULT_MARGIN_M}px;
`;
