import { Formik, FormikHelpers } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ActivationModel } from "@src/modules/App/Profile/ViewProfile/hooks/useActivation";
import ActivationForm from "@src/modules/App/Profile/ViewProfile/Subscription/Activation/ActivationForm";

const initialValues: ActivationModel = { subscriptionCode: "" };

interface Props {
  handleSubmit: (
    values: ActivationModel,
    helpers: FormikHelpers<ActivationModel>,
  ) => void;
}

const Activation: FunctionComponent<Props> = ({ handleSubmit }) => {
  const { t } = useTranslation();

  const validationSchema: Yup.SchemaOf<ActivationModel> = Yup.object().shape({
    subscriptionCode: Yup.string().required(t("Code required")),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => <ActivationForm {...formProps} />}
    </Formik>
  );
};

export default Activation;
