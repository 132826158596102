import { ComponentPropsWithoutRef, FunctionComponent } from "react";

import { profileButtonId } from "@src/config/consts";
import useProfile from "@src/modules/App/hooks/useProfile";
import {
  ButtonProps,
  ProfileButtonWrapper,
} from "@src/modules/App/Topbar/ProfileButton/ProfileButton.components";

const ProfileButton: FunctionComponent<
  ComponentPropsWithoutRef<"button"> & ButtonProps
> = ({ size, onClick }) => {
  const { data: profileData } = useProfile();

  return (
    <ProfileButtonWrapper
      id={profileButtonId}
      data-cy={profileButtonId}
      size={size}
      onClick={onClick}
    >
      {profileData?.name.slice(0, 1) ?? "P"}
    </ProfileButtonWrapper>
  );
};
export default ProfileButton;
