import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useLocation, useRouteMatch } from "react-router";

import { DICOM_VIEWER } from "@src/common/styles/const";
import useProfile, { AccountType } from "@src/modules/App/hooks/useProfile";
import { ReactComponent as Admin } from "@src/modules/App/Sidebar/icons/admin.svg";
import { ReactComponent as DevStatus } from "@src/modules/App/Sidebar/icons/dev_status.svg";
import { ReactComponent as Dicom } from "@src/modules/App/Sidebar/icons/dicom.svg";
import { ReactComponent as Group } from "@src/modules/App/Sidebar/icons/group.svg";
import { ReactComponent as Home } from "@src/modules/App/Sidebar/icons/home.svg";
import { ReactComponent as KnowledgeBase } from "@src/modules/App/Sidebar/icons/knowledge_base.svg";
import {
  SidebarWrapper,
  StyledLink,
} from "@src/modules/App/Sidebar/Sidebar.components";
import routesPaths from "@src/modules/Routing/routesPaths";

const Sidebar: FunctionComponent = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { data: profileData } = useProfile();

  const groupsRouteMatch = useRouteMatch(routesPaths.GROUPS);
  const adminRouteMatch = useRouteMatch(routesPaths.ADMIN.MAIN);

  const hasAccessToGroups =
    (profileData?.accountType === AccountType.GroupAdmin &&
      profileData?.groupIds &&
      profileData?.groupIds?.length > 0) ||
    profileData?.accountType === AccountType.SuperAdmin ||
    profileData?.accountType === AccountType.ProProjectAdmin;

  const hasAccessToAdminPanel =
    profileData?.accountType === AccountType.ProProjectAdmin;

  const groupsPath = generatePath(routesPaths.GROUPS, {
    groupId: undefined,
  });

  return (
    <SidebarWrapper>
      <StyledLink
        to={routesPaths.HOME}
        $isCurrent={location.pathname === routesPaths.HOME}
      >
        <Home />
        {t("Home")}
      </StyledLink>
      <StyledLink
        to={routesPaths.DEVICE_STATUS.LOCATIONS}
        $isCurrent={
          location.pathname.includes(routesPaths.DEVICE_STATUS.LOCATIONS) ||
          location.pathname.includes(routesPaths.DEVICE_STATUS.DEVICE)
        }
      >
        <DevStatus /> {t("Devices Status")}
      </StyledLink>
      <StyledLink
        to={routesPaths.KNOWLEDGE_BASE.BASE}
        $isCurrent={location.pathname.includes(routesPaths.KNOWLEDGE_BASE.BASE)}
      >
        <KnowledgeBase /> {t("Knowledge Base")}
      </StyledLink>
      <StyledLink
        to={generatePath(routesPaths.DICOM_VIEWER)}
        $isCurrent={location.pathname.startsWith(DICOM_VIEWER)}
      >
        <Dicom /> {t("DICOM Viewer")}
      </StyledLink>
      {hasAccessToGroups && (
        <StyledLink to={groupsPath} $isCurrent={!!groupsRouteMatch?.isExact}>
          <Group />
          {t("My Groups")}
        </StyledLink>
      )}
      {hasAccessToAdminPanel && (
        <StyledLink
          to={generatePath(routesPaths.ADMIN.MAIN)}
          $isCurrent={!!adminRouteMatch}
        >
          <Admin /> {t("Admin Panel")}
        </StyledLink>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
