export default {
  white: "#fff",
  almostBlack: "#14121F",
  background: "#F2F7FA",
  gray1: "#E7EEF3",
  gray2: "#889CB0",
  gray2_5: "#D5E2EA",
  gray3: "#4B6878",
  lightBlue: "#1AABDA",
  darkBlue: "#2084A8",
  darkBlue2: "#0F6382",
  red: "#FB461E",
  lightGreen: "#D4F3DB",
  yellow: "#FFBD14",
  lightYellow: "#FFE39C",
  green: "#45D245",
  errorBackground: "#F9DCDC",
  toastSuccessBackground: "#BBE4F2",
  inProgressBlue: "#BDE0ED",
  testPassedDefault: "#D4F3DB",
  testPassedActive: "#BBEEB2",
  testInProgressDefault: "#CBEAF6",
  testInProgressActive: "#BDE0ED",
  testActionNeededDefault: "#FFE8AD",
  testActionNeededActive: "#FFE39C",
  testFailedDefault: "#F9DCDC",
  testFailedActive: "#FFD0D0",
  schedulerWarning: "#EF7911",
} as const;
