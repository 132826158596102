import styled from "styled-components";

import { defaultLetterSpacing } from "@src/common/styles/mixins";

export const ConnectorLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SmallLabel = styled.p`
  font-size: 10px;
  font-weight: 400;
  ${defaultLetterSpacing}
`;
