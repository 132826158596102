import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";

import useAxios from "@src/config/axios/useAxios";
import { EDIT_PROFILE_ENDPOINT } from "@src/config/baseUrls";

export interface EditProfileModel {
  userName: string | null;
  password: string | null;
  oldPassword: string | null;
}

const useEditProfile = () => {
  const axios = useAxios();

  return useMutation<AxiosResponse<string>, AxiosError, EditProfileModel>(
    async (values: EditProfileModel) =>
      await axios.post(EDIT_PROFILE_ENDPOINT, values),
  );
};

export default useEditProfile;
