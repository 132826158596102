import styled from "styled-components";

import colors from "@src/common/styles/colors";
import { defaultLetterSpacing } from "@src/common/styles/mixins";

export const ERROR_MARGIN = 4;
export const ERROR_HEIGHT = 14;

const ErrorMessage = styled.div`
  ${defaultLetterSpacing};
  margin-top: ${ERROR_MARGIN}px;
  color: ${colors.red};
  font-size: 10px;
  line-height: ${ERROR_HEIGHT}px;
`;

export default ErrorMessage;
