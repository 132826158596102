import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_L,
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_S,
} from "@src/common/styles/const";
import {
  defaultLetterSpacing,
  defaultTransition,
  media,
} from "@src/common/styles/mixins";

export const SIDEBAR_WIDTH = 117;
const SIDEBAR_WIDTH_TABLET = 332;
const SIDEBAR_WIDTH_MOBILE = 280;

export const SidebarWrapper = styled.div`
  width: ${SIDEBAR_WIDTH}px;
  gap: ${DEFAULT_MARGIN_L}px;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;

  ${media.up("LAPTOP")`
    position: fixed;
    padding: ${DEFAULT_MARGIN_L}px 0 ${DEFAULT_MARGIN_L}px ${DEFAULT_MARGIN_M}px;
    background-color: ${colors.background};
  `}

  ${media.down("TABLET")`
    padding: ${DEFAULT_MARGIN_L}px ${DEFAULT_MARGIN_M}px;
    background-color: ${colors.white};
    width: ${SIDEBAR_WIDTH_TABLET}px;
  `}

  ${media.down("MOBILE")`
    width: ${SIDEBAR_WIDTH_MOBILE}px;
  `}
`;

export const StyledLink = styled(Link)<{ $isCurrent: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  word-break: normal;
  padding: ${DEFAULT_MARGIN_S}px ${DEFAULT_MARGIN_M}px;
  border-radius: 12px;
  background-color: transparent;
  color: ${colors.gray2};
  ${defaultLetterSpacing};
  ${defaultTransition};

  & > :first-child {
    margin-bottom: ${DEFAULT_MARGIN_S}px;
  }

  ${media.down("TABLET")`
    flex-direction: row;
    font-size: ${DEFAULT_MARGIN_M}px;
    &>:first-child {
        margin-bottom:0;
        margin-right: ${DEFAULT_MARGIN_M}px;
    }
  `}

  ${(p) =>
    p.$isCurrent &&
    css`
      background-color: ${colors.gray1};
      color: ${colors.darkBlue};
    `}

  &:hover {
    background-color: ${colors.gray1};
  }
`;
