import styled from "styled-components";

import colors from "@src/common/styles/colors";
import { DEFAULT_MARGIN_L, DEFAULT_MARGIN_M } from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import { ReactComponent as PencilIcon } from "@src/modules/App/Notifications/icons/pencil.svg";
import BaseSideModalWrapper from "@src/modules/shared/components/Modal/BaseSideModalWrapper";

const NOTIFICATIONS_MODAL_WIDTH = 437;

export const NotificationsModalWrapper = styled(BaseSideModalWrapper).attrs({
  direction: "right",
})`
  width: ${NOTIFICATIONS_MODAL_WIDTH}px;
  background-color: ${colors.white};

  ${media.down("MOBILE")`
    width: 100%;
  `};
  overflow: auto;
`;

export const NotificationsHeaderContainer = styled.div`
  display: flex;
  padding: 40px ${DEFAULT_MARGIN_L}px 0px ${DEFAULT_MARGIN_L}px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${media.down("MOBILE")`
    width: 100vw;
    padding: ${DEFAULT_MARGIN_M}px ${DEFAULT_MARGIN_M}px 0px ${DEFAULT_MARGIN_M}px;
  `};
`;

export const NotificationsHeader = styled.h3`
  font-weight: 400;
  line-height: 25px;
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
`;

export const MarkAsReadIcon = styled(PencilIcon)``;

export const NotificationsContainer = styled.div`
  padding: ${DEFAULT_MARGIN_L}px;

  ${media.down("MOBILE")`
    width: 100vw;
    padding: ${DEFAULT_MARGIN_M}px;
  `};
`;
