import { ReactElement, useRef } from "react";

import useOnClickOutside from "@src/common/hooks/useOnClickOutside";
import {
  AddOption,
  DropdownBadge,
  DropdownComponentWrapper,
  DropdownLabel,
  DropdownOption,
  DropdownStyle,
  DropdownToggle,
  DropdownWrapper,
  OptionName,
  OptionNameWrapper,
  OptionsWrapper,
  ScrollWrapper,
  StyledDropdown,
  TextWrapper,
} from "@src/modules/shared/components/Dropdown/Dropdown.components";
import useDropdown from "@src/modules/shared/components/Dropdown/useDropdown";
import ErrorMessage from "@src/modules/shared/components/Form/ErrorMessage";
import Checkbox from "@src/modules/shared/components/Form/Checkbox";

export interface DropdownOption<T> {
  name: string;
  value: T;
  badge?: ReactElement;
  disabled?: boolean;
}

export interface DropdownProps<T> {
  options: DropdownOption<T>[];
  value?: T | T[];
  placeholder?: string;
  label?: string;
  style?: DropdownStyle;
  isLoading?: boolean;
  disabled?: boolean;
  error?: string;
  showError?: boolean;
  className?: string;
  addOption?: ReactElement;
  onChange: (value: T) => void;
  onClose?: () => void;
  multi?: boolean;
}

const Dropdown = <T,>({
  options,
  value,
  placeholder,
  label,
  style = "lightBig",
  isLoading,
  disabled,
  error,
  showError = false,
  className,
  addOption,
  onChange,
  onClose,
  multi,
}: DropdownProps<T>) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdown = useDropdown();

  const onClickOutside = () => {
    if (dropdown.isOpen) {
      dropdown.setIsOpen(false);
      onClose?.();
    }
  };

  useOnClickOutside(dropdownRef, onClickOutside);

  const handleOptionClick = (
    option: DropdownOption<T>,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (!option.disabled) {
      onChange(option.value);
      if (!!!multi) dropdown.setIsOpen(false);
    }
  };

  const onCurrentOptionClick = () => {
    if (disabled) {
      return;
    }
    if (dropdown.isOpen) {
      onClose?.();
    }
    dropdown.toggle();
  };

  const current =
    !!multi && value
      ? {
          name: `${options
            .filter((option) => (value as T[]).some((v) => option.value === v))
            .map((option) => option.name)
            .join(", ")}`,
        }
      : value
      ? options.find((option: DropdownOption<T>) => option.value === value)
      : undefined;

  const currentOption = (
    <DropdownOption
      onClick={onCurrentOptionClick}
      $isOpen={dropdown.isOpen}
      isLoading={isLoading}
      dropdownStyle={style}
      isCurrent={true}
      isError={!!error}
      disabled={disabled}
    >
      {label && <DropdownLabel>{label}</DropdownLabel>}
      <OptionNameWrapper isBoldStyle={style === "bold"}>
        <TextWrapper isPlaceholder={!current?.name}>
          {current?.name || placeholder}
        </TextWrapper>
        {!isLoading && (
          <DropdownToggle $isOpen={dropdown.isOpen} disabled={disabled} />
        )}
      </OptionNameWrapper>
    </DropdownOption>
  );

  const dropdownOptions = options.map((option, index) => (
    <DropdownOption
      key={index}
      onClick={(e) => handleOptionClick(option, e)}
      dropdownStyle={style}
      hasBadge={!!option.badge}
      disabled={option.disabled}
    >
      {!!multi ? (
        <Checkbox
          name={option.name}
          hasBigLabel
          checked={(value as T[])?.some((v) => v === option.value) ?? false}
          stopPropagation={multi}
        >
          <OptionName>{option.name}</OptionName>
        </Checkbox>
      ) : (
        <>
          <OptionName>{option.name}</OptionName>
          {option.badge && <DropdownBadge>{option.badge}</DropdownBadge>}
        </>
      )}
    </DropdownOption>
  ));

  return (
    <DropdownComponentWrapper
      className={className}
      isError={!!error}
      showError={showError}
      ref={dropdownRef}
    >
      <DropdownWrapper dropdownStyle={style}>
        <StyledDropdown
          $isOpen={dropdown.isOpen}
          dropdownStyle={style}
          isLabel={!!label}
        >
          {currentOption}
          <OptionsWrapper dropdownStyle={style} $isOpen={dropdown.isOpen}>
            <ScrollWrapper>
              {dropdownOptions}
              {addOption && (
                <AddOption onClick={() => dropdown.setIsOpen(false)}>
                  {addOption}
                </AddOption>
              )}
            </ScrollWrapper>
          </OptionsWrapper>
        </StyledDropdown>
      </DropdownWrapper>
      {error && showError && <ErrorMessage>{error}</ErrorMessage>}
    </DropdownComponentWrapper>
  );
};

export default Dropdown;
