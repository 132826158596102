import { useEffect } from "react";

import AppEventTarget, {
  appEventTypes,
} from "@src/common/events/AppEventTarget";

const useConnectorTooltipHandler = (showTooltip: () => void) => {
  useEffect(() => {
    AppEventTarget.addEventListener(
      appEventTypes.SHOW_CONNECTOR_TOOLTIP,
      showTooltip,
    );
    return () =>
      AppEventTarget.removeEventListener(
        appEventTypes.SHOW_CONNECTOR_TOOLTIP,
        showTooltip,
      );
  }, [showTooltip]);
};

export default useConnectorTooltipHandler;
