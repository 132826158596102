import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";

import useAxios from "@src/config/axios/useAxios";
import { CONTACT_WITH_SALES_ENDPOINT } from "@src/config/baseUrls";

const useContactSales = () => {
  const axios = useAxios();
  return useMutation<AxiosResponse, AxiosError>("contactSales", () =>
    axios.post(CONTACT_WITH_SALES_ENDPOINT),
  );
};

export default useContactSales;
