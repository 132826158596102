import FileSaver from "file-saver";
import { toast } from "react-toastify";

import AppEventTarget from "@src/common/events/AppEventTarget";
import FileDownloadEvent from "@src/common/events/FileDownloadEvent";
import { OTHER_ERROR } from "@src/common/helpers/toastIds";
import { useErrorMessage } from "@src/common/helpers/useErrorMessage";
import useAxios from "@src/config/axios/useAxios";
import { CONNECTOR_ENDPOINT } from "@src/config/baseUrls";

const useDownloadConnector = () => {
  const axios = useAxios();
  const getErrorMessage = useErrorMessage();

  return async (): Promise<void> => {
    try {
      AppEventTarget.dispatchEvent(new FileDownloadEvent("start"));
      const response = await axios.get(CONNECTOR_ENDPOINT, {
        responseType: "blob",
      });

      const fileName = "connector_install.zip";
      FileSaver.saveAs(response.data, fileName);
    } catch (err) {
      toast.error(getErrorMessage(err), { toastId: OTHER_ERROR });
    } finally {
      AppEventTarget.dispatchEvent(new FileDownloadEvent("end"));
    }
  };
};

export default useDownloadConnector;
