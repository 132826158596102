import { HubConnection } from "@microsoft/signalr";
import { Dispatch } from "react";

import AppEventTarget from "@src/common/events/AppEventTarget";
import ConnectorStatusEvent from "@src/common/events/ConnectorStatusEvent";
import ShowConnectorTooltipEvent from "@src/common/events/ShowConnectorTooltipEvent";
import UpdateConnectorStatusEvent, {
  ConnectorStatusEventDetail,
} from "@src/common/events/UpdateConnectorStatusEvent";
import { PageableList } from "@src/modules/App/Notifications/shared/pageableList";
import { INotification } from "@src/modules/App/Notifications/shared/types";
import { NotificationEvents } from "@src/modules/App/Notifications/signalR/enums";
import {
  Actions,
  ActionTypes,
} from "@src/modules/App/Notifications/signalR/reducer/actions";

export const useListeners = () => {
  const {
    AddNotification,
    ReceiveNotifications,
    MarkedAsRead,
    SetConnectorStatus,
    UpdateConnectorStatus,
    ShowConnectorTooltip,
  } = NotificationEvents;

  const registerListeners = (
    hub: HubConnection | undefined,
    dispatch: Dispatch<Actions>,
  ) => {
    if (!hub) return;

    hub.on(AddNotification, (notification: INotification) =>
      dispatch({
        type: ActionTypes.AddNotification,
        data: notification,
      }),
    );

    hub.on(ReceiveNotifications, (page: PageableList<INotification>) =>
      dispatch({ type: ActionTypes.ReceiveNotifications, page }),
    );

    hub.on(MarkedAsRead, (notificationId?: string) =>
      dispatch({ type: ActionTypes.MarkedAsRead, notificationId }),
    );

    hub.on(SetConnectorStatus, () => {
      AppEventTarget.dispatchEvent(new ConnectorStatusEvent());
    });

    hub.on(
      UpdateConnectorStatus,
      (connectorStatus: ConnectorStatusEventDetail) =>
        AppEventTarget.dispatchEvent(
          new UpdateConnectorStatusEvent(connectorStatus.status),
        ),
    );

    hub.on(ShowConnectorTooltip, () =>
      AppEventTarget.dispatchEvent(new ShowConnectorTooltipEvent()),
    );
  };

  const deregisterListeners = (hub: HubConnection | undefined) => {
    if (!hub) return;

    hub.off(AddNotification);
    hub.off(ReceiveNotifications);
    hub.off(MarkedAsRead);
    hub.off(SetConnectorStatus);
    hub.off(UpdateConnectorStatus);
    hub.off(ShowConnectorTooltip);
  };

  return { registerListeners, deregisterListeners };
};
