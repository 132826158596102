import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useIsMobile } from "@src/common/styles/media";
import ConnectorLog from "@src/modules/App/Connector/ConnectorLog";
import {
  ConnectorModalHeader,
  ConnectorModalTitle,
  ConnectorModalWrapper,
  LogsHeaders,
  LogsWrapper,
} from "@src/modules/App/Connector/ConnectorModal.components";
import EmptyState from "@src/modules/App/Connector/EmptyState";
import useConnectorLogs from "@src/modules/App/Connector/useConnectorLogs";
import useDownloadConnector from "@src/modules/App/hooks/useDownloadConnector";
import Button from "@src/modules/shared/components/Button";
import ConnectorLabel from "@src/modules/shared/components/ConnectorLabel";
import InfiniteScrollLoader from "@src/modules/shared/components/InfiniteScrollLoader";
import { ModalTransitionProps } from "@src/modules/shared/components/Modal/ModalContainer.components";
import { ReactComponent as DownloadArrow } from "@src/modules/shared/icons/download_arrow.svg";

interface Props extends ModalTransitionProps {
  isActive: boolean;
}

const ConnectorModal: FunctionComponent<Props> = ({ status, isActive }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const downloadConnector = useDownloadConnector();

  const { data, fetchNextPage, hasNextPage } = useConnectorLogs(15);

  const handleObserver = (entries: IntersectionObserverEntry[]) => {
    if (hasNextPage && entries[0].isIntersecting) fetchNextPage();
  };

  const connectorLogs =
    data?.pages.flatMap((page) =>
      page.items.map((log) => (
        <ConnectorLog
          key={log.date}
          date={log.date}
          locationName={log.locationName}
          message={log.message}
          ipAddress={log.ipAddress}
          isConnected={log.isConnected}
          status={log.status}
        />
      )),
    ) ?? [];

  const hasAnyLogs = connectorLogs.length > 0;

  const logsTable = (
    <>
      <LogsHeaders>
        <span>{t("date").toUpperCase()}</span>
        <span>{t("location").toUpperCase()}</span>
        <span>{t("IP address").toUpperCase()}</span>
      </LogsHeaders>
      <LogsWrapper>
        {connectorLogs}
        {hasNextPage && (
          <InfiniteScrollLoader handleObserver={handleObserver} />
        )}
      </LogsWrapper>
    </>
  );

  return (
    <ConnectorModalWrapper status={status}>
      <ConnectorModalHeader>
        <ConnectorModalTitle>
          {isActive
            ? t("Diagnomatic Connector is active")
            : t("Diagnomatic Connector is not active")}
        </ConnectorModalTitle>
        <Button
          onClick={downloadConnector}
          buttonType="primary"
          size={isMobile ? "large" : "small"}
        >
          <DownloadArrow />
          <ConnectorLabel>{t("Download")}</ConnectorLabel>
        </Button>
      </ConnectorModalHeader>
      {hasAnyLogs ? (
        logsTable
      ) : (
        <EmptyState downloadConnector={downloadConnector} />
      )}
    </ConnectorModalWrapper>
  );
};

export default ConnectorModal;
