import { ComponentPropsWithoutRef, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useIsMobile } from "@src/common/styles/media";
import { NavButtonWrapper } from "@src/modules/App/Topbar/shared/components";
import { ReactComponent as UpdateIconActive } from "@src/modules/App/Topbar/UpdatesButton/icons/update-icon-active.svg";
import { ReactComponent as UpdateIcon } from "@src/modules/App/Topbar/UpdatesButton/icons/update-icon.svg";
import useUpdates from "@src/modules/App/Updates/hooks/useUpdates";

const UpdatesButton: FunctionComponent<ComponentPropsWithoutRef<"button">> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  const { data } = useUpdates();
  const isMobile = useIsMobile();

  const storageDate = localStorage.getItem("lastUpdateDate");
  const newestUpdate = data?.pages.flatMap((page) => page).find(() => true);
  const hasNewUpdate = newestUpdate?.date !== storageDate;

  return (
    <NavButtonWrapper onClick={onClick}>
      {hasNewUpdate ? <UpdateIconActive /> : <UpdateIcon />}
      {!isMobile && t("Updates")}
    </NavButtonWrapper>
  );
};

export default UpdatesButton;
