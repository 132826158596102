import { FormikProps } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import {
  ButtonsWrapper,
  EditFormWrapper,
  Footer,
  FormGroup,
  FormLabel,
  LinksWrapper,
} from "@src/modules/App/Profile/EditProfile/EditProfileForm.components";
import { EditProfileModel } from "@src/modules/App/Profile/EditProfile/useEditProfile";
import Button from "@src/modules/shared/components/Button";
import { InputField } from "@src/modules/shared/components/Form/Input";
import Label from "@src/modules/shared/components/Form/Label";
import { PasswordInputField } from "@src/modules/shared/components/Form/PasswordInput";
import useDeleteAccount from "@src/modules/App/Profile/EditProfile/hooks/useDeleteAccount";
import { toast } from "react-toastify";

interface Props extends FormikProps<EditProfileModel> {
  userName: string;
  goToProfileView: () => void;
}

const EditProfileForm: FunctionComponent<Props> = ({
  isSubmitting,
  goToProfileView,
  userName,
}) => {
  const { t } = useTranslation();
  const deleteRequestMutation = useDeleteAccount();
  const handleDeleteAccountClick = () =>
    deleteRequestMutation.mutate(undefined, {
      onSuccess: () => {
        toast.success(t("Delete request has been sent"));
        goToProfileView();
      },
    });

  return (
    <EditFormWrapper>
      <FormGroup>
        <FormLabel>{t("Change names").toUpperCase()}</FormLabel>
        <Label>{t("First and last name")}</Label>
        <InputField
          name="userName"
          placeholder={userName}
          showError={true}
          autoComplete="new-password"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>{t("Change password").toUpperCase()}</FormLabel>
        <Label>{t("Current password")}</Label>
        <PasswordInputField
          name="oldPassword"
          placeholder={t("Enter password")}
          showError={true}
          autoComplete="new-password"
        />
        <Label>{t("New password")}</Label>
        <PasswordInputField
          name="password"
          placeholder={t("Enter password")}
          showError={true}
        />
      </FormGroup>
      <Footer>
        <LinksWrapper>
          <Button buttonType="tertiary" onClick={handleDeleteAccountClick}>
            {t("Request delete account")}
          </Button>
        </LinksWrapper>
        <ButtonsWrapper>
          <Button buttonType="secondary" onClick={goToProfileView}>
            {t("Cancel")}
          </Button>
          <Button buttonType="primary" type="submit" disabled={isSubmitting}>
            {t("Save changes")}
          </Button>
        </ButtonsWrapper>
      </Footer>
    </EditFormWrapper>
  );
};

export default EditProfileForm;
