import { useField } from "formik";
import { FunctionComponent } from "react";

import { PasswordInput } from "@src/modules/shared/components/Form/PasswordInput";
import { PasswordInputProps } from "@src/modules/shared/components/Form/PasswordInput/PasswordInput";

type Props = Omit<PasswordInputProps, "name"> & {
  name: string;
};

const PasswordInputField: FunctionComponent<Props> = ({ name, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <PasswordInput
      {...field}
      {...props}
      error={meta.touched && meta.error ? meta.error : undefined}
    />
  );
};

export default PasswordInputField;
