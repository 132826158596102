import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import { DEFAULT_MARGIN_S, DEFAULT_MARGIN_XS } from "@src/common/styles/const";
import { defaultLetterSpacing } from "@src/common/styles/mixins";

export type TooltipPosition = "top" | "left" | "right";

export const TooltipWrapper = styled.div<{ fullWidth: boolean }>`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
`;

export const TooltipDescription = styled.div<{
  position: TooltipPosition;
  isVisible: boolean;
  rect: DOMRect;
}>`
  position: fixed;
  top: ${({ rect }) => rect.top}px;
  left: ${({ rect }) => rect.left}px;

  ${({ position, rect }) =>
    position === "top"
      ? css`
          transform: translate(
            calc((${rect.width}px - 100%) / 2),
            calc(-100% - ${DEFAULT_MARGIN_S}px)
          );
        `
      : position === "left"
      ? css`
          transform: translate(
            calc(-100% - ${DEFAULT_MARGIN_S}px),
            calc((${rect.height}px - 100%) / 2)
          );
        `
      : css`
          transform: translate(
            calc(${rect.width}px + ${DEFAULT_MARGIN_S}px),
            calc((${rect.height}px - 100%) / 2)
          );
        `}
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  ${defaultLetterSpacing};
  line-height: 16px;
  padding: ${DEFAULT_MARGIN_S}px ${DEFAULT_MARGIN_S}px;
  color: ${colors.gray3};
  background-color: ${colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 0.25s;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: ${DEFAULT_MARGIN_XS}px;
`;

export const TooltipDescriptionLine = styled.div`
  display: block;
`;
