import { FunctionComponent, useState } from "react";

import { useIsMobile, useIsTabletDown } from "@src/common/styles/media";
import ConnectorModal from "@src/modules/App/Connector/ConnectorModal";
import useProfile from "@src/modules/App/hooks/useProfile";
import MobileSidebar from "@src/modules/App/MobileSidebar/MobileSidebarModal";
import NotificationsModal from "@src/modules/App/Notifications/NotificationsModal";
import Profile from "@src/modules/App/Profile";
import ConnectorButton from "@src/modules/App/Topbar/ConnectorButton";
import DiagnomaticLogo from "@src/modules/App/Topbar/DiagnomaticLogo";
import SidebarHamburger from "@src/modules/App/Topbar/Hamburger";
import NotificationsButton from "@src/modules/App/Topbar/NotificationsButton";
import ProfileButton from "@src/modules/App/Topbar/ProfileButton";
import {
  TopbarContent,
  TopbarWrapper,
} from "@src/modules/App/Topbar/Topbar.components";
import UpdatesButton from "@src/modules/App/Topbar/UpdatesButton";
import UpdatesModal from "@src/modules/App/Updates/UpdatesModal";
import LanguageDropdown from "@src/modules/shared/components/LanguageDropdown";
import useModal from "@src/modules/shared/components/Modal/useModal";

interface OpenedModalsState {
  profile: boolean;
  notifications: boolean;
  connector: boolean;
  updates: boolean;
}

interface TopbarProps {
  isAuthed: boolean;
}

const TopBar: FunctionComponent<TopbarProps> = ({ isAuthed }) => {
  const isMobile = useIsMobile();
  const isTabletDown = useIsTabletDown();
  const { openSideModal, closeModal } = useModal();
  const { data: profileData } = useProfile();

  const [openedModals, setOpenedModals] = useState<OpenedModalsState>({
    profile: false,
    notifications: false,
    connector: false,
    updates: false,
  });

  const onProfileButtonClick = () => {
    setOpenedModals((prevState) => ({
      ...prevState,
      notifications: false,
      connector: false,
      updates: false,
    }));
    if (openedModals.profile) {
      closeModal();
    } else {
      openSideModal(<Profile />, true, () => {
        setOpenedModals((prevState) => ({
          ...prevState,
          profile: false,
        }));
      });
    }
    setOpenedModals((prevState) => ({
      ...prevState,
      profile: !prevState.profile,
    }));
  };

  const onNotificationOpenUrlClick = () => {
    setOpenedModals(() => ({
      notifications: false,
      profile: false,
      connector: false,
      updates: false,
    }));
    closeModal();
  };

  const onNotificationsButtonClick = () => {
    setOpenedModals((prevState) => ({
      ...prevState,
      profile: false,
      connector: false,
      updates: false,
    }));
    if (openedModals.notifications) {
      closeModal();
    } else {
      openSideModal(
        <NotificationsModal
          onNotificationOpenUrlClick={onNotificationOpenUrlClick}
        />,
        true,
        () => {
          setOpenedModals((prevState) => ({
            ...prevState,
            notifications: false,
          }));
        },
      );
    }
    setOpenedModals((prevState) => ({
      ...prevState,
      notifications: !prevState.notifications,
    }));
  };

  const onConnectorButtonClick = () => {
    setOpenedModals((prevState) => ({
      ...prevState,
      profile: false,
      notifications: false,
      updates: false,
    }));
    if (openedModals.connector) {
      closeModal();
    } else {
      openSideModal(
        <ConnectorModal isActive={!!profileData?.hasActiveConnector} />,
        true,
        () => {
          setOpenedModals((prevState) => ({
            ...prevState,
            connector: false,
          }));
        },
      );
    }
    setOpenedModals((prevState) => ({
      ...prevState,
      connector: !prevState.connector,
    }));
  };

  const onUpdatesButtonClick = () => {
    setOpenedModals((prevState) => ({
      ...prevState,
      profile: false,
      notifications: false,
      connector: false,
    }));
    if (openedModals.updates) {
      closeModal();
    } else {
      openSideModal(<UpdatesModal />, true, () => {
        setOpenedModals((prevState) => ({
          ...prevState,
          updates: false,
        }));
      });
    }
    setOpenedModals((prevState) => ({
      ...prevState,
      updates: !prevState.updates,
    }));
  };

  const onHamburgerClick = async () => {
    setOpenedModals({
      profile: false,
      notifications: false,
      connector: false,
      updates: false,
    });

    openSideModal(<MobileSidebar />, false);
  };

  return (
    <TopbarWrapper>
      <TopbarContent>
        {isTabletDown && isAuthed && (
          <SidebarHamburger onClick={onHamburgerClick} />
        )}
        <DiagnomaticLogo />
      </TopbarContent>
      <TopbarContent>
        {isAuthed && <UpdatesButton onClick={onUpdatesButtonClick} />}
        {(!isMobile || !isAuthed) && <LanguageDropdown style="dark" />}
        {isAuthed && <ConnectorButton onClick={onConnectorButtonClick} />}
        {isAuthed && (
          <NotificationsButton
            onClick={onNotificationsButtonClick}
            modalOpen={openedModals.notifications}
          />
        )}
        {isAuthed && (
          <ProfileButton size="small" onClick={onProfileButtonClick} />
        )}
      </TopbarContent>
    </TopbarWrapper>
  );
};

export default TopBar;
