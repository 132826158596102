import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  defaultLetterSpacing,
  defaultTransition,
} from "@src/common/styles/mixins";

export const RemindPasswordLink = styled.a`
  ${defaultLetterSpacing}
  ${defaultTransition}
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${colors.darkBlue};

  &:hover {
    color: ${colors.darkBlue2};
  }
`;
