import React, { forwardRef, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  AttentionIcon,
  NotificationDate,
  NotificationFooterLower,
  NotificationFooterUpper,
  NotificationHeaderWrapper,
  NotificationImage,
  NotificationTextContainer,
  NotificationTitle,
  NotificationWrapper,
  OkIcon,
  WarningIcon,
} from "@src/modules/App/Notifications/Notification/Notification.components";
import NotificationTooltip from "@src/modules/App/Notifications/Notification/NotificationTooltip";
import {
  INotification,
  NotificationActionType,
  NotificationType,
} from "@src/modules/App/Notifications/shared/types";
import { getDeviceIconUrl } from "@src/modules/shared/deviceTypeIconsConfig";

type NotificationProps = INotification & {
  markRead: () => void;
  onNotificationOpenUrlClick: () => void;
};

const NotificationElement: FunctionComponent<NotificationProps> = ({
  actionType,
  deviceType,
  openUrl,
  read,
  type,
  upperInfo,
  lowerInfo,
  date,
  markRead,
  onNotificationOpenUrlClick,
}) => {
  const {
    TestPassed,
    TestFailed,
    ConnectorStudyAdded,
    ConnectorFilesError,
    ActionNeeded,
    ConnectorMissingIPAddressError,
    ConnectorNoDeviceByIPError,
    ConnectorSubscriptionExceededError,
    ConnectorNoPhantomForDeviceError,
  } = NotificationActionType;

  const history = useHistory();
  const { i18n, t } = useTranslation();

  const notificationDateFormatter = new Intl.DateTimeFormat(i18n.language, {
    dateStyle: "medium",
    timeStyle: "short",
  });

  const title =
    actionType === TestPassed
      ? t("Test passed")
      : actionType === TestFailed
      ? t("Test failed")
      : actionType === ConnectorStudyAdded
      ? t("Successfully added study")
      : actionType === ConnectorFilesError
      ? t("Error while processing files in Diagnomatic Connector")
      : actionType === ActionNeeded
      ? t("Device needs attention")
      : actionType === ConnectorMissingIPAddressError
      ? t("Device IP address wasn't provided by Diagnomatic Connector")
      : actionType === ConnectorNoDeviceByIPError
      ? t("No device for provided IP address and location")
      : actionType === ConnectorSubscriptionExceededError
      ? t("Studies limit exceeded")
      : actionType === ConnectorNoPhantomForDeviceError
      ? t("No registered test equipment for device")
      : t("Notification");

  const isStudyNotification = [TestPassed, TestFailed, ActionNeeded].includes(
    actionType,
  );

  const notificationIcon =
    type === NotificationType.Attention ? (
      <AttentionIcon />
    ) : type === NotificationType.Warning ? (
      <WarningIcon />
    ) : (
      <OkIcon />
    );

  const goTo = () => {
    if (!read) markRead();
    if (!openUrl) return;
    onNotificationOpenUrlClick();
    history.push(openUrl);
  };

  const onMarkRead = (e: React.PointerEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!read) markRead();
  };

  return (
    <NotificationWrapper isRead={read} onClick={goTo}>
      {isStudyNotification && (
        <NotificationImage src={getDeviceIconUrl(deviceType)} />
      )}
      <NotificationTextContainer>
        <NotificationHeaderWrapper isRead={read} type={type}>
          {notificationIcon}
          <NotificationTitle>{title}</NotificationTitle>
        </NotificationHeaderWrapper>
        <div>
          <NotificationFooterUpper>{upperInfo}</NotificationFooterUpper>
          <NotificationFooterLower>{lowerInfo}</NotificationFooterLower>
        </div>
      </NotificationTextContainer>
      <NotificationDate isRead={read}>
        {notificationDateFormatter.format(new Date(date))}
      </NotificationDate>
      {!read && <NotificationTooltip onClick={onMarkRead} />}
    </NotificationWrapper>
  );
};

const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  (props, ref) => (
    <div ref={ref}>
      <NotificationElement {...props} />
    </div>
  ),
);
Notification.displayName = "Notification";

export default Notification;
