import { Link } from "react-router-dom";
import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_L,
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_S,
  DEFAULT_MARGIN_XS,
} from "@src/common/styles/const";
import {
  defaultLinkStyle,
  media,
  wrapLongText,
} from "@src/common/styles/mixins";
import { ReactComponent as Play } from "@src/modules/shared/icons/arrow-play.svg";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${DEFAULT_MARGIN_L}px;
  padding: ${DEFAULT_MARGIN_M}px;
  min-height: 100%;

  ${media.down("TABLET")`
    padding: ${DEFAULT_MARGIN_M}px;
  `}

  ${media.down("MOBILE")`
    padding: ${DEFAULT_MARGIN_S}px;
  `}
`;

export const ContentWithTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 20px rgba(3, 74, 100, 0.1);
  border-radius: 36px;
  overflow: hidden;
  background-color: ${colors.background};

  margin: ${DEFAULT_MARGIN_M}px;

  ${media.down("MOBILE")`
    margin: ${DEFAULT_MARGIN_S}px;
  `}
`;

export const PanelHeadline = styled.h2`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: ${colors.almostBlack};

  ${media.down("MOBILE")`
    font-size: 14px;
  `}
`;

export const SubscriptionLabel = styled.h3`
  margin: 0;
  padding: ${DEFAULT_MARGIN_S}px;
  border-radius: 6px;
  background-color: ${colors.background};
  color: ${colors.darkBlue};
  font-size: 12px;
  font-weight: 400;
`;

export const MaxWidthWrapper = styled.div`
  width: 100%;
`;

export const AspectRatioWrapper = styled.div<{ ratio: number }>`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: ${({ ratio }) => 100 / ratio}% 0 0 0;
`;

export const ResponsiveSquare = styled.div`
  position: relative;
  :before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

export const RegularDescription = styled.span`
  font-size: 12px;
  color: ${colors.almostBlack};
`;

export const DescriptionLink = styled(Link)`
  ${defaultLinkStyle}
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  background-color: ${colors.gray2_5};
  height: 1px;
  border: none;
`;

export const KnowledgeBaseTileTitle = styled.h3`
  margin: 0;
  padding: ${DEFAULT_MARGIN_XS}px ${DEFAULT_MARGIN_S}px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  width: 100%;
  ${wrapLongText}
`;

export const ArrowPlay = styled(Play)`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  height: 35%;
  width: 35%;
`;

export const KnowledgeBaseTileThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: ${colors.gray2_5};
`;

export const DateText = styled.span`
  font-weight: 600;
  line-height: 25px;
  text-transform: uppercase;
  color: ${colors.gray3};
`;
