import { TransitionStatus } from "react-transition-group/Transition";
import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import { ZIndex } from "@src/common/styles/const";
import { HEADER_HEIGHT } from "@src/modules/App/Topbar/Topbar.components";
import { ModalState } from "@src/modules/shared/components/Modal/ModalProvider";

export interface TransitionProps {
  status: TransitionStatus;
}

export type ModalTransitionProps = Partial<TransitionProps>;

export type ContainerProps = TransitionProps & {
  position: ModalState["position"];
  mode: ModalState["mode"];
  showHeader: ModalState["showHeader"];
};

export const MODAL_TRANSITION_TIME_MS = 330;

export const Container = styled.div<ContainerProps>`
  position: ${(p) => p.position};
  top: ${(p) => (p.showHeader ? HEADER_HEIGHT : 0)}px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  pointer-events: none;
  z-index: ${(p) => (p.showHeader ? ZIndex.Modal : ZIndex.ModalShowHeader)};
  user-select: none;

  > * {
    user-select: text;
  }

  ${(p) =>
    p.mode === "normal" &&
    css`
      opacity: 1;
    `};

  transition: opacity ${MODAL_TRANSITION_TIME_MS}ms ease-in-out;
  ${(p) =>
    (p.status === "entering" || p.status === "entered") &&
    css`
      opacity: 1;
      pointer-events: all;
    `};
`;

type BackgroundProps = TransitionProps & {
  showHeader: ModalState["showHeader"];
};

export const Background = styled.div<BackgroundProps>`
  position: fixed;
  top: ${(p) => (p.showHeader ? HEADER_HEIGHT : 0)}px;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${colors.gray3};

  opacity: 0;
  z-index: ${(p) =>
    p.showHeader ? ZIndex.ModalBackground : ZIndex.ModalBackgroundShowHeader};

  transition: opacity ${MODAL_TRANSITION_TIME_MS}ms ease-in-out;
  ${(p) =>
    (p.status === "entering" || p.status === "entered") &&
    css`
      opacity: 0.25;
    `};
`;
