import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_L,
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_XL,
} from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import { StyledButton } from "@src/modules/shared/components/Button/Button.components";
import {
  ERROR_HEIGHT,
  ERROR_MARGIN,
} from "@src/modules/shared/components/Form/ErrorMessage";
import FormGroup from "@src/modules/shared/components/Form/FormGroup";

export const PublicFormCheckbox = styled.div`
  margin-bottom: ${DEFAULT_MARGIN_L}px;
`;

export const PublicFormHeader = styled.h2`
  font-size: 24px;
  margin-top: 0;
  margin-bottom: ${DEFAULT_MARGIN_L}px;
  font-weight: 600;
  width: 100%;
  color: ${colors.almostBlack};
`;

export const PublicFormSmallHeader = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: ${DEFAULT_MARGIN_L}px;
  width: 100%;
  color: ${colors.gray3};
`;

export const PublicPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  margin-top: 44px;
  padding: ${DEFAULT_MARGIN_XL}px 70px;
  width: 460px;

  border-radius: 32px;
  box-shadow: 0 4px 20px rgba(3, 74, 100, 0.1);
  background-color: ${colors.white};

  ${media.only("TABLET")`
  margin-top: 104px;
  `}

  ${media.down("MOBILE")`
    flex-grow: 1;
    margin-top: 0;
    padding: ${DEFAULT_MARGIN_M}px;
    width: 100%;
    border-radius: 0;
    box-shadow: none;

    ${FormGroup} {
      width: 100%;
      div {
        width: 100%;
      }
    } 

    > form {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  `}
`;

export const PublicFormButtons = styled.div<{ withMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  ${(p) =>
    p.withMargin &&
    css`
      margin-top: ${DEFAULT_MARGIN_L - ERROR_HEIGHT - ERROR_MARGIN}px;
    `}

  ${media.down("MOBILE")`
    margin-top: auto;
    width: 100%;

    ${StyledButton} {
      width: 100%;

      &:last-of-type {
        margin-top: ${DEFAULT_MARGIN_M}px;
      }
    }
  `}
`;

export const PublicFormLabel = styled.p`
  font-size: 12px;
  margin-top: ${DEFAULT_MARGIN_L}px;
  margin-bottom: ${DEFAULT_MARGIN_M}px;
  width: 100%;
  color: ${colors.gray3};

  ${FormGroup} + & {
    margin-top: ${DEFAULT_MARGIN_L - ERROR_HEIGHT - ERROR_MARGIN}px;
  }
`;
