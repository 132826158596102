import { PageableList } from "@src/modules/App/Notifications/shared/pageableList";
import { INotification } from "@src/modules/App/Notifications/shared/types";
import {
  Actions,
  ActionTypes,
} from "@src/modules/App/Notifications/signalR/reducer/actions";

interface NotificationsState {
  notifications: INotification[] | undefined;
  currentPage: PageableList<INotification> | undefined;
  unreadFlag: boolean;
}

export const reducer = (
  state: NotificationsState,
  action: Actions,
): NotificationsState => {
  const { AddNotification, ReceiveNotifications, MarkedAsRead, SetUnreadFlag } =
    ActionTypes;
  const { notifications, currentPage } = state;
  let newState: NotificationsState = { ...state };

  switch (action.type) {
    case AddNotification:
      newState = {
        notifications: [
          ...(state.notifications?.filter(
            (notification) => notification.id !== action.data.id,
          ) ?? []),
          action.data,
        ],
        currentPage,
        unreadFlag: true,
      };
      break;

    case ReceiveNotifications:
      const newNotifications = [
        ...(state.notifications ?? []),
        ...action.page.items,
      ];
      newState = {
        notifications: newNotifications,
        currentPage: action.page,
        unreadFlag: newNotifications.filter((n) => !n.read).length > 0,
      };
      break;

    case MarkedAsRead:
      if (!notifications) break;

      if (action.notificationId) {
        const notification = notifications.find(
          (n) => n.id === action.notificationId,
        );
        if (notification) {
          notification.read = true;
          newState = {
            notifications: [...notifications],
            currentPage,
            unreadFlag: state.unreadFlag,
          };
        }
      } else {
        newState = {
          notifications: notifications.map((n) => {
            n.read = true;
            return n;
          }),
          currentPage,
          unreadFlag: state.unreadFlag,
        };
      }
      break;

    case SetUnreadFlag:
      newState = { ...state };
      newState.unreadFlag = action.unreadFlag;
      break;

    default:
      newState = state;
  }

  newState.notifications?.sort((a, b) => (a.date < b.date ? 1 : -1));

  return newState;
};
