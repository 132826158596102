import { FunctionComponent } from "react";

import { PublicWrapper } from "@src/modules/App/PublicLayout/PublicLayout.components";
import Topbar from "@src/modules/App/Topbar";

const PublicLayout: FunctionComponent = ({ children }) => (
  <>
    <Topbar isAuthed={false} />
    <PublicWrapper>{children}</PublicWrapper>
  </>
);
export default PublicLayout;
