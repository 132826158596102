import { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CountryDropdownWrapper } from "@src/modules/App/Profile/ViewProfile/CountryDropdown/CountryDropdown.components";
import { UpdateCountryModel } from "@src/modules/App/Profile/ViewProfile/hooks/useUpdateCountry";
import Dropdown, {
  DropdownOption,
} from "@src/modules/shared/components/Dropdown/Dropdown";
import useDropdown from "@src/modules/shared/components/Dropdown/useDropdown";

interface CountryDropdownProps {
  userCountry: string | undefined;
  availableCountries: string[];
  onChange: (value: UpdateCountryModel) => void;
  isLoading: boolean;
  additionalMargin: number;
}

const CountryDropdown: FunctionComponent<CountryDropdownProps> = ({
  userCountry,
  availableCountries,
  onChange,
  isLoading,
  additionalMargin,
}) => {
  const dropdown = useDropdown();
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: string) => {
      dropdown.toggle();
      onChange({ country: value });
    },
    [dropdown, onChange],
  );

  const options: DropdownOption<string>[] = useMemo(
    () =>
      availableCountries.map((country) => ({
        value: country,
        name: t(country as unknown as TemplateStringsArray),
      })),
    [availableCountries, t],
  );

  return (
    <CountryDropdownWrapper margin={additionalMargin}>
      <Dropdown
        style="light"
        options={options}
        isLoading={isLoading}
        value={userCountry}
        onChange={handleChange}
        placeholder={t("Select country")}
      />
    </CountryDropdownWrapper>
  );
};

export default CountryDropdown;
