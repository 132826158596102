import { ComponentPropsWithoutRef, FunctionComponent } from "react";

import {
  StyledButton,
  StyledButtonProps,
} from "@src/modules/shared/components/Button/Button.components";

type ButtonProps = ComponentPropsWithoutRef<"button"> & StyledButtonProps;

const Button: FunctionComponent<ButtonProps> = ({
  children,
  buttonType = "primary",
  size = "small",
  fullWidth = false,
  type = "button",
  ...props
}) => (
  <StyledButton
    type={type}
    buttonType={buttonType}
    size={size}
    fullWidth={fullWidth}
    {...props}
  >
    {children}
  </StyledButton>
);

export default Button;
