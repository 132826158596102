import { LottieOptions, useLottie } from "lottie-react";
import { FunctionComponent } from "react";
import styled from "styled-components";

import { DEFAULT_MARGIN_L } from "@src/common/styles/const";
import animationData from "@src/modules/shared/components/ScreenLoading/loader.json";

const Background = styled.div`
  margin: ${DEFAULT_MARGIN_L}px auto;
  width: 70px;
`;

const SmallLoader: FunctionComponent = () => {
  const options: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  const { View } = useLottie(options);

  return <Background>{View}</Background>;
};

export default SmallLoader;
