import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_S,
  DEFAULT_MARGIN_XS,
} from "@src/common/styles/const";
import { defaultLetterSpacing, media } from "@src/common/styles/mixins";

interface DetailsProps {
  isActive: boolean;
}

export const SubscriptionHeader = styled.div<DetailsProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => (p.isActive ? colors.gray2 : colors.gray2_5)};
  color: ${colors.white};
  font-size: 14px;
  font-weight: 600;
  padding: ${DEFAULT_MARGIN_S}px ${DEFAULT_MARGIN_M}px;
  margin-bottom: ${DEFAULT_MARGIN_XS}px;

  ${media.down("MOBILE")`
    padding: ${DEFAULT_MARGIN_S}px;
  `}
`;

export const SubscriptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: ${DEFAULT_MARGIN_XS}px ${DEFAULT_MARGIN_M}px;

  ${media.down("MOBILE")`
    padding: ${DEFAULT_MARGIN_XS}px ${DEFAULT_MARGIN_S}px;
  `}
`;

export const SubscriptionValue = styled.span`
  ${defaultLetterSpacing};
  font-weight: 600;
`;
