import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_S,
  DEFAULT_MARGIN_XS,
} from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";

export const ProfileDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  padding: 20px 40px;

  & > :first-child {
    margin-right: ${DEFAULT_MARGIN_M}px;
  }

  & > :last-child {
    align-self: flex-start;
    margin-left: auto;
  }

  ${media.down("MOBILE")`
    padding: ${DEFAULT_MARGIN_M}px;
    width: 100vw;
  `}
`;

export const ProfileColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > :last-child {
    margin-right: auto;
    width: auto;
  }
`;

export const UserName = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: ${colors.almostBlack};
  margin-bottom: ${DEFAULT_MARGIN_XS}px;
`;

export const UserEmail = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: ${colors.almostBlack};
  margin-bottom: ${DEFAULT_MARGIN_S}px;
`;
