import { CSSProperties, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { COOKIE } from "@src/common/helpers/toastIds";
import colors from "@src/common/styles/colors";
import { CloseButton } from "@src/modules/shared/components/ToastContainer/ToastContainer";

const CONSENT_COOKIE = "cookieConsent";

const style: CSSProperties = {
  color: colors.white,
  backgroundColor: colors.gray3,
  width: "min(900px, 90vw)",
};

const setCookie = () => {
  document.cookie = `${CONSENT_COOKIE}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/; samesite=strict`;
};

const useCookieInfo = () => {
  const { t } = useTranslation();

  return useEffect(() => {
    const cookies = document.cookie.split("; ");
    const consentCookie = cookies.find((row) => row.startsWith(CONSENT_COOKIE));

    if (!consentCookie) {
      toast.info(
        t(
          "This page uses cookies. If you don't want them to be used please change your internet browser settings",
        ),
        {
          toastId: COOKIE,
          onClick: setCookie,
          autoClose: false,
          style,
          closeButton: <CloseButton showText />,
        },
      );
    } else {
      setCookie();
    }
  });
};

export default useCookieInfo;
