export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const INSUFFICIENT_SUBSCRIPTION = "INSUFFICIENT_SUBSCRIPTION";
export const OTHER_ERROR = "OTHER_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const COOKIE = "COOKIE_CONSENT";
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";
export const NOTIFICATIONS_UPDATE_ERROR = "NOTIFICATIONS_UPDATE_ERROR";
export const CHANGES_APPLIED = "CHANGES_APPLIED";
export const LICENSE_ACTIVATED = "LICENSE_ACTIVATED";
export const REQUEST_SEND = "REQUEST_SEND";
export const SELECT_COUNTRY = "SELECT_COUNTRY";
export const CREATED_SUCCESSFULLY = "CREATED_SUCCESSFULLY";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";
export const DELETE_FILE_ERROR = "DELETE_FILE_ERROR";
export const DELETED_SUCCESSFULLY = "DELETED_SUCCESSFULLY";
export const REANALYZE_IN_PROGRESS = "REANALYZE_IN_PROGRESS";
export const NO_COMPATIBLE_TEST_EQUIPMENT = "NO_COMPATIBLE_TEST_EQUIPMENT";
export const INVITATION_SENT = "INVITATION_SENT";
export const TASK_DISMISSED_SUCCESSFULLY = "TASK_DISMISSED_SUCCESSFULLY";
export const AXIOS_CMS_ERROR = "AXIOS_CMS_ERROR";
export const ACTIVATED_SUCCESSFULLY = "ACTIVATED_SUCCESSFULLY";
export const EMAIL_SENT = "EMAIL_SENT";
export const DOWNLOADING_FILES = "DOWNLOADING_FILES";
