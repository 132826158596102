import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { DEFAULT_MARGIN_XS } from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import { ReactComponent as Logo } from "@src/modules/App/Topbar/icons/diagnomatic-logo.svg";
import routesPaths from "@src/modules/Routing/routesPaths";

const StyledLogo = styled(Logo)`
  height: 25px;

  ${media.down("MOBILE")`
    width: 122px;
    margin-left: -${DEFAULT_MARGIN_XS}px;
  `}
`;

const BuildVersion = styled.div`
  display: flex;
  justify-content: end;
  font-size: 12px;
`;

const DiagnomaticLogo: FunctionComponent = () => (
  <Link to={routesPaths.HOME}>
    <StyledLogo />
    <BuildVersion>v {process.env.REACT_APP_VERSION}</BuildVersion>
  </Link>
);

export default DiagnomaticLogo;
