import { ComponentPropsWithoutRef, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import {
  IndicatorWrapper,
  NotificationTooltipWrapper,
  TooltipIndicator,
} from "@src/modules/App/Notifications/Notification/NotificationTooltip/NotificationTooltip.components";
import Tooltip from "@src/modules/shared/components/Tooltip";

const NotificationTooltip: FunctionComponent<
  ComponentPropsWithoutRef<"div">
> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <NotificationTooltipWrapper onClick={onClick}>
      <Tooltip description={t("Mark as read")} position="left">
        <IndicatorWrapper>
          <TooltipIndicator />
        </IndicatorWrapper>
      </Tooltip>
    </NotificationTooltipWrapper>
  );
};

export default NotificationTooltip;
