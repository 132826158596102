import { useQuery } from "react-query";

import useAxios from "@src/config/axios/useAxios";
import { SALES_CONTACT_ENDPOINT } from "@src/config/baseUrls";
import { queryKeys } from "@src/config/queryKeys";

type SalesContactResponse = { country: string; email: string; phone: string }[];

const useSalesContact = () => {
  const axios = useAxios();

  return useQuery<SalesContactResponse>(queryKeys.SALES_CONTACT, async () => {
    const { data } = await axios.get(SALES_CONTACT_ENDPOINT);
    return data;
  });
};

export default useSalesContact;
