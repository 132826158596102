import axios from "axios";
import { useMutation } from "react-query";

import AppEventTarget from "@src/common/events/AppEventTarget";
import {
  TokenResponse,
  userTokenFromResponse,
  setUserToken,
} from "@src/common/helpers/userToken";
import {
  baseAxiosHeaders,
  baseAxiosConfig,
} from "@src/config/axios/AxiosProvider";
import { REFRESH_TOKEN_ENDPOINT } from "@src/config/baseUrls";
import TokenChangeEvent from "@src/common/events/TokenChangeEvent";
import useLogout from "@src/common/hooks/useLogout";

const fetchRefreshToken = async () => {
  const { data } = await axios.post<TokenResponse>(
    REFRESH_TOKEN_ENDPOINT,
    undefined,
    {
      headers: {
        ...baseAxiosHeaders,
      },
      ...baseAxiosConfig,
    },
  );
  return data;
};

const useRefreshToken = () => {
  const mutation = useMutation<TokenResponse>(fetchRefreshToken);
  const { logout } = useLogout();

  return async () => {
    if (!mutation.isLoading) {
      try {
        const response = await mutation.mutateAsync();

        const userToken = userTokenFromResponse(response);
        setUserToken(userToken);
        AppEventTarget.dispatchEvent(new TokenChangeEvent(userToken));
      } catch (error) {
        logout();
      }
    }
  };
};

export default useRefreshToken;
