import { Form, FormikProps } from "formik";
import { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router";

import LoadingProps from "@src/common/helpers/LoadingProps";
import { useIsMobile } from "@src/common/styles/media";
import {
  PublicFormButtons,
  PublicFormHeader,
  PublicFormLabel,
  PublicFormSmallHeader,
} from "@src/modules/Public/shared/components";
import { LoginState } from "@src/modules/Public/shared/LoginForm/LoginForm";
import { RemindPasswordLink } from "@src/modules/Public/shared/LoginForm/LoginFormContent.components";
import { LoginModel } from "@src/modules/Public/shared/LoginForm/useLogin";
import RulesAndPrivacy from "@src/modules/Public/shared/RulesAndPrivacy";
import routesPaths from "@src/modules/Routing/routesPaths";
import Button from "@src/modules/shared/components/Button";
import FormGroup from "@src/modules/shared/components/Form/FormGroup";
import { InputField } from "@src/modules/shared/components/Form/Input";
import Label from "@src/modules/shared/components/Form/Label";
import { PasswordInputField } from "@src/modules/shared/components/Form/PasswordInput";

type Props = FormikProps<LoginModel> & LoadingProps & LoginState;

const LoginFormContent: FunctionComponent<Props> = ({
  isSubmitting,
  isLoading,
  mode,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();

  const submitButton = useMemo(
    () => (
      <Button
        type="submit"
        buttonType="primary"
        size="large"
        disabled={isSubmitting || isLoading}
      >
        {t("Log in")}
      </Button>
    ),
    [isSubmitting, isLoading, t],
  );

  const goToRegisterPage = useCallback(
    () => history.push(routesPaths.REGISTER),
    [history],
  );

  return (
    <Form>
      <PublicFormHeader>
        {mode === "activated"
          ? t("You have been registered")
          : mode === "regular"
          ? t("Log in")
          : t("Your password is changed")}
      </PublicFormHeader>
      {mode !== "regular" && (
        <PublicFormSmallHeader>
          {t("Log in and start using Diagnomatic")}
        </PublicFormSmallHeader>
      )}
      <FormGroup>
        <Label>{t("E-mail")}</Label>
        <InputField
          name="email"
          type="email"
          placeholder={t("Enter e-mail")}
          showError={true}
          tabIndex={1}
          disabled={isLoading}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t("Password")}</Label>
        <RemindPasswordLink href={generatePath(routesPaths.RESET_PASSWORD)}>
          {t("Forgot password?")}
        </RemindPasswordLink>
        <PasswordInputField
          name="password"
          placeholder={t("Enter password")}
          showError={true}
          tabIndex={2}
          disabled={isLoading}
        />
      </FormGroup>
      <PublicFormLabel>
        <RulesAndPrivacy isLogin={true} />
      </PublicFormLabel>
      <PublicFormButtons>
        {!isMobile && submitButton}
        {mode === "regular" && (
          <>
            <PublicFormLabel>{t("Don't have an account yet?")}</PublicFormLabel>
            <Button
              buttonType="secondary"
              size="large"
              onClick={goToRegisterPage}
            >
              {t("Register")}
            </Button>
          </>
        )}
        {isMobile && submitButton}
      </PublicFormButtons>
    </Form>
  );
};

export default LoginFormContent;
