import { FormikHelpers } from "formik";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

import { LICENSE_ACTIVATED } from "@src/common/helpers/toastIds";
import useLogout from "@src/common/hooks/useLogout";
import { queryKeys } from "@src/config/queryKeys";
import useProfile, { AccountType } from "@src/modules/App/hooks/useProfile";
import {
  BottomProfileWrapper,
  ProfileWrapper,
} from "@src/modules/App/Profile/shared/components";
import CountryDropdown from "@src/modules/App/Profile/ViewProfile/CountryDropdown";
import useActivation, {
  ActivationModel,
} from "@src/modules/App/Profile/ViewProfile/hooks/useActivation";
import useSalesContact from "@src/modules/App/Profile/ViewProfile/hooks/useSalesContact";
import useUpdateCountry, {
  UpdateCountryModel,
} from "@src/modules/App/Profile/ViewProfile/hooks/useUpdateCountry";
import { ReactComponent as Exit } from "@src/modules/App/Profile/ViewProfile/icons/exit.svg";
import ProfileDetails from "@src/modules/App/Profile/ViewProfile/ProfileDetails";
import Activation from "@src/modules/App/Profile/ViewProfile/Subscription/Activation";
import SubscriptionContact from "@src/modules/App/Profile/ViewProfile/Subscription/SubscriptionContact";
import SubscriptionDetails from "@src/modules/App/Profile/ViewProfile/Subscription/SubscriptionDetails";
import {
  DropdownsWrapper,
  LogoutButton,
} from "@src/modules/App/Profile/ViewProfile/ViewProfile.components";
import LanguageDropdown from "@src/modules/shared/components/LanguageDropdown";
import useModal from "@src/modules/shared/components/Modal/useModal";
import { DEFAULT_MARGIN_M } from "@src/common/styles/const";

interface Props {
  goToEdit: () => void;
}

const ViewProfile: FunctionComponent<Props> = ({ goToEdit }) => {
  const [country, setCountry] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const contactQuery = useSalesContact();
  const codeMutation = useActivation();
  const countryMutation = useUpdateCountry();
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { data: profileData } = useProfile();
  const { logout } = useLogout();

  const handleLogout = () => {
    closeModal();
    logout();
  };

  const handleCountryChange = async (value: UpdateCountryModel) => {
    countryMutation.mutate(value, {
      onSuccess: () => setCountry(value.country),
    });
  };

  const handleActivation = (
    values: ActivationModel,
    helpers: FormikHelpers<ActivationModel>,
  ) => {
    codeMutation.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.PROFILE);
        helpers.resetForm();
        toast.success(t("License successfully activated"), {
          toastId: LICENSE_ACTIVATED,
        });
      },
      onError: (error) => {
        const errorMsg =
          error.response?.data.message ||
          t("Something went wrong, please try again");
        helpers.setFieldError("subscriptionCode", errorMsg);
      },
      onSettled: () => helpers.setSubmitting(false),
    });
  };

  const currentCountry = country ?? profileData?.country;

  const currentContact = contactQuery.data?.find(
    (contact) => contact.country === (currentCountry ?? "poland"),
  );
  const hasGroupSubscription =
    profileData?.accountType === AccountType.GroupAdmin ||
    profileData?.accountType === AccountType.GroupMember;

  return (
    <ProfileWrapper>
      {profileData && (
        <>
          <ProfileDetails
            email={profileData.email}
            name={profileData.name}
            goToEdit={goToEdit}
          />
          <BottomProfileWrapper>
            <DropdownsWrapper>
              <LanguageDropdown style="light" />
              {contactQuery.data && (
                <CountryDropdown
                  userCountry={currentCountry}
                  availableCountries={contactQuery.data.map(
                    (contact) => contact.country,
                  )}
                  onChange={handleCountryChange}
                  isLoading={countryMutation.isLoading}
                  additionalMargin={DEFAULT_MARGIN_M}
                />
              )}
            </DropdownsWrapper>
            <SubscriptionDetails subscription={profileData.subscription} />
            {!hasGroupSubscription && contactQuery.data && (
              <SubscriptionContact
                phone={currentContact?.phone}
                email={currentContact?.email}
              >
                <Activation handleSubmit={handleActivation} />
              </SubscriptionContact>
            )}
          </BottomProfileWrapper>
        </>
      )}
      <LogoutButton
        data-cy="logout-button"
        buttonType="tertiary"
        onClick={handleLogout}
      >
        <Exit />
        {t("Log out")}
      </LogoutButton>
    </ProfileWrapper>
  );
};

export default ViewProfile;
