import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import { DEFAULT_MARGIN_M, DEFAULT_MARGIN_S } from "@src/common/styles/const";
import { defaultLetterSpacing } from "@src/common/styles/mixins";

export interface ButtonProps {
  size: "small" | "medium" | "large";
}

export const ProfileButtonWrapper = styled.button<ButtonProps>`
  ${defaultLetterSpacing}
  background-color: ${colors.gray1};
  border-radius: 50%;
  font-weight: 600;
  text-align: center;
  line-height: 1em;
  color: ${colors.lightBlue};

  ${(p) =>
    p.size === "small"
      ? css`
          width: 32px;
          height: 32px;
          padding: ${DEFAULT_MARGIN_S}px;
          font-size: 16px;
        `
      : p.size === "medium"
      ? css`
          width: 64px;
          height: 64px;
          padding: ${DEFAULT_MARGIN_M}px;
          font-size: 32px;
          cursor: auto;
        `
      : css`
          width: 100px;
          height: 100px;
          padding: 25px;
          font-size: 50.125px;
          cursor: auto;
        `}
`;
