import { FunctionComponent, lazy, Suspense, useEffect, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router";

import useConnectorStatusHandler from "@src/common/eventHandlers/useConnectorStatusHandler";
import useTokenChangeHandler from "@src/common/eventHandlers/useTokenChangeHandler";
import isDevelopment from "@src/common/helpers/isDevelopment";
import GlobalStyle from "@src/common/styles/global";
import AppContext, { AppContextType } from "@src/modules/App/AppContext";
import AppSkeleton from "@src/modules/App/AppSkeleton";
import useCookieInfo from "@src/modules/App/hooks/useCookieInfo";
import { useNotificationsCore } from "@src/modules/App/Notifications/hooks/useNotificationsCore";
import AuthedRoute from "@src/modules/Routing/AuthedRoute";
import PublicRoute from "@src/modules/Routing/PublicRoute";
import routes from "@src/modules/Routing/routes";
import ModalProvider from "@src/modules/shared/components/Modal/ModalProvider";
import ScreenLoading from "@src/modules/shared/components/ScreenLoading";
import ToastContainer from "@src/modules/shared/components/ToastContainer";

const routeComponents = routes.map((routeConfig, index) => {
  const component = lazy(routeConfig.importComponent);

  switch (routeConfig.type) {
    case "authed":
      return <AuthedRoute key={index} component={component} {...routeConfig} />;
    case "public":
      return <PublicRoute key={index} component={component} {...routeConfig} />;
    case "unique":
      return <Route key={index} component={component} {...routeConfig} />;
  }
});

const App: FunctionComponent = () => {
  const [token] = useTokenChangeHandler();
  useCookieInfo();

  const notifications = useNotificationsCore();

  useConnectorStatusHandler();

  const context: AppContextType = useMemo(
    () => ({
      userToken: token,
      notifications,
    }),
    [token, notifications],
  );

  useEffect(() => {
    let script: HTMLScriptElement;

    if (!isDevelopment()) {
      script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "hs-script-loader";
      script.async = true;
      script.defer = true;
      script.src = "//js.hs-scripts.com/14563542.js";
      document.body.appendChild(script);
    }

    return () => {
      if (!isDevelopment()) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <AppContext.Provider value={context}>
      <GlobalStyle />
      <ToastContainer />
      <ModalProvider>
        <AppSkeleton>
          <Suspense fallback={<ScreenLoading />}>
            <Switch>
              {routeComponents}
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </AppSkeleton>
      </ModalProvider>
    </AppContext.Provider>
  );
};
export default App;
