import { FormikProps, useField } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { activationCodeId } from "@src/config/consts";
import { ActivationModel } from "@src/modules/App/Profile/ViewProfile/hooks/useActivation";
import {
  CodeInput,
  FormWrapper,
  SubmitButton,
} from "@src/modules/App/Profile/ViewProfile/Subscription/Activation/Activation.components";

type Props = FormikProps<ActivationModel>;

const ActivationForm: FunctionComponent<Props> = ({}) => {
  const { t } = useTranslation();
  const [field, meta] = useField("subscriptionCode");

  return (
    <FormWrapper>
      <CodeInput
        id={activationCodeId}
        placeholder={t("Enter the code")}
        error={!!meta.error}
        {...field}
      />
      <SubmitButton>{t("Apply")}</SubmitButton>
    </FormWrapper>
  );
};

export default ActivationForm;
