import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

import {
  deleteUserToken,
  getTimeToExpire,
  getUserToken,
} from "@src/common/helpers/userToken";
import useAxios from "@src/config/axios/useAxios";
import { redirectToQueryParam } from "@src/modules/Public/shared/LoginForm/LoginForm";
import routesPaths from "@src/modules/Routing/routesPaths";
import { REVOKE_TOKEN_ENDPOINT } from "@src/config/baseUrls";
import { LOGOUT } from "@src/common/helpers/toastIds";
import { queryKeys } from "@src/config/queryKeys";

const useLogout = () => {
  const history = useHistory();
  const axios = useAxios();
  const { t } = useTranslation();

  const mutation = useMutation(queryKeys.LOGOUT, () =>
    axios.post(REVOKE_TOKEN_ENDPOINT),
  );

  const logout = async (showToast = false, redirectPathname?: string) => {
    const token = getUserToken();
    try {
      if (token && getTimeToExpire(token) > 0 && !mutation.isLoading)
        mutation.mutate();
    } catch (error) {
      console.error(error);
    } finally {
      deleteUserToken();
    }

    let target: string = routesPaths.LOGIN;
    if (redirectPathname) {
      target = `${target}?${new URLSearchParams({
        [redirectToQueryParam]: redirectPathname,
      }).toString()}`;
    }

    history.replace(target);

    if (showToast) {
      toast.success(t("Successfully logged out"), {
        toastId: LOGOUT,
      });
    }
  };

  return { logout };
};

export default useLogout;
