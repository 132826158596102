import { FunctionComponent, useEffect, useMemo, useState } from "react";

import useNotificationsContext from "@src/modules/App/hooks/useNotificationsContext";
import {
  BellIcon,
  NotificationsBadge,
  NotificationsButtonWrapper,
} from "@src/modules/App/Topbar/NotificationsButton/NotificationsButton.components";

interface Props {
  onClick: () => void;
  modalOpen: boolean;
}

const NotificationsButton: FunctionComponent<Props> = ({
  onClick,
  modalOpen,
}) => {
  const { notifications, unreadFlag } = useNotificationsContext();
  const [animationRunning, setAnimationRunning] = useState(false);
  const unread = useMemo(
    () => notifications?.filter((n) => !n.read),
    [notifications],
  );

  useEffect(() => {
    if (!unread || modalOpen) return;
    setAnimationRunning(true);
  }, [unread, modalOpen]);

  return (
    <NotificationsButtonWrapper onClick={onClick}>
      <BellIcon
        $animate={!modalOpen && unreadFlag && animationRunning}
        onAnimationEnd={() => setAnimationRunning(false)}
      />
      {!modalOpen && unreadFlag && (
        <NotificationsBadge animate={animationRunning} />
      )}
    </NotificationsButtonWrapper>
  );
};

export default NotificationsButton;
