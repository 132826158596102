import useProfile, { AccountType } from "@src/modules/App/hooks/useProfile";

const useIsUserSuperAdmin = () => {
  const { data: profileData } = useProfile();

  return (
    profileData?.accountType === AccountType.ProProjectAdmin ||
    profileData?.accountType === AccountType.SuperAdmin
  );
};

export default useIsUserSuperAdmin;
