import { HubConnection } from "@microsoft/signalr";
import { useCallback } from "react";
import { toast } from "react-toastify";

import {
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_UPDATE_ERROR,
} from "@src/common/helpers/toastIds";
import i18n from "@src/config/i18n/i18next";
import { PageRequest } from "@src/modules/App/Notifications/shared/types";
import { NotificationEndpoints } from "@src/modules/App/Notifications/signalR/enums";

export const useEmitters = (hub: HubConnection | undefined) => {
  const { MarkAllAsRead, MarkAsRead, GetNotifications } = NotificationEndpoints;

  const getNotificationsEmitter = useCallback(
    async (pageRequest: PageRequest) => {
      if (!hub) return;
      try {
        await hub.invoke(GetNotifications, pageRequest);
      } catch (e) {
        toast.error(i18n.t("Couldn't get notifications"), {
          toastId: NOTIFICATIONS_ERROR,
        });
      }
    },
    [hub, GetNotifications],
  );

  const markReadEmitter = useCallback(
    async (notificationId?: string) => {
      if (!hub) return;
      try {
        if (notificationId) await hub.invoke(MarkAsRead, notificationId);
        else await hub.invoke(MarkAllAsRead);
      } catch (e) {
        toast.error(i18n.t("Couldn't update notification(s)"), {
          toastId: NOTIFICATIONS_UPDATE_ERROR,
        });
      }
    },
    [hub, MarkAllAsRead, MarkAsRead],
  );

  return { getNotificationsEmitter, markReadEmitter };
};
