import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useCallback, useEffect, useState } from "react";

import useTokenChangeHandler from "@src/common/eventHandlers/useTokenChangeHandler";
import { API_URL, NOTIFICATIONS_ENDPOINT } from "@src/config/baseUrls";

export const useHubConnection = () => {
  const [hub, setHub] = useState<HubConnection>();
  const [userToken] = useTokenChangeHandler();

  const setupHub = useCallback(async (token: string) => {
    const hubUrl = `${API_URL}${NOTIFICATIONS_ENDPOINT}`;
    try {
      const hub = new HubConnectionBuilder()
        .withUrl(hubUrl, {
          accessTokenFactory: () => token,
          timeout: 10000,
        })
        .withAutomaticReconnect()
        .build();

      setHub(hub);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e, "hubConnection");
    }
  }, []);

  useEffect(() => {
    if (userToken?.token) {
      setupHub(userToken.token);
    }
  }, [setupHub, userToken?.token]);

  return { hub, setupHub };
};
