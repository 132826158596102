import { useField } from "formik";
import { FunctionComponent } from "react";

import { Input } from "@src/modules/shared/components/Form/Input";
import { InputProps } from "@src/modules/shared/components/Form/Input/Input";

type Props = Omit<InputProps, "name"> & {
  name: string;
};

const InputField: FunctionComponent<Props> = ({ name, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <Input
      {...field}
      {...props}
      value={field.value ?? props.value ?? ""}
      error={meta.touched && meta.error ? meta.error : undefined}
    />
  );
};

export default InputField;
