import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import formatDate from "@src/common/helpers/formatDate";
import { SubscriptionData } from "@src/modules/App/hooks/useProfile";
import {
  SubscriptionHeader,
  SubscriptionRow,
  SubscriptionValue,
} from "@src/modules/App/Profile/ViewProfile/Subscription/shared/components";
import {
  ErrorLogo,
  ExpirationDate,
  SubscriptionDetailsWrapper,
} from "@src/modules/App/Profile/ViewProfile/Subscription/SubscriptionDetails/SubscriptionDetails.components";
import useIsUserSuperAdmin from "@src/modules/shared/hooks/useIsUserSuperAdmin";
import useSubscriptionLabel from "@src/modules/shared/hooks/useSubscriptionLabel";
import useSubscriptionLimits from "@src/modules/shared/hooks/useSubscriptionLimits";

const SubscriptionDetails: FunctionComponent<{
  subscription: SubscriptionData;
}> = ({ subscription }) => {
  const { t } = useTranslation();
  const isSuperAdmin = useIsUserSuperAdmin();

  const isActive = true;
  const { studiesLimit, devicesLimit, phantomsLimit } = useSubscriptionLimits();

  const subscriptionLabel = useSubscriptionLabel();

  return (
    <SubscriptionDetailsWrapper isActive={isActive}>
      <SubscriptionHeader isActive={isActive}>
        {t("My subscription")}
      </SubscriptionHeader>
      <SubscriptionRow>
        {t("Active plan")}
        <SubscriptionValue>{subscriptionLabel}</SubscriptionValue>
      </SubscriptionRow>
      <SubscriptionRow>
        <ExpirationDate>
          {t("Expiration date")}
          {subscription.expiresSoon && <ErrorLogo />}
        </ExpirationDate>
        <SubscriptionValue>
          {formatDate(subscription.expirationDate)}
        </SubscriptionValue>
      </SubscriptionRow>
      {isSuperAdmin && (
        <SubscriptionRow>
          {t("Users in my groups")}
          <SubscriptionValue>{`${subscription.groupMembersCount}/${subscription.groupMembersLimit}`}</SubscriptionValue>
        </SubscriptionRow>
      )}
      <SubscriptionRow>
        {t("Performed tests")}
        <SubscriptionValue>
          {`${subscription.studiesCount}/${studiesLimit}`}
        </SubscriptionValue>
      </SubscriptionRow>
      <SubscriptionRow>
        {t("Diagnostic devices")}
        <SubscriptionValue>
          {`${subscription.devicesCount}/${devicesLimit}`}
        </SubscriptionValue>
      </SubscriptionRow>
      <SubscriptionRow>
        {t("Test equipment (phantoms)")}
        <SubscriptionValue>
          {`${subscription.usedPhantoms}/${phantomsLimit}`}
        </SubscriptionValue>
      </SubscriptionRow>
      <SubscriptionRow>
        {t("License number")}
        <SubscriptionValue>{subscription.redeemCode}</SubscriptionValue>
      </SubscriptionRow>
    </SubscriptionDetailsWrapper>
  );
};

export default SubscriptionDetails;
