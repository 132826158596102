import { useState, useCallback } from "react";

const useDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), [setIsOpen]);

  return { isOpen, setIsOpen, toggle };
};

export default useDropdown;
