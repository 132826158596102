import { FunctionComponent, useEffect, useMemo } from "react";
import FlipMove from "react-flip-move";
import { useTranslation } from "react-i18next";

import useNotificationsContext from "@src/modules/App/hooks/useNotificationsContext";
import Notification from "@src/modules/App/Notifications/Notification";
import {
  MarkAsReadIcon,
  NotificationsContainer,
  NotificationsHeader,
  NotificationsHeaderContainer,
  NotificationsModalWrapper,
} from "@src/modules/App/Notifications/NotificationsModal.components";
import {
  BASE_NOTIFICATION_HEIGHT,
  NOTIFICATIONS_CROP,
} from "@src/modules/App/Notifications/shared/consts";
import Button from "@src/modules/shared/components/Button";
import InfiniteScrollLoader from "@src/modules/shared/components/InfiniteScrollLoader";
import { ModalTransitionProps } from "@src/modules/shared/components/Modal/ModalContainer.components";
import SmallLoader from "@src/modules/shared/components/ScreenLoading/SmallLoader";

const NotificationsModal: FunctionComponent<
  ModalTransitionProps & { onNotificationOpenUrlClick: () => void }
> = ({ status, onNotificationOpenUrlClick }) => {
  const { notifications, currentPage, markAsRead, getNextPage, setUnreadFlag } =
    useNotificationsContext();
  const { t } = useTranslation();
  const pageSize =
    Math.ceil(window.innerHeight / BASE_NOTIFICATION_HEIGHT) -
    NOTIFICATIONS_CROP;
  const allFetched =
    (notifications?.length ?? 1) >= (currentPage?.itemsCount ?? 0);

  const notificationsData = useMemo(
    () =>
      notifications?.map((n) => (
        <Notification
          key={n.id}
          id={n.id}
          actionType={n.actionType}
          deviceType={n.deviceType}
          openUrl={n.openUrl}
          read={n.read}
          type={n.type}
          upperInfo={n.upperInfo}
          lowerInfo={n.lowerInfo}
          date={n.date}
          markRead={() => {
            markAsRead(n.id);
          }}
          onNotificationOpenUrlClick={onNotificationOpenUrlClick}
        />
      )),
    [notifications, markAsRead, onNotificationOpenUrlClick],
  );

  const handleObserver = (entries: IntersectionObserverEntry[]) => {
    if (
      notifications &&
      currentPage &&
      !allFetched &&
      entries[0].isIntersecting
    )
      getNextPage(pageSize);
  };

  useEffect(() => {
    if (!notifications) return;
    setUnreadFlag(false);
  }, [notifications, setUnreadFlag]);

  return (
    <NotificationsModalWrapper status={status}>
      <NotificationsHeaderContainer>
        <NotificationsHeader>{t("Notifications")}</NotificationsHeader>
        {notificationsData && (
          <Button
            disabled={notificationsData.length === 0}
            onClick={() => markAsRead()}
            buttonType="tertiary"
          >
            <MarkAsReadIcon />
            {t("Mark all as read")}
          </Button>
        )}
      </NotificationsHeaderContainer>
      <NotificationsContainer>
        {!notificationsData && <SmallLoader />}
        {notificationsData && (
          <FlipMove duration={200}>{notificationsData}</FlipMove>
        )}
        {!allFetched && (
          <InfiniteScrollLoader handleObserver={handleObserver} />
        )}
      </NotificationsContainer>
    </NotificationsModalWrapper>
  );
};

export default NotificationsModal;
