import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { UnderlinedLink } from "@src/modules/Public/shared/RulesAndPrivacy/RulesAndPrivacy.components";

// NOTE: located in /public
const DEFAULT_TERMS_OF_USE_FILENAME = "Rules of the Diagnomatic.com EN.pdf";
const DEFAULT_PRIVACY_POLICY_FILENAME = "Privacy Policy EN.pdf";

const TERMS_OF_USE_FILENAME_PL = "Regulamin Diagnomatic.com PL.pdf";
const PRIVACY_POLICY_FILENAME_PL = "Privacy Policy PL.pdf";

interface Props {
  isLogin?: boolean;
}

const RulesAndPrivacy: FunctionComponent<Props> = ({ isLogin }) => {
  const { t, i18n } = useTranslation();
  const langCode = i18n.language.split("-")[0];

  const termsOfUseName =
    langCode === "pl"
      ? TERMS_OF_USE_FILENAME_PL
      : DEFAULT_TERMS_OF_USE_FILENAME;
  const privacyPolicyName =
    langCode === "pl"
      ? PRIVACY_POLICY_FILENAME_PL
      : DEFAULT_PRIVACY_POLICY_FILENAME;

  return (
    <>
      {`${
        isLogin
          ? t("By signing in I have taken note of the contents of the")
          : t("I have taken note of the contents of the")
      } `}
      <UnderlinedLink href={`/${termsOfUseName}`} target="_blank">
        {t("Rules of use")}
      </UnderlinedLink>
      {` ${t("of the Online Service Diagnomatic and the")} `}
      <UnderlinedLink href={`/${privacyPolicyName}`} target="_blank">
        {t("Privacy Policy")}
      </UnderlinedLink>
      {`, ${t("and I accept the contents of these documents")}.`}
    </>
  );
};

export default RulesAndPrivacy;
