import { CSSProperties, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastOptions } from "react-toastify";

import AppEventTarget, {
  appEventTypes,
} from "@src/common/events/AppEventTarget";
import FileDownloadEvent from "@src/common/events/FileDownloadEvent";
import { DOWNLOADING_FILES } from "@src/common/helpers/toastIds";
import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_L,
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_S,
} from "@src/common/styles/const";
import { FileLoader } from "@src/modules/shared/components/FileLoader";

const style: CSSProperties = {
  position: "fixed",
  bottom: `${DEFAULT_MARGIN_L}px`,
  right: `${DEFAULT_MARGIN_L}px`,
  backgroundColor: colors.gray3,
  color: colors.white,
  width: "auto",
  margin: 0,
  padding: `${DEFAULT_MARGIN_S}px ${DEFAULT_MARGIN_M}px`,
};

const toastProps: ToastOptions = {
  toastId: DOWNLOADING_FILES,
  position: "bottom-right",
  closeButton: false,
  autoClose: false,
  style,
  closeOnClick: false,
  draggable: false,
};

const useDownloadEventHandler = () => {
  const [filesCount, setFilesCount] = useState(0);
  const { t } = useTranslation();

  const getToastContent = useCallback(
    (filesCount: number) => (
      <>
        {t("Downloading")} {filesCount}{" "}
        {filesCount === 1 ? t("file") : t("files")}
        <FileLoader size="large" />
      </>
    ),
    [t],
  );

  const handler = useCallback(
    ({ detail }: FileDownloadEvent) => {
      setFilesCount((prev) => (detail === "start" ? ++prev : --prev));
    },
    [setFilesCount],
  );

  useEffect(() => {
    AppEventTarget.addEventListener(appEventTypes.FILE_DOWNLOAD, {
      handleEvent: handler,
    });
    return () =>
      AppEventTarget.removeEventListener(appEventTypes.FILE_DOWNLOAD, {
        handleEvent: handler,
      });
  }, [handler]);

  useEffect(() => {
    if (toast.isActive(DOWNLOADING_FILES)) {
      if (filesCount === 0) toast.dismiss(DOWNLOADING_FILES);
      else
        toast.update(DOWNLOADING_FILES, {
          render: getToastContent(filesCount),
        });
    } else {
      if (filesCount > 0) {
        toast.info(getToastContent(filesCount), toastProps);
      }
    }
  }, [filesCount, getToastContent]);
};

export default useDownloadEventHandler;
