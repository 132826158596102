import styled from "styled-components";

import colors from "@src/common/styles/colors";
import { DEFAULT_MARGIN_S } from "@src/common/styles/const";

export const NotificationTooltipWrapper = styled.div`
  position: absolute;
  top: ${DEFAULT_MARGIN_S}px;
  right: ${DEFAULT_MARGIN_S}px;
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  height: 24px;
  width: 24px;

  &:before {
    content: "";
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: ${colors.gray2_5};
    opacity: 0;
    transition: opacity 0.25s;
  }

  &:hover:before {
    opacity: 1;
  }
`;

export const TooltipIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${colors.lightBlue};
`;
