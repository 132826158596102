import { FunctionComponent, useCallback, useState } from "react";

import { Input } from "@src/modules/shared/components/Form/Input";
import { InputProps } from "@src/modules/shared/components/Form/Input/Input";
import eyeCrossedIconSrc from "@src/modules/shared/components/Form/PasswordInput/icons/eye-crossed.svg";
import eyeIconSrc from "@src/modules/shared/components/Form/PasswordInput/icons/eye.svg";

export type PasswordInputProps = Omit<
  InputProps,
  "type" | "icon" | "onIconClick"
>;

const PasswordInput: FunctionComponent<PasswordInputProps> = (props) => {
  const [isPasswordType, setIsPasswordType] = useState<boolean>(true);

  const onIconClick = useCallback(
    () => setIsPasswordType((state) => !state),
    [],
  );

  return (
    <Input
      {...props}
      type={isPasswordType ? "password" : "text"}
      icon={isPasswordType ? eyeIconSrc : eyeCrossedIconSrc}
      onIconClick={onIconClick}
    />
  );
};

export default PasswordInput;
