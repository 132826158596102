interface Breakpoint {
  min?: number;
  max?: number;
}
type Breakpoints = {
  [key in
    | "MOBILE_EXTRA_SMALL"
    | "MOBILE_SMALL"
    | "MOBILE"
    | "TABLET"
    | "LAPTOP"
    | "LAPTOP_CUSTOM"
    | "LAPTOP_LARGE"
    | "LAPTOP_EXTRA_LARGE"
    | "QHD"
    | "UHD"]: Breakpoint;
};

export const ELEMENT_BORDER_RADIUS_SIZE = 6;

export const DEFAULT_MARGIN_XXL = 40;
export const DEFAULT_MARGIN_XL = 32;
export const DEFAULT_MARGIN_L = 24;
export const DEFAULT_MARGIN_M = 16;
export const DEFAULT_MARGIN_S = 8;
export const DEFAULT_MARGIN_XS = 4;

export enum ZIndex {
  Popup = 20,
  OverPopup = 21,
  Dropdown = 22,
  TopPopup = 30,
  ModalBackground = 100,
  Modal = 101,
  Header = 102,
  ModalBackgroundShowHeader = 103,
  ModalShowHeader = 104,
}

export const BREAKPOINTS: Breakpoints = {
  MOBILE_EXTRA_SMALL: {
    max: 374.98,
  },
  MOBILE_SMALL: {
    min: 375,
    max: 479.98,
  },
  MOBILE: {
    min: 480,
    max: 767.98,
  },
  TABLET: {
    min: 768,
    max: 1023.98,
  },
  LAPTOP: {
    min: 1024,
    max: 1439.98,
  },
  LAPTOP_CUSTOM: {
    min: 1024,
    max: 1320,
  },
  LAPTOP_LARGE: {
    min: 1440,
    max: 1919,
  },
  LAPTOP_EXTRA_LARGE: {
    min: 1920,
  },
  QHD: {
    min: 2560,
    max: 3840,
  },
  UHD: {
    min: 3840,
  },
} as const;

export const ORIENTATIONS = {
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait",
} as const;

export const DICOM_VIEWER = "/dicom-viewer";
