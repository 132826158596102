import { FunctionComponent, useEffect, useRef, useState } from "react";

import ConnectorTooltip from "@src/modules/App/Topbar/ConnectorButton/ConnectorTooltip";
import {
  ConnectorIndicator,
  IndicatorWrapper,
} from "@src/modules/App/Topbar/ConnectorButton/TooltipIndicator/TooltipIndicator.components";

interface Props {
  shouldDisplayTooltip: boolean;
  hideIndicator: () => void;
}

const TooltipIndicator: FunctionComponent<Props> = ({
  shouldDisplayTooltip,
  hideIndicator,
}) => {
  const indicatorRef = useRef<HTMLDivElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] =
    useState(shouldDisplayTooltip);

  const handleClose = () => {
    hideIndicator();
    setIsTooltipVisible(false);
  };

  useEffect(() => {
    const indicator = indicatorRef.current;
    const setVisible = () => {
      if (!isTooltipVisible) setIsTooltipVisible(true);
    };

    indicator?.addEventListener("mouseenter", setVisible);

    return () => indicator?.removeEventListener("mouseenter", setVisible);
  }, [indicatorRef, isTooltipVisible]);

  return (
    <>
      <IndicatorWrapper ref={indicatorRef} id="tooltipWrapper">
        <ConnectorIndicator />
      </IndicatorWrapper>
      {indicatorRef.current && (
        <ConnectorTooltip
          closeTooltip={handleClose}
          isVisible={isTooltipVisible}
          container={indicatorRef.current}
        />
      )}
    </>
  );
};

export default TooltipIndicator;
