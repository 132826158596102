import format from "date-fns/format";
import { t } from "i18next";
import { FunctionComponent } from "react";

import { DATE_FORMAT } from "@src/common/helpers/dateFormats";
import { UpdateData } from "@src/modules/App/Updates/hooks/useUpdates";
import {
  UpdateBadge,
  UpdateContent,
  UpdateHeader,
  UpdateText,
  UpdateTitle,
  UpdateWrapper,
} from "@src/modules/App/Updates/Update/Update.components";

const Update: FunctionComponent<UpdateData> = ({
  title,
  date,
  improvements,
  newFeatures,
}) => (
  <UpdateWrapper>
    <UpdateHeader>
      <UpdateTitle>{title}</UpdateTitle>
      <UpdateText>{format(new Date(date), DATE_FORMAT)}</UpdateText>
    </UpdateHeader>
    {newFeatures && (
      <UpdateContent>
        <UpdateBadge type="newFeatures">{t("New features")}</UpdateBadge>
        <UpdateText>{newFeatures}</UpdateText>
      </UpdateContent>
    )}
    {improvements && (
      <UpdateContent>
        <UpdateBadge type="improvements">{t("Improvements")}</UpdateBadge>
        <UpdateText>{improvements}</UpdateText>
      </UpdateContent>
    )}
  </UpdateWrapper>
);

export default Update;
