import {
  TooltipDescription,
  TooltipDescriptionLine,
  TooltipPosition,
} from "@src/modules/shared/components/Tooltip/Tooltip.components";
import { FunctionComponent } from "react";
import { createPortal } from "react-dom";

interface Props {
  descriptions: string[];
  isVisible: boolean;
  position: TooltipPosition;
  rect?: DOMRect;
}

const TOOLTIP_PORTAL_ID = "tooltip-portal";

const TooltipPortal: FunctionComponent<Props> = ({
  descriptions,
  position,
  isVisible,
  rect,
}) => {
  if (!rect) return null;

  return createPortal(
    <TooltipDescription position={position} isVisible={isVisible} rect={rect}>
      {descriptions.map((description, index) => (
        <TooltipDescriptionLine key={index}>
          {description}
        </TooltipDescriptionLine>
      ))}
    </TooltipDescription>,
    document.getElementById(TOOLTIP_PORTAL_ID)!,
  );
};

export default TooltipPortal;
