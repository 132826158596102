import styled, { css } from "styled-components";

import colors from "@src/common/styles/colors";
import { BaseModalWrapperProps } from "@src/modules/shared/components/Modal/BaseModalWrapper";
import { MODAL_TRANSITION_TIME_MS } from "@src/modules/shared/components/Modal/ModalContainer.components";

export type BaseSideModalWrapperProps = BaseModalWrapperProps & {
  direction: "left" | "right";
};

const BaseSideModalWrapper = styled.div<BaseSideModalWrapperProps>`
  background-color: ${colors.background};
  transition: transform ${MODAL_TRANSITION_TIME_MS}ms ease-in-out;
  transform: translate(
    ${(p) => (p.direction === "left" ? "-100%" : "100%")},
    0
  );

  margin-left: ${(p) => (p.direction === "left" ? 0 : "auto")};
  margin-right: ${(p) => (p.direction === "right" ? 0 : "auto")};

  box-shadow: 0px 4px 20px rgba(3, 74, 100, 0.1);

  ${(p) =>
    (p.status === "entering" || p.status === "entered") &&
    css`
      transform: translate(0, 0);
    `};
`;

export default BaseSideModalWrapper;
