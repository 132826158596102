import { FunctionComponent, ReactElement, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router";

import useOnClickOutside from "@src/common/hooks/useOnClickOutside";
import { DICOM_VIEWER } from "@src/common/styles/const";
import {
  DropdownOption,
  DropdownStyle,
  DropdownToggle,
  DropdownWrapper,
  OptionsWrapper,
  StyledDropdown,
} from "@src/modules/shared/components/Dropdown/Dropdown.components";
import useDropdown from "@src/modules/shared/components/Dropdown/useDropdown";
import { ReactComponent as ENG } from "@src/modules/shared/components/LanguageDropdown/flags/GB.svg";
import { ReactComponent as PL } from "@src/modules/shared/components/LanguageDropdown/flags/PL.svg";
import { ReactComponent as RU } from "@src/modules/shared/components/LanguageDropdown/flags/RU.svg";
import {
  LanguageDropdownWrapper,
  LanguageName,
  OptionContent,
} from "@src/modules/shared/components/LanguageDropdown/LanguageDropdown.components";

interface Language {
  code: string;
  name: string;
  flag: ReactElement;
  isDisabled: boolean;
}

interface LanguageDropdownProps {
  style: DropdownStyle;
}

const languages: Language[] = [
  { code: "en", name: "ENG", flag: <ENG />, isDisabled: false },
  { code: "pl", name: "PL", flag: <PL />, isDisabled: false },
  { code: "ru", name: "RU", flag: <RU />, isDisabled: true },
];

const LanguageDropdown: FunctionComponent<LanguageDropdownProps> = ({
  style,
}) => {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();
  const dropdown = useDropdown();
  const location = useLocation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => dropdown.setIsOpen(false));

  const changeLanguage = async (language: Language) => {
    dropdown.setIsOpen(false);
    if (language.isDisabled) return;
    await i18n.changeLanguage(language.code);
    queryClient.invalidateQueries();

    if (location.pathname.startsWith(DICOM_VIEWER)) {
      window.location.reload();
    }
  };

  const current =
    languages.find(
      (lang) =>
        lang.code === i18n.language || i18n.language.includes(lang.code),
    ) || languages[0];

  const currentLanguage = (
    <DropdownOption
      onClick={dropdown.toggle}
      dropdownStyle={style}
      $isOpen={dropdown.isOpen}
      isCurrent
    >
      <OptionContent>
        <LanguageName>{current.name}</LanguageName>
        {current.flag}
        <DropdownToggle $isOpen={dropdown.isOpen} />
      </OptionContent>
    </DropdownOption>
  );

  const languageOptions = languages
    .filter((lang) => lang.code !== i18n.language)
    .map((lang) => (
      <DropdownOption
        key={lang.code}
        dropdownStyle={style}
        isDisabled={lang.isDisabled}
        onClick={() => changeLanguage(lang)}
      >
        <OptionContent>
          <LanguageName>{lang.name}</LanguageName>
          {lang.flag}
        </OptionContent>
      </DropdownOption>
    ));

  return (
    <LanguageDropdownWrapper ref={dropdownRef}>
      <DropdownWrapper dropdownStyle={style}>
        <StyledDropdown $isOpen={dropdown.isOpen} dropdownStyle={style}>
          {currentLanguage}
          <OptionsWrapper dropdownStyle={style} $isOpen={dropdown.isOpen}>
            {languageOptions}
          </OptionsWrapper>
        </StyledDropdown>
      </DropdownWrapper>
    </LanguageDropdownWrapper>
  );
};

export default LanguageDropdown;
