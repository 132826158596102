import styled from "styled-components";

import colors from "@src/common/styles/colors";
import {
  DEFAULT_MARGIN_L,
  DEFAULT_MARGIN_M,
  DEFAULT_MARGIN_S,
} from "@src/common/styles/const";
import { media } from "@src/common/styles/mixins";
import { StyledButton } from "@src/modules/shared/components/Button/Button.components";
import BaseSideModalWrapper from "@src/modules/shared/components/Modal/BaseSideModalWrapper";

const CONNECTOR_MODAL_WIDTH = 600;

export const ConnectorModalWrapper = styled(BaseSideModalWrapper).attrs({
  direction: "right",
})`
  width: ${CONNECTOR_MODAL_WIDTH}px;
  background-color: ${colors.white};

  ${media.down("MOBILE")`
    width: 100vw;
  `};
  overflow: auto;
`;

export const ConnectorModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${DEFAULT_MARGIN_L}px;

  ${media.down("MOBILE")`
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    width: 100vw;

    ${StyledButton}{
      width:100%;
    }
  `}
`;

export const ConnectorModalTitle = styled.h2`
  font-weight: 600;
  font-size: 18px;
  color: ${colors.almostBlack};
  margin: 0;
`;

export const LogsHeaders = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  margin-top: ${DEFAULT_MARGIN_M}px;
  margin-bottom: ${DEFAULT_MARGIN_S}px;
  font-size: 12px;
  padding: 0 ${DEFAULT_MARGIN_L}px;

  ${media.down("MOBILE")`
    margin-top: ${DEFAULT_MARGIN_M}px;
  `}
`;

export const LogsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  padding: 0 ${DEFAULT_MARGIN_L}px ${DEFAULT_MARGIN_L}px ${DEFAULT_MARGIN_L}px;

  ${media.down("MOBILE")`
    width: 100vw;
  `}
`;
