import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "react-query";

import { TokenResponse } from "@src/common/helpers/userToken";
import useAxios from "@src/config/axios/useAxios";
import { TOKEN_ENDPOINT } from "@src/config/baseUrls";

export interface LoginModel {
  email: string;
  password: string;
}

const useLogin = () => {
  const axios = useAxios();

  return useMutation<AxiosResponse<TokenResponse>, AxiosError, LoginModel>(
    async (values) => await axios.post(TOKEN_ENDPOINT, values),
  );
};

export default useLogin;
