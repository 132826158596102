import styled from "styled-components";

import colors from "@src/common/styles/colors";
import { BadgeAnimation } from "@src/modules/App/Topbar/NotificationsButton/NotificationsButton.components";

export const IndicatorWrapper = styled.div`
  position: absolute;
  inset: 0;
`;

export const ConnectorIndicator = styled.div`
  position: absolute;
  top: 2px;
  right: -1px;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  border: 1px solid ${colors.background};
  background-color: ${colors.darkBlue};
  animation: ${BadgeAnimation} 1s ease-in-out 1;
`;
